<template>
  <div class="space-y-3 bg-ds-background pt-6" data-cy="drafts-card-tweet">
    <div
      class="border relative rounded-lg"
      :class="{
        'border-ds-button-icon': isSelectedDraft,
        'border-ds-outline-secondary': !isSelectedDraft,
      }"
    >
      <div
        class="flex w-full items-center justify-between rounded-t-lg px-6 py-3"
        :class="{
          'bg-ds-button-secondary-background': isSelectedDraft,
          'bg-ds-outline-secondary': !isSelectedDraft,
        }"
      >
        <div class="flex items-center justify-between gap-x-2">
          <tooltip content="Edit Draft">
            <new-button
              type="custom"
              data-cy="draft-card-edit"
              @click="editDraft"
            >
              <inline-svg
                class="h-5 w-5 stroke-ds-text-secondary"
                src="/img/icons/new-edit-icon.svg"
              />
            </new-button>
          </tooltip>
          <h4 data-cy="draft-card-created-at" class="text-base text-ds-text-secondary">
            {{ formattedCreatedAt }}
          </h4>
        </div>
        <div class="flex items-center gap-2">
          <tooltip content="Duplicate">
            <new-button type="custom" data-cy="draft-card-duplicate" @click="duplicateDraft">
              <inline-svg
                class="h-5 w-5 text-ds-button-icon"
                src="/img/icons/new/duplicate-outline.svg"
              />
            </new-button>
          </tooltip>
          <tooltip content="Delete Draft">
            <new-button type="custom" data-cy="draft-card-delete" @click="confirmDraftDeletion">
              <inline-svg class="h-5 w-5 stroke-ds-button-icon" src="/img/icons/delete.svg" />
            </new-button>
          </tooltip>
        </div>
      </div>
      <div
        ref="body"
        class="px-5 py-4 text-ds-text-primary"
        :class="{ 'max-h-300 overflow-y-hidden rounded-b-lg': showExpand && isExpandable }"
      >
        <div v-for="tweet in draft.tweets" :key="tweet.guid" class="relative w-full">
          <div class="my-2 flex flex-row items-center gap-x-2">
            <img class="avatar-img" :src="userProfile.photoURL" alt="user profile picture" />
            <p class="avatar-name">
              {{ userProfile.name }}
            </p>
            <span class="avatar-username">@{{ userProfile.username }}</span>
          </div>
          <div
            v-if="draft.tweets.length > 1"
            class="absolute ml-4 w-px bg-ds-outline-secondary"
            style="height: calc(100% - 44px)"
          />
          <div class="ml-10">
            <!-- For draft replies -->
            <tweet-display
              v-if="draft.replyToTweetId"
              :profilePictureURL="draft.replyToTweetInfo.userProfilePictureURL"
              :tweet="draft.replyToTweetInfo.text"
              :userDisplayName="draft.replyToTweetInfo.userDisplayName"
              :username="draft.replyToTweetInfo.username"
              isRedirectable
            >
            </tweet-display>
            <p
              class="whitespace-pre-line break-words px-1 text-base"
              v-html="formatTweet(tweet.status)"
              dir="auto"
            />
            <tweet-display
              v-if="tweet.quoteTweetData"
              :tweet="tweet.quoteTweetData.text"
              :class="tweet.status.length > 0 && 'mt-4'"
              :username="tweet.quoteTweetData.username"
              :profilePictureURL="tweet.quoteTweetData.userProfilePictureURL"
            />
            <tweet-display
              v-if="draft.hasThreadFinisherTweet && tweet.count === draft.tweets.length - 1"
              :tweet="formatTweet(draft.tweets[0].status)"
              :class="tweet.status.length > 0 && 'mt-4'"
              :username="userProfile.username"
              :profilePictureURL="userProfile.photoURL"
            />
            <queue-thumbnail :media="tweet.media" />
            <poll-options :tweet="tweet" />
          </div>
        </div>
      </div>
      <div
        class="flex w-full items-end justify-between rounded-b-lg bg-ds-background px-5 pb-4"
        :class="showExpand && isExpandable && 'fade-effect absolute bottom-0 h-24'"
      >
        <div class="flex items-center gap-2">
          <template v-for="icon in footerIcons">
            <tooltip
              :key="icon.key"
              v-if="icon.show"
              :content="displayIconMessage(icon.key, draft)"
              :disabled="icon.disabled"
            >
              <span
                class="flex h-8 w-8 items-center justify-center rounded-full"
                :class="{
                  'bg-ds-button-secondary-background': isSelectedDraft,
                  'bg-ds-outline-secondary': !isSelectedDraft,
                }"
              >
                <inline-svg
                  class="h-5 w-5"
                  :class="{
                    'text-ds-button-secondary-label': isSelectedDraft,
                    'text-gray-80': !isSelectedDraft,
                  }"
                  :src="icon.path"
                />
              </span>
            </tooltip>
          </template>
        </div>
        <div v-if="isExpandable">
          <new-button v-if="showExpand" @click="showExpand = false" type="secondary">
            <span>Show more</span>
          </new-button>
          <new-button v-else @click="showExpand = true" type="secondary">
            <span>Show less</span>
          </new-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import moment from 'moment';
  import 'moment-timezone';
  import { mapGetters } from 'vuex';
  import lodash from 'lodash';
  import { formatTweet } from '@/util/formatTweet';
  import { displayIconMessage } from '@/util/displayIconMessage';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import LinkedInMixin from '@/views/Mixins/LinkedInMixin.vue';
  import FacebookMixin from '@/views/Mixins/FacebookMixin.vue';
  import QueueThumbnail from '@/views/QueueThumbnail.vue';
  import PollOptions from '@/components/PollOptions.vue';
  import NewThreadMixin from '@/views/Mixins/NewThreadMixin.vue';
  import { countTweetLength } from '@/../functions/src/util/countTweetLength';
  import TweetDisplay from '../TweetDisplay.vue';
  import { EventBus } from '@/event-bus';
  import { DraftThread } from '../../models/DraftThread';
  import { v4 as uuid } from 'uuid';

  export default {
    name: 'DraftCard',
    components: {
      PollOptions,
      QueueThumbnail,
      TweetDisplay,
    },
    props: {
      draft: {
        type: Object,
        required: true,
      },
      threadToEdit: {
        type: Object,
        default: null,
      },
      selectedDraft: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        showExpand: true,
        isExpandable: false,
      };
    },
    mounted() {
      const cardBody = this.$refs.body;
      this.$nextTick(() => {
        if (cardBody) {
          const cardBodyHeight = cardBody.clientHeight;
          const maxHeight = 250;
          if (cardBodyHeight > maxHeight && cardBodyHeight - maxHeight > 50) {
            this.isExpandable = true;
          }
        }
      });
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      formattedCreatedAt() {
        const differenceInMinutes = moment(new Date()).diff(this.draft.created_at, 'minutes');
        return this.getTimeDifferenceText(differenceInMinutes, this.draft.created_at);
      },
      canDraftBePostedNow() {
        const tweetsOver280Chars = this.draft.tweets.filter(
          (t) => countTweetLength(t.status) > 280,
        );
        return tweetsOver280Chars.length === 0;
      },
      footerIcons() {
        return [
          {
            show: true,
            path: '/img/icons/new/socials/twitter.svg',
            disabled: true,
            key: 'twitter',
          },
          {
            show: this.draft.linkedIn,
            path: '/img/icons/new/linkedin.svg',
            disabled: true,
            key: 'LinkedIn',
          },
          {
            show: this.draft.facebook,
            path: '/img/icons/new/facebook.svg',
            disabled: true,
            key: 'Facebook',
          },
          {
            show: this.draft.shareOnInstagram,
            path: '/img/icons/new/instagram.svg',
            disabled: true,
            key: 'Instagram',
          },
          {
            show: this.draft.threads,
            path: '/img/icons/new/socials/threads.svg',
            disabled: true,
            key: 'Threads',
          },
          {
            show: this.draft.tiktok,
            path: '/img/icons/new/socials/tiktok.svg',
            disabled: true,
            key: 'Tiktok',
          },
          {
            show: this.draft.autoplug,
            path: '/img/icons/new/autoplug.svg',
            disabled: false,
            key: 'Autoplug',
          },
          {
            show: this.draft.conditionalRetweetsConditions,
            path: '/img/icons/retweet.svg',
            disabled: false,
            key: 'Conditional Retweet',
          },
          {
            show: this.draft.delayBetweenTweets,
            path: '/img/icons/delay.svg',
            disabled: false,
            key: 'Delay Message',
          },
          {
            show: this.draft.autoDM,
            path: '/img/icons/new/autodm.svg',
            disabled: false,
            key: 'autoDM',
          },
        ];
      },
      isSelectedDraft() {
        return this.selectedDraft && this.selectedDraft === this.draft.id;
      },
    },
    methods: {
      displayIconMessage,
      lodashGet: lodash.get,
      formatTweet,
      editDraft() {
        this.$emit('edit-draft', this.draft);
        this.$eventStore.composer('Drafts: Edit draft');
      },
      confirmDraftDeletion() {
        this.$eventStore.composer('Drafts: Delete draft');
        this.swalModal({
          title: 'Delete Draft',
          text: 'Are you sure you want to delete this draft?',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'Cancel',
          preConfirm: async () => {
            try {
              if (lodash.get(this.threadToEdit, 'id') === lodash.get(this.draft, 'id')) {
                this.$emit('reset-composer');
              }

              await this.draft.deleteFromFirestore(this.currentUser, this.userProfile.uid);
              this.$notify({ type: 'success', message: 'Draft deleted successfully!' });
            } catch {
              this.swalModal({
                title: 'Sorry',
                text: 'An error has occurred while deleting the post.',
                type: 'warning',
              });
            }
          },
        });
      },
      async postNow() {
        try {
          EventBus.$emit('show-loader');
          const draftToThread = this.draft.toScheduledThread(new Date());
          draftToThread.postNow = true;
          await draftToThread.update(this.currentUser, this.userProfile.uid);
          this.$notify({ type: 'success', message: 'Thread posted successfully!' });
        } catch {
          this.swalModal({
            title: 'Sorry',
            text: 'An error has occurred while posting the thread.',
            type: 'warning',
          });
        } finally {
          EventBus.$emit('hide-loader');
        }
      },
      chooseTime() {
        const dropdown = this.$refs.dropdown;
        if (dropdown) {
          dropdown.close();
        }
        this.$nextTick(() => {
          this.$emit('choose-time', this.draft);
        });
      },
      showScheduleOnNextFreeSlot() {
        const dropdown = this.$refs.dropdown;
        if (dropdown) {
          dropdown.close();
        }
        this.$nextTick(() => {
          this.$emit('schedule-on-next-free-slot', this.draft);
        });
      },
      getTimeDifferenceText(time, draftCreatedAt) {
        switch (true) {
          case time <= 2:
            return 'just now';
          case time < 60:
            return `${time} minutes ago`;
          case time < 24 * 60:
            return `${Math.floor(time / 60)} hours ago`;
          case time < 48 * 60:
            return `Yesterday at ${draftCreatedAt.format('HH:mm')}`;
          default:
            return draftCreatedAt.format('MMMM DD, YYYY [at] HH:mm');
        }
      },
      async duplicateDraft() {
        this.$eventStore.composer('Drafts: Duplicate draft');
        EventBus.$emit('show-loader');
        try {
          const duplicatedDraft = new DraftThread({
            ...this.draft,
            categories: this.draft.categories.map((category) => category.id),
            create_at: moment(),
            id: uuid(),
            user: this.draft.user.id,
          });

          await duplicatedDraft.saveToFirestore(
            this.currentUser,
            this.userProfile.uid,
            this.timezone,
            this.$eventStore,
          );

          this.$notify({ type: 'success', message: 'Draft was duplicated successfully!' });
        } catch (error) {
          console.log(error);
          this.swalModal({
            title: 'Sorry',
            text: 'An error has occurred while duplicating draft',
            type: 'warning',
          });
        }
        EventBus.$emit('hide-loader');
      },
    },
    mixins: [SwalModalMixin, LinkedInMixin, FacebookMixin, NewThreadMixin],
  };
</script>
