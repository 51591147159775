<template>
  <slide-over
    enableMobileBottomToTopTranslation
    ref="composerContainer"
    @close="$emit('close')"
    :confirmOnDismiss="Boolean(threadToEdit) || showQuickView.enable"
    @attempt-dismiss="attemptDismiss"
    :open="show"
    :containerClasses="`${getSlideOverWidth} flex justify-end w-full z-50 relative isolate`"
    :composerContext="{ isComposer: true, isKeyboardVisible: isMobileKeyboardVisible }"
  >
    <transition
      enter-active-class="transform transition ease-in-out duration-500"
      enter-class="translate-y-full lg:translate-x-full lg:translate-y-0"
      enter-to-class="translate-y-0 lg:translate-x-0"
      leave-active-class="transform transition ease-in-out duration-500"
      leave-class="translate-y-0 lg:translate-x-0"
      leave-to-class="translate-y-full lg:translate-x-full lg:translate-y-0"
      @after-leave="showQuickView.enable = false"
    >
      <div
        v-show="showQuickView.content"
        @scroll="handleQuickViewScroll"
        class="preview-height fixed inset-x-0 bottom-0 overflow-auto rounded-t-3xl bg-ds-background px-4 pt-5 shadow-small lg:relative lg:w-2/5 lg:rounded-none lg:p-8 xl:w-1/2"
        :class="{
          'z-300': !isDesktop,
        }"
      >
        <fade-transition
          :duration="{
            enter: 200,
            leave: 0,
          }"
        >
          <new-composer-preview
            v-show="isPreviewShown"
            :tweets="tweets"
            :hasThreadFinisherTweet="hasThreadFinisherTweet"
            :targetPreview="selectedTab"
            :linkedInText="linkedInText"
            :facebookText="facebookText"
            :linkedInPostType="linkedInPostType"
            :instagramCaption="instagramCaption"
            :isShareOnLinkedInEnabled="isShareOnLinkedInEnabled"
            :isShareOnFacebookEnabled="isShareOnFacebookEnabled"
            :isShareOnInstagramEnabled="isShareOnInstagramEnabled"
            :isAReplyFromParent="isAReplyFromParent"
            :postType="postType"
            :hideTabsLabel="isFocusModeEnabled"
            @view-changed="(view) => (selectedTab = view)"
            @close="showQuickView.content = false"
            @updateParentOriginalTweetInfo="updateParentValues"
          />
        </fade-transition>
        <fade-transition
          :duration="{
            enter: 200,
            leave: 0,
          }"
        >
          <new-composer-drafts
            ref="drafts"
            v-show="isDraftShown"
            :show="isDraftShown"
            @close="showQuickView.content = false"
            @edit-draft="editDraft"
            :threadToEdit="threadToEdit"
            @reset-composer="resetComposer"
            @choose-time="chooseTimeForDraft"
            @schedule-on-next-free-slot="scheduleDraftOnNextFreeSlot"
            :selectedDraft="selectedDraft"
          />
        </fade-transition>
        <fade-transition
          :duration="{
            enter: 200,
            leave: 0,
          }"
        >
          <new-composer-prompts
            v-show="isPromptsShown"
            :show="isPromptsShown"
            @close="togglePrompts"
            @insert-inspiration-tweet="insertInspirationTweet"
          />
        </fade-transition>
      </div>
    </transition>
    <div
      :class="getComposerWidth"
      class="overscroll-behavior-contain relative flex h-full overflow-y-auto rounded-t-3xl bg-ds-background px-4 pb-3 pt-5 shadow-medium lg:rounded-none lg:p-8"
      :style="isMobileKeyboardVisible && `height: var(--available-space)`"
    >
      <div data-cy="create-overlay" v-show="isSubmitting">
        <div class="loader absolute inset-0 bg-ds-background" style="z-index: 1000">
          <loading-scheduler />
        </div>
      </div>

      <div
        class="fixed inset-0 z-40 bg-overlay transition-opacity"
        data-cy="create-overlay"
        v-show="isGeneratingAIResult"
      >
        <div class="fixed inset-0 z-50 flex flex-col items-center justify-center">
          <loading-scheduler />
          <p class="text-2xl font-bold text-main-color-100">Please wait as AI works its magic...</p>
        </div>
      </div>

      <!-- side bar close button -->

      <portal to="composer-view-actions" v-if="!isAReply && selectedTab !== 'reel-editor'">
        <tooltip content="AI" v-if="postType === 'twitter' && isAIOn && !isFocusModeEnabled">
          <new-button v-if="postType === 'twitter'" type="secondary" @click="showAI">
            <inline-svg src="/img/icons/ai-request.svg" class="h-6 w-6 stroke-current" />
          </new-button>
        </tooltip>
        <tooltip
          class="hidden lg:inline-block"
          :content="isFocusModeEnabled ? 'Leave Focus Mode' : 'Focus Mode'"
          v-if="postType === 'twitter' && !isAMAPost"
        >
          <new-button
            type="custom"
            @click="toggleFocusMode"
            v-if="postType === 'twitter'"
            data-cy="compose-focus-button"
          >
            <inline-svg
              v-if="!isFocusModeEnabled"
              src="/img/icons/new/full-screen.svg"
              class="h-8 w-8 stroke-ds-button-icon"
            />
            <inline-svg
              v-else
              src="/img/icons/new/close-full-screen.svg"
              class="rounded h-10 w-10 bg-ds-button-secondary-background stroke-ds-button-icon p-1"
            />
          </new-button>
        </tooltip>
        <tooltip
          content="Drafts"
          v-if="postType === 'twitter' && !isFocusModeEnabled && !isAMAPost"
        >
          <new-button
            type="custom"
            @click="toggleDrafts"
            v-if="postType === 'twitter'"
            data-cy="compose-drafts-button"
          >
            <div class="relative">
              <span
                v-if="userProfile.draftPostCount > 0"
                class="absolute -right-0.75 -top-0.75 box-content flex h-4 w-4 items-center justify-center rounded-full border-1.5 border-white bg-main-color-100 text-xxs text-white"
              >
                {{ userProfile.draftPostCount }}
              </span>
              <inline-svg
                src="/img/icons/new/drafts.svg"
                class="h-8 w-8 stroke-ds-button-icon"
                stroke-width="1.5"
              />
            </div>
          </new-button>
        </tooltip>
        <tooltip
          content="Inspiration"
          v-if="['recurrent', 'twitter'].includes(postType) && !isFocusModeEnabled && !isAMAPost"
        >
          <new-button
            type="custom"
            v-if="['recurrent', 'twitter'].includes(postType)"
            @click="togglePrompts"
            data-cy="compose-prompts-button"
          >
            <inline-svg src="/img/icons/new/ai-filter.svg" class="h-8 w-8 stroke-ds-button-icon" />
          </new-button>
        </tooltip>
      </portal>

      <portal to="newDelayTweet">
        <new-delay-between-tweets
          v-show="tweets.length > 1 && ['recurrent', 'twitter'].includes(postType)"
          :threadToEdit="threadToEdit"
          :delayBetweenTweetsFromParent="delayBetweenTweets"
          @values-set="(delay) => (this.delayBetweenTweets = delay)"
        />
      </portal>

      <div
        ref="composer"
        class="relative flex h-full w-full flex-col"
        :class="'mx-auto max-w-610' === !isFocusModeEnabled"
      >
        <div>
          <!-- Upper Nav Bar -->
          <div class="mb-6 flex items-center justify-between">
            <div v-if="isFocusModeEnabled" class="relative hidden w-full overflow-visible lg:block">
              <div class="info_trigger flex items-center justify-center">
                <inline-svg class="text-blues-40" src="/img/icons/info-icon.svg" />
              </div>
              <div
                class="info_content_container pointer-events-none absolute left-1 w-466 rounded-lg bg-ds-background p-3 shadow-md transition-opacity delay-700"
              >
                <h1 class="">Welcome to Focus mode!</h1>
                <ul class="space-y-3 text-base">
                  <li>
                    <p>
                      Here you can compose your tweets in a full-screen editor, without being extra
                      features and different platforms.
                    </p>
                  </li>
                  <li>
                    <p>
                      While writing, press
                      <kbd
                        class="text-gray-800 border border-gray-200 dark:bg-gray-600 dark:border-gray-500 rounded-lg bg-gray-100 px-2 py-1.5 text-xs font-semibold dark:text-gray-100"
                        >Enter</kbd
                      >
                      two times to create a new tweet in a thread.
                    </p>
                  </li>
                  <li>
                    <p>
                      You can add media, finishing touches and other platforms after you exit Focus mode.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="flex w-full flex-row-reverse items-center justify-between gap-x-3 lg:flex-row lg:justify-start"
            >
              <new-button
                v-show="!isFocusModeEnabled"
                @click="$refs.composerContainer.dismiss()"
                type="custom"
                data-cy="composer-close"
              >
                <inline-svg
                  src="/img/icons/new/modal-collapse.svg"
                  class="hidden h-6 rotate-180 transform text-main-color-100 lg:inline"
                />
                <inline-svg src="/img/icons/new/x.svg" class="inline text-gray-80 lg:hidden" />
              </new-button>
              <h1 v-show="!isFocusModeEnabled">{{ getComposerTitle }}</h1>
              <span
                v-if="selectedTab === 'reel-editor'"
                class="border rounded-2xl border-ds-outline-primary px-3 py-1 text-sm text-ds-text-primary"
                >Beta</span
              >
            </div>
            <div v-show="!isSubmitting" class="flex items-center gap-4">
              <portal-target
                v-if="isDesktop"
                name="composer-view-actions"
                class="flex items-center gap-x-4"
              />
            </div>
          </div>
          <div
            v-if="!isAReply && !isFocusModeEnabled"
            class="border-b dark-mode-border-60 flex items-center justify-between border-gray-90 pb-6"
          >
            <div class="flex items-center gap-x-2">
              <new-button
                :icon="!isShareOnThreadsEnabled"
                v-if="['twitter', 'recurrent'].includes(postType) && selectedTab !== 'reel-editor'"
                data-cy="twitter-post-button"
                @click="selectedTab = 'twitter'"
                :type="selectedTab === 'twitter' ? 'primary' : 'secondary'"
              >
                <inline-svg
                  v-if="!isShareOnThreadsEnabled"
                  src="/img/icons/new/socials/twitter.svg"
                  class="inline h-5 w-5 fill-current"
                />
                <div v-else class="flex items-end justify-center gap-x-1">
                  <inline-svg
                    src="/img/icons/new/socials/twitter.svg"
                    class="inline h-5 w-5 fill-current"
                  />
                  <span class="font-main-font text-sm leading-none">&</span>
                  <inline-svg
                    src="/img/icons/new/socials/threads.svg"
                    class="inline h-5 w-5 stroke-current"
                  />
                </div>
              </new-button>
              <new-button
                icon
                v-if="isShareOnFacebookEnabled || postType === 'facebook'"
                data-cy="facebook-post-button"
                @click="selectedTab = 'facebook'"
                :type="selectedTab === 'facebook' ? 'primary' : 'secondary'"
              >
                <inline-svg
                  src="/img/icons/new/socials/facebook.svg"
                  class="inline h-5 w-5 fill-current"
                />
                <inline-svg
                  v-if="!isShareOnFacebookInfoValid"
                  style="fill: red"
                  class="absolute right-0 top-0 h-4"
                  src="/img/icons/triangle-exclamation-solid.svg"
                />
              </new-button>
              <new-button
                icon
                v-if="(isShareOnLinkedInEnabled || postType === 'linkedin') && !isAMAPost"
                data-cy="linkedin-post-button"
                @click="selectedTab = 'linkedin'"
                :type="selectedTab === 'linkedin' ? 'primary' : 'secondary'"
              >
                <inline-svg
                  src="/img/icons/new/socials/linkedin.svg"
                  class="inline h-5 w-5 fill-current"
                />
                <inline-svg
                  v-if="showLinkedInWarning"
                  class="absolute right-0 top-0 h-4 fill-danger-100"
                  src="/img/icons/triangle-exclamation-solid.svg"
                />
              </new-button>
              <new-button
                icon
                v-if="
                  (isShareOnInstagramEnabled || postType === 'instagram') &&
                  selectedTab !== 'reel-editor' &&
                  !isAMAPost
                "
                data-cy="instagram-post-button"
                @click="selectedTab = 'instagram'"
                :type="selectedTab === 'instagram' ? 'primary' : 'secondary'"
              >
                <inline-svg
                  src="/img/icons/new/socials/instagram.svg"
                  class="inline h-5 w-5 fill-current"
                />
              </new-button>
              <new-button
                icon
                v-if="selectedTab === 'reel-editor'"
                :type="selectedTab === 'reel-editor' ? 'primary' : 'secondary'"
              >
                <inline-svg
                  src="/img/icons/instagram-reel.svg"
                  class="inline h-5 w-5 fill-current"
                />
              </new-button>
              <new-button
                icon
                v-if="selectedTab === 'threads'"
                :type="selectedTab === 'threads' ? 'primary' : 'secondary'"
              >
                <inline-svg
                  src="/img/icons/new/socials/threads.svg"
                  class="inline h-5 w-5 stroke-current"
                />
              </new-button>
              <new-button
                icon
                data-cy="tiktok-post-button"
                v-if="selectedTab === 'tiktok' || isShareOnTiktokEnabled"
                :type="selectedTab === 'tiktok' ? 'primary' : 'secondary'"
                @click="selectedTab = 'tiktok'"
              >
                <inline-svg src="/img/icons/new/socials/tiktok.svg" class="inline h-5 w-5" />
              </new-button>
              <new-drop-down
                icon
                buttonType="bordered"
                v-if="
                  (postType === 'twitter' || postType === 'recurrent') &&
                  selectedTab !== 'reel-editor' &&
                  !isAMAPost
                "
                data-cy="post-types-dropdown"
              >
                <template v-slot:button="slotProps">
                  <inline-svg
                    src="/img/icons/arrow-down-icon.svg"
                    class="h-2.5 w-2.5 stroke-current"
                    :class="['transform', slotProps.isOpen ? 'rotate-180' : 'rotate-0']"
                  />
                </template>
                <template>
                  <div class="flex flex-col gap-y-3 text-sm font-medium text-ds-button-icon">
                    <div class="flex items-center justify-between gap-x-6">
                      <span
                        :class="[
                          'flex items-center justify-center gap-x-3',
                          { 'text-gray-80': !linkedInConditions.canShare },
                        ]"
                      >
                        <inline-svg
                          src="/img/icons/new/socials/linkedin.svg"
                          class="h-5 w-5 fill-current"
                        />
                        LinkedIn
                      </span>
                      <base-toggle
                        :disabled="!linkedInConditions.canShare"
                        v-model="isShareOnLinkedInEnabled"
                        @input="switchSelectedTab('linkedin', isShareOnLinkedInEnabled)"
                        name="enable-crosspost-linkedin"
                        id="enable-crosspost-linkedin"
                      />
                    </div>
                    <div class="flex items-center justify-between gap-x-6">
                      <span
                        :class="[
                          'flex items-center justify-center gap-x-3',
                          { 'text-gray-80': !facebookConditions.canShare },
                        ]"
                      >
                        <inline-svg
                          src="/img/icons/new/socials/facebook.svg"
                          class="h-5 w-5 fill-current"
                        />
                        Facebook
                      </span>
                      <base-toggle
                        :disabled="!facebookConditions.canShare"
                        @input="switchSelectedTab('facebook', isShareOnFacebookEnabled)"
                        v-model="isShareOnFacebookEnabled"
                        name="enable-crosspost-facebook"
                        id="enable-crosspost-facebook"
                      />
                    </div>
                    <div class="flex items-center justify-between gap-x-6">
                      <span
                        :class="[
                          'flex items-center justify-center gap-x-3',
                          { 'text-gray-80': !canShareOnInstagram },
                        ]"
                      >
                        <inline-svg
                          src="/img/icons/new/socials/instagram.svg"
                          class="h-5 w-5 fill-current"
                        />
                        Instagram
                      </span>
                      <base-toggle
                        :disabled="!canShareOnInstagram"
                        @input="switchSelectedTab('instagram', isShareOnInstagramEnabled)"
                        v-model="isShareOnInstagramEnabled"
                        name="enable-crosspost-instagram"
                        id="enable-crosspost-instagram"
                      />
                    </div>
                    <div
                      class="flex items-center justify-between gap-x-6"
                    >
                      <span
                        :class="[
                          'flex items-center justify-center gap-x-3',
                          { 'text-gray-80': !canShareOnThreads },
                        ]"
                      >
                        <inline-svg
                          src="/img/icons/new/socials/threads.svg"
                          class="h-5 w-5 stroke-current"
                        />
                        Threads
                      </span>
                      <base-toggle
                        :disabled="!canShareOnThreads"
                        v-model="isShareOnThreadsEnabled"
                        name="enable-crosspost-threads"
                        id="enable-crosspost-threads"
                      />
                    </div>
                    <!--
                        TikTok cross-posting is temporarily disabled.
                        This section will be re-enabled once we finalize the composer's
                        implementation strategy for TikTok content creation.
                    -->
                    <!-- <div class="flex items-center justify-between gap-x-6">
                      <span
                        :class="[
                          'flex items-center justify-center gap-x-3',
                          { 'text-gray-80': !canShareOnTiktok },
                        ]"
                      >
                        <inline-svg
                          src="/img/icons/new/socials/tiktok.svg"
                          class="h-5 w-5 fill-current"
                        />
                        Tiktok
                      </span>
                      <base-toggle
                        :disabled="!canShareOnTiktok"
                        @input="switchSelectedTab('tiktok', isShareOnTiktokEnabled)"
                        v-model="isShareOnTiktokEnabled"
                        name="enable-crosspost-tiktok"
                        id="enable-crosspost-tiktok"
                      />
                    </div> -->
                  </div>
                </template>
              </new-drop-down>
            </div>
            <portal-target
              v-if="!isDesktop"
              name="composer-view-actions"
              class="flex items-center gap-x-4"
            />
          </div>
        </div>
        <div
          v-show="!isFocusModeEnabled"
          class="styled-scrollbars overscroll-behavior-contain mt-6 flex h-full max-w-3xl flex-col justify-start gap-y-6 overflow-y-auto"
        >
          <!-- Post Composer -->
          <div
            v-if="selectedTab === 'twitter' && !isAReply"
            class="styled-scrollbars max-h-composer-sm min-h-composer-sm w-full pr-4 lg:max-h-composer lg:min-h-composer"
            data-cy="composer-twitter"
          >
            <new-composer-text-area
              v-for="(tweet, idx) in tweets"
              :isAIOn="isAIOn"
              :key="tweet.count"
              :tweet="tweet"
              :numberOfTweets="tweets.length"
              :currently-focused-tweet-index="currentlyFocusedTweetIndex"
              :hasThreadFinisherTweet="hasThreadFinisherTweet"
              :threadLength="tweets.length"
              :canAddMoreVideos="canAddMoreVideos"
              :imageCountInThread="imageCountInThread"
              :firstTweet="tweets[0]"
              :canDeleteTweet="Array.isArray(tweets) && tweets.length > 1"
              :isShareOnFacebookEnabled="isShareOnFacebookEnabled"
              :isShareOnLinkedInEnabled="isShareOnLinkedInEnabled"
              :canAddMediaToFacebook="facebookConditions.canAddMedia"
              :canAddMediaToLinkedIn="linkedInConditions.canAddMedia"
              :tweetIndexToFocus="tweetIndexToFocus"
              :originalThread="threadToEdit ? threadToEdit : threadToReplace"
              :wasSubmitted="wasSubmitted"
              :hasInspirationTweet="hasInspirationTweet"
              :selectedCategories="selectedCategories"
              :isComposerDropdownVisible="!isAMAPost"
              :isCategoriesVisible="!isAMAPost"
              :bestTweetIndex="bestTweetIndex"
              :hasStartedEditing="hasStartedEditing"
              :isShareOnThreadsEnabled="isShareOnThreadsEnabled"
              :uploadingMedia="uploadingMedia"
              @set-generating-ai-result="(value) => (isGeneratingAIResult = value)"
              @status-updated="updateStatus($event, idx)"
              @cleared-media="clearMediaSelectionAtIndex"
              @deleted="deleteTweet"
              @set-ai-thread="setThreadFromGptResult"
              @add-tweet="addTweet"
              @pasted="splitLongTextIntoTweets"
              @quote-tweet-data-updated="(quoteURL) => (tweet.quoteTweetData = quoteURL)"
              @updated-poll="(value) => updatePoll(value, tweet.guid)"
              @input-changed="onInputChange"
              @pressed-ctrl-enter="postType === 'twitter' ? addToQueue() : saveDraft()"
              @append-send-us-dm-button="addSendUsDMButton(idx)"
              @set-currently-focused-tweet-index="setCurrentlyFocusedTweetIndex"
              @add-category="addCategory"
              @remove-category="removeCategory"
              @focused="handleComposerFocus(true)"
              @blurred="handleComposerFocus(false)"
              @selected-gif="addGifToTweet"
              @change-best-tweet="changeBestTweet"
              @clear-composer="clearComposer(idx)"
            />
            <div class="mt-4 flex items-center gap-4" v-if="!isAMAPost">
              <portal to="addTweet">
                <tooltip content="Add Tweet">
                  <new-button @click="addTweet" icon data-cy="compose-add-tweet" type="secondary">
                    <inline-svg src="img/icons/add.svg" />
                  </new-button>
                </tooltip>
              </portal>
              <portal to="addThreadFinisher">
                <tooltip
                  v-if="!hasThreadFinisherTweet && tweets.length > 1"
                  content="Add a thread finisher"
                >
                  <new-button
                    type="transparent"
                    size="sm"
                    icon
                    @click="addThreadFinisherTweet"
                    data-cy="compose-thread-finisher"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="stroke-ds-button-icon"
                    >
                      <path
                        d="M2 10H5.84M8.4 6H2M8.4 14H2M8.4 11.6L14 2V8.4H18L12.4 18V11.6H8.4Z"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span class="sr-only">Thread finisher</span>
                  </new-button>
                </tooltip>
              </portal>
            </div>
          </div>

          <div
            v-if="selectedTab === 'threads'"
            class="styled-scrollbars max-h-composer-sm min-h-composer-sm w-full pr-4 lg:max-h-composer lg:min-h-composer"
            data-cy="composer-threads"
          >
            <new-composer-threads
              v-for="(tweet, idx) in tweets"
              :key="tweet.count"
              :tweet="tweet"
              :numberOfTweets="tweets.length"
              :currently-focused-tweet-index="currentlyFocusedTweetIndex"
              :threadLength="tweets.length"
              :canAddMoreVideos="canAddMoreVideos"
              :imageCountInThread="imageCountInThread"
              :firstTweet="tweets[0]"
              :canDeleteTweet="Array.isArray(tweets) && tweets.length > 1"
              :tweetIndexToFocus="tweetIndexToFocus"
              :originalThread="threadToEdit ? threadToEdit : threadToReplace"
              :wasSubmitted="wasSubmitted"
              :isComposerDropdownVisible="!isAMAPost"
              :isCategoriesVisible="!isAMAPost"
              :hasStartedEditing="hasStartedEditing"
              :isShareOnThreadsEnabled="isShareOnThreadsEnabled"
              @status-updated="updateStatus($event, idx)"
              @cleared-media="clearMediaSelectionAtIndex"
              @deleted="deleteTweet"
              @add-tweet="addTweet"
              @pasted="splitLongTextIntoTweets"
              @input-changed="onInputChange"
              @pressed-ctrl-enter="postType === 'twitter' ? addToQueue() : saveDraft()"
              @set-currently-focused-tweet-index="setCurrentlyFocusedTweetIndex"
              @focused="handleComposerFocus(true)"
              @blurred="handleComposerFocus(false)"
              @selected-gif="addGifToTweet"
              @clear-composer="clearComposer(idx)"
              :uploadingMedia="uploadingMedia"
            />
            <div class="mt-4 flex items-center gap-4" v-if="!isAMAPost">
              <portal to="addTweet">
                <tooltip content="Add Tweet">
                  <new-button @click="addTweet" icon data-cy="compose-add-tweet" type="secondary">
                    <inline-svg src="img/icons/add.svg" />
                  </new-button>
                </tooltip>
              </portal>
            </div>
          </div>

          <new-composer-reel-editor
            v-if="selectedTab === 'reel-editor'"
            :thread="threadFromParent"
            @values-set="updateParentValues"
            :initialReelOptions="initialReelOptions"
          />
          <new-composer-instagram
            :isMobileKeyboardVisible="isMobileKeyboardVisible"
            v-show="selectedTab === 'instagram'"
            :isVisible="selectedTab === 'instagram'"
            :hasMultipleTweets="tweets.length > 1"
            :originalThread="threadToEdit ? threadToEdit : threadToReplace"
            :canShareOnInstagram="canShareOnInstagram"
            :isPostOfTypeInstagram="postType === 'instagram'"
            :tweet="tweets[0]"
            :tweetshotContent="tweetshotContent"
            :isShareOnInstagramEnabled="isShareOnInstagramEnabled"
            :uploadingMedia="uploadingMedia"
            @values-set="updateParentValues"
            @cleared-media="clearMediaSelectionAtIndex"
            @show-tweetshot-modal="isTweetshotModalVisible = true"
            @pressed-ctrl-enter="handleCtrlEnter"
            @focused="handleComposerFocus(true)"
            @blurred="handleComposerFocus(false)"
          />
          <new-composer-facebook
            v-show="selectedTab === 'facebook'"
            :isVisible="selectedTab === 'facebook'"
            :tweetsCombinedStatuses="tweetsCombinedStatuses"
            :originalThread="threadToEdit ? threadToEdit : threadToReplace"
            :tweetshotContent="tweetshotContent"
            :tweet="tweets[0]"
            :tweets="tweets"
            :isPostOfTypeFacebook="postType === 'facebook'"
            :uploadingMedia="uploadingMedia"
            @values-set="updateParentValues"
            @resetFacebookManualEdit="resetFacebookManualEdit = $event"
            @cleared-media="clearMediaSelectionAtIndex"
            @show-tweetshot-modal="isTweetshotModalVisible = true"
            @pressed-ctrl-enter="handleCtrlEnter"
            @focused="handleComposerFocus(true)"
            @blurred="handleComposerFocus(false)"
            :isMobileKeyboardVisible="isMobileKeyboardVisible"
          />
          <new-composer-linked-in
            v-show="selectedTab === 'linkedin'"
            :isVisible="selectedTab === 'linkedin'"
            :tweetsCombinedStatuses="tweetsCombinedStatuses"
            :originalThread="threadToEdit ? threadToEdit : threadToReplace"
            :tweetshotContent="tweetshotContent"
            :isPostOfTypeLinkedIn="postType === 'linkedin'"
            :tweet="tweets[0]"
            :tweets="tweets"
            :uploadingMedia="uploadingMedia"
            @values-set="updateParentValues"
            @resetLinkedIn="resetLinkedIn = $event"
            @cleared-media="clearMediaSelectionAtIndex"
            @show-tweetshot-modal="isTweetshotModalVisible = true"
            @pressed-ctrl-enter="handleCtrlEnter"
            @focused="handleComposerFocus(true)"
            @blurred="handleComposerFocus(false)"
            :isMobileKeyboardVisible="isMobileKeyboardVisible"
          />
          <new-reply-composer
            v-if="isAReply"
            v-show="selectedTab === 'reply'"
            :threadToEdit="threadToEdit"
            :replyTweetInfo="originalTweetInfo"
            :timeFromParent="timeFromParent"
            :showComposer="isAReply"
            :hasStartedEditing="hasStartedEditing"
            :uploadingMedia="uploadingMedia"
            @values-set="updateParentValues"
            @cleared-media="clearMediaSelectionAtIndex"
            @pressed-ctrl-enter="handleCtrlEnter"
            @focused="handleComposerFocus(true)"
            @blurred="handleComposerFocus(false)"
          />
          <new-composer-tiktok
            v-show="selectedTab === 'tiktok'"
            :isVisible="selectedTab === 'tiktok'"
            :originalThread="threadToEdit ? threadToEdit : threadToReplace"
            :isPostOfTypeTiktok="postType === 'tiktok'"
            :canShareOnTiktok="canShareOnTiktok"
            :tweet="tweets[0]"
            :tweetshotContent="tweetshotContent"
            :uploadingMedia="uploadingMedia"
            @values-set="updateParentValues"
            @cleared-media="clearMediaSelectionAtIndex"
            @pressed-ctrl-enter="handleCtrlEnter"
            @focused="handleComposerFocus(true)"
            @blurred="handleComposerFocus(false)"
            @show-tweetshot-modal="isTweetshotModalVisible = true"
          />
        </div>

        <new-focus-mode-composer
          v-if="isFocusModeEnabled"
          :tweetsFromParent="tweets"
          :composerStatus="focusModeComposerStatus"
          @composer-status-updated="updateFocusModeStatus"
          @input-changed="onInputChange"
          @status-updated="updateStatusFromFocusMode"
        />
        <portal
          v-if="tweetshotContent.length > 0 && postType !== 'instagram'"
          to="tweetshot-preview"
        >
          <div class="w-full">
            <div class="flex items-center justify-between">
              <span class="text-muted">Tweetshot preview</span>

              <new-button type="transparent" @click="tweetshotContent = []">
                <span class="text-sm text-muted">Clear</span>
              </new-button>
            </div>
            <tweet-display
              v-for="content in tweetshotContent"
              :key="content.tweetId"
              :tweet="content.tweetText"
              :username="userProfile.username"
              :userDisplayName="userProfile.name"
              :profilePictureURL="userProfile.photoURL"
              class="mb-4"
            />
          </div>
        </portal>

        <portal to="options-dropdown">
          <div class="flex flex-col gap-y-2">
            <new-button
              data-cy="compose-autoplug"
              v-if="postType !== 'recurrent'"
              @click="isAutoplugModalVisible = true"
              :inactive="!isAutoplugEnabled"
              type="dropdown"
              size="sm"
            >
              <inline-svg
                src="/img/icons/autoplugs-icon.svg"
                :class="{
                  'stroke-current': !isAutoplugEnabled,
                  'stroke-main-color-30': isAutoplugEnabled,
                }"
              />
              Autoplug
            </new-button>

            <new-button
              data-cy="compose-conditional-retweet"
              v-if="postType !== 'recurrent'"
              @click="isConditionalRetweetModalVisible = true"
              :inactive="!isConditionalRetweetEnabled"
              type="dropdown"
              size="sm"
            >
              <inline-svg
                src="/img/icons/new/retweet-side.svg"
                :class="{
                  'stroke-main-color-30': isConditionalRetweetEnabled,
                  'stroke-current': !isConditionalRetweetEnabled,
                }"
              />
              Conditional retweet
            </new-button>
            <hr v-if="postType !== 'recurrent'" class="h-0.5 border-none bg-ds-outline-secondary" />
            <div
              data-cy="compose-evergreen"
              class="flex items-center gap-x-3 px-3 text-sm"
              :class="{
                'text-gray-80': !isFavorite,
                'dropdown-main-text': isFavorite,
              }"
            >
              <inline-svg
                src="/img/icons/new/star.svg"
                :class="{
                  'stroke-main-color-30': isFavorite,
                  'stroke-gray': !isFavorite,
                }"
              />
              Set as evergreen
              <base-toggle v-model="isFavorite" />
            </div>
          </div>
        </portal>

        <div
          class="mt-auto flex w-full flex-col items-start justify-start gap-4"
          :class="{ 'lg:mx-auto': !isFocusModeEnabled, 'ml-auto': isFocusModeEnabled }"
        >
          <div
            v-if="showUserHasReachedSchedulingLimitWarning && postType !== 'recurrent'"
            class="alert_container flex w-full gap-3 rounded-lg bg-ds-system-alert-background px-6 py-4 text-black"
          >
            <div>
              <inline-svg
                src="/img/icons/new/alert.svg"
                class="h-5 w-5 text-ds-system-alert-background"
              />
            </div>
            <div class="flex w-full flex-col items-start justify-center">
              <div class="flex w-full items-center justify-between">
                <p class="text-base font-medium text-ds-text-primary">
                  No more available time slots!
                </p>
                <inline-svg
                  src="/img/icons/close.svg"
                  class="h-4 w-4 cursor-pointer text-ds-text-secondary"
                  @click="showSchedulingLimitNotification = false"
                />
              </div>
              <p class="text-sm text-ds-text-primary opacity-50">
                You currently don't have any free slots available. If you create a post, it will be
                scheduled for 10 minutes from now.
                <button class="underline" @click="switchToScheduleAndCloseComposer">
                  You can add new slots here,
                </button>
              </p>
            </div>
          </div>
          <div
            class="mx-auto flex w-full flex-row items-center gap-y-3"
            :class="[
              postType === 'twitter' ? 'justify-between' : 'justify-between lg:justify-center',
              {
                'bg-main-canvas fixed z-50 px-4 py-3': isMobileKeyboardVisible,
              },
            ]"
            :style="`bottom: ${isMobileKeyboardVisible ? 'var(--keyboard-size)' : 'auto'}`"
          >
            <label
              v-if="canShowPreview"
              for="preview-toggle"
              class="hidden items-center gap-x-1.5 lg:flex"
            >
              Show preview
              <base-toggle
                data-cy="show-preview"
                id="preview-toggle"
                :value="isPreviewShown"
                @input="togglePreview"
              />
            </label>
            <div
              v-if="isFocusModeEnabled && isATweetExceedingCharLimit"
              class="flex items-center gap-2 rounded-lg bg-ds-system-error-background px-3 py-1"
            >
              <inline-svg class="text-ds-system-error-text" src="img/icons/warning.svg" />
              <p class="text-md">tweet is over {{ tweetsCharactersLimit }} characters</p>
            </div>
            <template v-if="!isDesktop && (selectedTab === 'twitter' || selectedTab === 'threads')">
              <portal-target
                v-for="tweet in tweets"
                :key="tweet.guid"
                :name="`tweet-actions-${tweet.guid}`"
              />
            </template>
            <portal-target
              v-if="!isDesktop && selectedTab === 'linkedin'"
              :name="`linkedin-actions-${tweets[0].guid}`"
            />
            <portal-target
              v-if="!isDesktop && selectedTab === 'facebook'"
              :name="`facebook-actions-${tweets[0].guid}`"
            />
            <portal-target
              v-if="!isDesktop && selectedTab === 'instagram'"
              :name="`instagram-actions-${tweets[0].guid}`"
            />
            <div class="ml-auto flex items-center gap-x-4">
              <tooltip
                :disabled="canThreadBeStoredAsADraft"
                v-if="
                  ['twitter', 'recurrent'].includes(postType) &&
                  selectedTab !== 'reel-editor' &&
                  !isAMAPost
                "
                data-cy="compose-button-tooltip"
                :content="
                  cannotSchedulePosts ? 'You don\'t have permission to create posts'
                  : linkedInInfo.customCarouselFileName.trim() === ''
                    ? 'Carousel title cannot be empty'
                    : 'Your post is unfinished'
                "
                class="w-full sm:w-auto"
                :class="{
                  'hidden lg:inline': !threadToReplace && postType !== 'recurrent',
                }"
              >
                <span class="block sm:inline-block">
                  <new-button
                    class="w-full sm:w-auto"
                    :disabled="!canThreadBeStoredAsADraft || !canThreadBeScheduledWithoutLink"
                    @click="saveDraft"
                    type="secondary"
                    data-cy="compose-left-button"
                  >
                    <span v-if="threadToEdit && isDraft">Update</span>
                    <span v-else-if="postType === 'recurrent'">
                      {{ threadToReplace ? 'Replace' : 'Save' }} post
                    </span>
                    <span v-else>Save to drafts</span>
                  </new-button>
                </span>
              </tooltip>
              <template v-if="postType !== 'recurrent'">
                <template
                  v-if="
                    ['instagram', 'linkedin', 'facebook', 'threads', 'tiktok'].includes(postType)
                  "
                >
                  <tooltip
                    :content="
                      postType === 'tiktok' && !canTiktokPostBeScheduled
                        ? shareOnTiktokTooltip
                        : `You don't have permission to create posts`
                    "
                    :disabled="!cannotSchedulePosts && canTiktokPostBeScheduled"
                  >
                    <new-button
                      :disabled="!canPostBeScheduledIgnoreTime()"
                      type="secondary"
                      data-cy="post-now-button"
                      @click="showPostConfirmationModal"
                    >
                      <span class="flex w-full items-center gap-x-3"> Post now </span>
                    </new-button>
                  </tooltip>
                  <tooltip
                    :content="
                      postType === 'tiktok' && !canTiktokPostBeScheduled
                        ? shareOnTiktokTooltip
                        : `You don't have permission to create posts`
                    "
                    :disabled="!cannotSchedulePosts && canTiktokPostBeScheduled"
                  >
                    <div>
                      <new-button
                        :disabled="!canPostBeScheduledIgnoreTime()"
                        @click="schedulePostOrShowModal"
                        data-cy="compose-right-button"
                      >
                        <span
                          v-if="
                            ['instagram', 'linkedin', 'facebook', 'threads', 'tiktok'].includes(
                              postType,
                            ) && threadToEdit
                          "
                          >Update</span
                        >
                        <span
                          v-else-if="
                            ['instagram', 'linkedin', 'facebook', 'threads', 'tiktok'].includes(
                              postType,
                            )
                          "
                          >Schedule</span
                        >
                      </new-button>
                    </div>
                  </tooltip>
                </template>
                <new-drop-down
                  v-else
                  ref="postActions"
                  :extended="timeFromParent !== null"
                  :extendedButtonType="isDesktop ? 'rounded-right' : 'primary'"
                  :padded="false"
                  :icon="!isDesktop"
                  :buttonType="getButtonType"
                  :buttonSize="isDesktop ? 'md' : 'lg'"
                  data-cy="compose-right-button"
                >
                  <template #button>
                    <span class="hidden lg:inline">{{ getScheduleButtonText }}</span>
                    <inline-svg
                      src="/img/icons/arrow-right-icon.svg"
                      class="h-6 w-6 stroke-current lg:hidden"
                    />
                  </template>
                  <template #extended-button>
                    <div class="hidden lg:block">
                      <new-button
                        :disabled="!canPostBeScheduledIgnoreTime()"
                        @click="addToQueue"
                        type="rounded-left"
                      >
                        <span class="hidden lg:inline">{{ getScheduleButtonText }}</span>
                        <inline-svg
                          src="/img/icons/arrow-right-icon.svg"
                          class="h-6 w-6 stroke-current lg:hidden"
                        />
                      </new-button>
                    </div>
                  </template>
                  <template>
                    <div
                      class="flex w-full flex-col items-center gap-y-3 px-3 pb-4 lg:w-80 lg:px-0"
                    >
                      <div
                        v-if="selectedTab === 'reel-editor'"
                        class="flex w-full gap-x-2 rounded-t-lg bg-ds-button-secondary-background p-4"
                        :class="{
                          'items-center': videosLeft > 1,
                          'items-start': videosLeft <= 1,
                        }"
                      >
                        <inline-svg
                          src="/img/icons/new/video-credits.svg"
                          class="text-ds-button-icon"
                        />
                        <div>
                          <p class="text-ds-button-icon">{{ videosLeft }}/30 videos left</p>
                          <p v-if="videosLeft === 1" class="text-ds-button-primary-hover">
                            You are about to run out of your monthly videos.
                          </p>
                          <p v-if="videosLeft <= 0" class="text-ds-button-primary-hover">
                            You have spent all your videos for this month.
                          </p>
                          <!-- <new-button v-if="videosLeft <= 1 " size="sm" class="mt-2"
                            >Upgrade to {{ this.getPlanLabel('premium') }}</new-button
                          > -->
                        </div>
                      </div>
                      <div class="flex w-full flex-col-reverse gap-y-3 lg:flex-col">
                        <div class="flex w-full flex-col gap-y-3 lg:flex-col">
                          <tooltip
                            content="You don't have permission to create posts"
                            :disabled="!cannotSchedulePosts"
                          >
                            <new-button
                              :disabled="!canPostBeScheduledIgnoreTime()"
                              :type="isDesktop ? 'dropdown' : 'dropdown-full-secondary'"
                              data-cy="post-now-button"
                              @click="showPostConfirmationModal"
                            >
                              <span
                                class="flex items-center gap-x-3 lg:w-full"
                                :class="{ 'pt-4': selectedTab !== 'reel-editor' && isDesktop }"
                              >
                                <inline-svg
                                  src="/img/icons/post-now-icon.svg"
                                  class="h-5 w-5 stroke-ds-button-icon"
                                />
                                Post now
                              </span>
                            </new-button>
                          </tooltip>
                          <tooltip
                            content="You don't have permission to create posts"
                            :disabled="!cannotSchedulePosts"
                          >
                          <new-button
                            v-if="isDraft && threadToEdit"
                            :disabled="!canAddCategory()"
                            @click="setAsRecurrentPost"
                            :type="isDesktop ? 'dropdown' : 'dropdown-full-secondary'"
                            data-cy="add-to-category-button"
                          >
                            <span class="flex items-center gap-x-3 lg:w-full">
                              <inline-svg
                                src="/img/icons/new/category.svg"
                                class="h-5 w-5 text-ds-button-icon"
                              />
                              <span>Add to category</span>
                            </span>
                          </new-button>
                        </tooltip>
                          <new-button
                            @click="isFavorite = !isFavorite"
                            :type="isDesktop ? 'dropdown' : 'dropdown-full-secondary'"
                            data-cy="set-evergreen-button"
                          >
                            <span class="flex items-center gap-x-3 lg:w-full">
                              <inline-svg
                                src="/img/icons/star-icon.svg"
                                class="h-5 w-5"
                                :class="{
                                  'fill-success-100 stroke-success-100': isFavorite,
                                  'stroke-ds-button-icon': !isFavorite,
                                }"
                              />
                              <span>{{
                                isFavorite
                                  ? 'Unset as an Evergreen post'
                                  : 'Set as an Evergreen post'
                              }}</span>
                            </span>
                          </new-button>
                          <hr class="dropdown-separator hidden lg:block" />
                          <div class="flex w-full flex-col gap-y-3">
                            <tooltip
                              content="You don't have permission to create posts"
                              :disabled="!cannotSchedulePosts"
                            >
                              <new-button
                                v-if="isAReply && !threadToEdit"
                                :disabled="!canPostBeScheduledIgnoreTime()"
                                @click="addToQueue(true)"
                                :type="isDesktop ? 'dropdown' : 'dropdown-full-secondary'"
                                data-cy="add-to-queue-button"
                              >
                                <span class="flex items-center gap-x-3 lg:w-full">
                                  <inline-svg
                                    src="/img/icons/new/arrow.svg"
                                    class="h-5 w-5 stroke-ds-button-icon"
                                  />
                                  <span>Selected slot</span>
                                  <span class="ml-auto opacity-50">{{ timeToShow }}</span>
                                </span>
                              </new-button>
                              <new-button
                                v-else
                                :disabled="!canPostBeScheduledIgnoreTime()"
                                @click="addToQueue(true)"
                                :type="isDesktop ? 'dropdown' : 'dropdown-full-primary'"
                                data-cy="add-to-queue-button"
                              >
                                <span class="flex items-center gap-x-3 lg:w-full">
                                  <inline-svg
                                    src="/img/icons/new/arrow.svg"
                                    class="h-5 w-5 stroke-ds-button-primary-label"
                                  />
                                  <span v-if="hasUserReachedSchedulingLimits"> Schedule </span>
                                  <span v-else> Schedule on next free slot </span>
                                  <span data-cy="next-free-slot-time" class="ml-auto opacity-50">{{
                                    getFormattedNextFreeSlot
                                  }}</span>
                                </span>
                              </new-button>
                            </tooltip>
                            <div class="block w-full lg:hidden">
                              <tooltip
                                content="You don't have permission to create posts"
                                :disabled="!cannotSchedulePosts"
                              >
                                <new-button
                                  :disabled="!canPostBeScheduledIgnoreTime()"
                                  @click="addToQueue"
                                  v-if="timeFromParent !== null"
                                  :type="isDesktop ? 'dropdown' : 'dropdown-full-primary'"
                                  data-cy="add-to-queue-button"
                                >
                                  <span>{{ getScheduleButtonText }}</span>
                                </new-button>
                              </tooltip>
                            </div>
                          </div>
                        </div>
                        <!-- for AI Features -->
                        <!-- <new-button type="dropdown">
                            <span class="flex w-full items-center gap-x-3">
                              <inline-svg
                                src="/img/icons/ai-request.svg"
                                class="h-5 w-5 stroke-current"
                              />
                              Optimise using AI
                            </span>
                          </new-button> -->
                        <div
                          class="mb-8 mt-3 flex w-full flex-col-reverse gap-y-3 lg:mb-0 lg:mt-0 lg:gap-y-0"
                        >
                          <tooltip
                            content="You don't have permission to create posts"
                            :disabled="!cannotSchedulePosts"
                          >
                            <new-button
                              v-if="!isAReply"
                              :disabled="!canPostBeScheduledIgnoreTime()"
                              @click="schedulePostOrShowModal"
                              :type="isDesktop ? 'dropdown' : 'dropdown-full'"
                              data-cy="choose-time-button"
                            >
                              <span class="flex items-center gap-x-3 lg:w-full">
                                <inline-svg
                                  src="/img/icons/new-clock-icon.svg"
                                  class="h-5 w-5 stroke-ds-button-secondary-label"
                                />
                                {{ isDesktop ? 'Choose a time' : 'Schedule & choose a time' }}
                              </span>
                            </new-button>
                          </tooltip>
                          <div class="grid grid-cols-2 gap-x-3" v-if="!isDesktop">
                            <!-- <hr class="dropdown-separator mx-3" v-if="selectedTab !== 'reel-editor'" /> -->
                            <new-button
                              v-if="
                                ['twitter', 'recurrent'].includes(postType) &&
                                selectedTab !== 'reel-editor'
                              "
                              :disabled="!canThreadBeStoredAsADraft"
                              @click="saveDraft"
                              type="dropdown-full"
                            >
                              <span v-if="threadToEdit && isDraft">Update</span>
                              <span v-else-if="postType === 'recurrent'">
                                {{ threadToReplace ? 'Replace' : 'Save' }} post
                              </span>
                              <span v-else class="flex w-full items-center gap-x-3">
                                <inline-svg
                                  stroke-width="2"
                                  src="/img/icons/new/drafts.svg"
                                  class="h-5 w-5 stroke-ds-button-secondary-label"
                                />
                                Save draft
                              </span>
                            </new-button>
                            <!-- <hr
                              class="dropdown-separator mx-3"
                              v-if="selectedTab !== 'instagram' && selectedTab !== 'reel-editor'"
                            /> -->
                            <new-button
                              @click="showPreview"
                              v-if="canShowPreview"
                              type="dropdown-full"
                            >
                              <span class="flex w-full items-center gap-x-3">
                                <inline-svg
                                  src="/img/icons/eye.svg"
                                  class="h-5 w-5 stroke-ds-button-secondary-label"
                                />
                                Show preview
                              </span>
                            </new-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </new-drop-down>
                <tooltip
                  content="You don't have permission to update posts"
                >
                  <new-button
                    v-if="isThreadUpdatable"
                    :disabled="!canPostBeScheduledIgnoreTime()"
                    @click="updateThreadWithoutChangingTheTime()"
                    data-cy="compose-update-button"
                  >
                    <span>Update</span>
                  </new-button>
                </tooltip>
              </template>
            </div>
          </div>
        </div>
      </div>

      <new-conditional-retweet-modal
        :show="isConditionalRetweetModalVisible"
        :originalThread="threadToEdit ? threadToEdit : threadToReplace"
        @close="isConditionalRetweetModalVisible = false"
        @values-set="updateParentValues"
      />

      <new-autoplug-modal
        :show="isAutoplugModalVisible"
        :originalThread="threadToEdit ? threadToEdit : threadToReplace"
        @close="isAutoplugModalVisible = false"
        @values-set="updateParentValues"
        :isThreadToReplace="Boolean(threadToReplace)"
      />

      <upgrade-to-next-plan-pop-up
        :show="showNextPlanPopUp"
        :content="nextPlanPopUpContent"
        @close="showNextPlanPopUp = false"
        :nextPlan="nextPlan"
      />

      <!--Pick a time modal  -->
      <new-calendar-modal
        v-if="isTimeModalVisible"
        :timeSlots="timeSlots"
        :fullTime="fullTime"
        :threadToEdit="threadToEdit"
        :timeFromParent="timeFromParent"
        :canPostBeScheduled="canPostBeScheduled()"
        @close="isTimeModalVisible = false"
        @values-set="updateParentValues"
        @schedule-post="schedulePost()"
        @schedule-reel="scheduleReel()"
      />
      <tweetshot-modal
        :show="isTweetshotModalVisible"
        :tweetshotContentFromParent="tweetshotContent"
        @close="isTweetshotModalVisible = false"
        @update-tweetshot-content="updateTweetshotContent"
      />
    </div>
  </slide-over>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import lodash from 'lodash';
  import moment from 'moment';
  import 'moment-timezone';
  import NewComposerTextArea from '@/components/NewComposer/NewComposerTextArea.vue';
  import NewComposerPrompts from '@/components/NewComposer/NewComposerPrompts.vue';
  import NewComposerPreview from '@/components/NewComposer/NewComposerPreview.vue';
  import NewComposerInstagram from '@/components/NewComposer/NewComposerInstagram.vue';
  import NewComposerTiktok from '@/components/NewComposer/NewComposerTiktok.vue';
  import NewComposerFacebook from '@/components/NewComposer/NewComposerFacebook.vue';
  import NewComposerLinkedIn from '@/components/NewComposer/NewComposerLinkedIn.vue';
  import NewComposerReelEditor from '@/components/NewComposer/NewComposerReelEditor.vue';
  import NewConditionalRetweetModal from '@/components/NewComposer/Modals/NewConditionalRetweetModal.vue';
  import NewAutoplugModal from '@/components/NewComposer/Modals/NewAutoplugModal.vue';
  import NewDelayBetweenTweets from '@/components/NewComposer/NewDelayBetweenTweets.vue';
  import TweetshotModal from '@/components/NewComposer/Modals/TweetshotModal.vue';
  import LoadingScheduler from '@/components/Loading/LoadingScheduler.vue';
  import QuoteTweetMixin from '@/views/Mixins/QuoteTweetMixin.vue';
  import { defaultThreadFinisherText } from '@/views/Composer/config';
  import { countTweetLength } from '@/../functions/src/util/countTweetLength';
  import { getSelectionText } from '@/util/getSelectionText';
  import { splitTextIntoTweets } from '@/util/splitTextIntoTweets';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import UploadContainerMixin from '@/views/Mixins/UploadContainerMixin.vue';
  import {
    getUnfinishedPost,
    setUnfinishedPost,
    removeUnfinishedPost,
  } from '@/util/unfinishedPosts';
  import LinkedInMixin from '@/views/Mixins/LinkedInMixin.vue';
  import FacebookMixin from '@/views/Mixins/FacebookMixin.vue';
  import { Thread } from '@/models/Thread';
  import NewThreadMixin from '@/views/Mixins/NewThreadMixin.vue';
  import { DraftThread } from '@/models/DraftThread';
  import { store } from '@/store';
  import controller from '@/controller';
  import storage from '@/storage';
  import { formatTweet } from '@/util/formatTweet';
  // import NewComposerAI from '@/components/NewComposer/NewComposerAI.vue';
  import TweetDisplay from '../TweetDisplay.vue';
  import SlideOver from '@/components/SlideOver.vue';
  import NewComposerDrafts from '@/components/NewComposer/NewComposerDrafts.vue';
  import BreakpointsMixin from '@/views/Mixins/BreakpointsMixin.vue';
  import MobileMixin from '@/views/Mixins/MobileMixin.vue';
  import { EventBus } from '@/event-bus';
  import UpgradeToNextPlanPopUp from '@/components/UpgradeToNextPlanPopUp.vue';
  import UpgradeToNextPlanMixin from '../../views/Mixins/UpgradeToNextPlanMixin.vue';
  import NewReplyComposer from './NewReplyComposer.vue';
  import NewCalendarModal from './Modals/NewCalendarModal.vue';
  import FadeTransition from 'vue2-transitions/src/Fade/FadeTransition';
  import NewFocusModeComposer from './NewFocusModeComposer.vue';
  import CustomerStatusMixin from '../../views/Mixins/CustomerStatusMixin.vue';
  import NewComposerThreads from './NewComposerThreads.vue';
  import TweetCharactersLimitMixin from '../../views/Mixins/TweetCharactersLimitMixin.vue';

  const config = require('@/config');
  const fb = require('@/firebase');
  const didUserReachDraftsLimit = require('@/../functions/src/util/didUserReachDraftsLimit');

  export default {
    name: 'new-composer',
    components: {
      UpgradeToNextPlanPopUp,
      // NewComposerAI,
      NewComposerReelEditor,
      NewComposerTextArea,
      NewComposerPrompts,
      NewComposerPreview,
      NewComposerInstagram,
      NewComposerFacebook,
      NewComposerLinkedIn,
      NewConditionalRetweetModal,
      NewAutoplugModal,
      TweetshotModal,
      NewDelayBetweenTweets,
      LoadingScheduler,
      TweetDisplay,
      SlideOver,
      NewComposerDrafts,
      NewReplyComposer,
      NewCalendarModal,
      FadeTransition,
      NewFocusModeComposer,
      NewComposerThreads,
      NewComposerTiktok,
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState([
        'isWholeScheduleLoaded',
        'schedule',
        'userBestTweetsCategory',
        'userBestTweetsCount',
        'bestTweetIndex',
        'cannotSchedulePosts',
      ]),
      isAMAPost() {
        return this.isAMA || (this.threadToEdit && this.threadToEdit.ama);
      },
      commandOrControlIcon() {
        return navigator.platform.includes('Win') ? 'Ctrl' : '⌘';
      },
      timeSlots() {
        /*
         * I added this check to address a console error that occurred when the page loaded,
         * as the schedule is not yet loaded at page load.
         *  Previously, the error did not occur because this computed property was only triggered when the user clicked the 'pick time' button.
         *  However, since the new modal now uses v-show to enable animations and not v-if, this had to be added."
         */
        if (!this.schedule) return [];
        const midnight = moment.tz(this.date, this.timezone).startOf('day').toISOString();
        const slots = this.schedule.getThreadsByDate()[midnight];
        const emptySlots = slots ? slots.filter((slot) => slot.slotType === 'empty') : [];
        return emptySlots;
      },
      canAddMoreVideos() {
        return (
          Array.isArray(this.tweets) &&
          this.tweets.filter((t) => t.mediaFile[0] && t.mediaFile[0].type.includes('video'))
            .length < 10
        );
      },
      imageCountInThread() {
        if (!Array.isArray(this.tweets)) return 0;

        const count = this.tweets.reduce(
          (acc, val) =>
            acc +
            val.mediaFile.filter((media) => media.type.toLowerCase().includes('image')).length,
          0
        );
        return count;
      },
      tweetsCombinedStatuses() {
        if (!['twitter', 'recurrent'].includes(this.postType)) return '';

        const tweetsWithoutThreadFinisher = this.hasThreadFinisherTweet
          ? this.tweets.filter((_, idx) => this.tweets.length - 1 !== idx)
          : this.tweets;
        return tweetsWithoutThreadFinisher.map((tweet) => tweet.status).join('\n\n');
      },
      fullTime() {
        if (!this.date || !this.time) return null;
        return `${this.date}T${this.time}`;
      },
      minDate() {
        return moment().format('YYYY-MM-DD');
      },
      validTweets() {
        return this.tweets
          .filter(
            (tweet) =>
              countTweetLength(tweet.status.trim()) > 0 ||
              tweet.mediaFile.length > 0 ||
              tweet.quoteTweetData
          )
          .map((tweet, i) => ({
            ...tweet,
            count: i,
            status: tweet.status.trim(),
          }));
      },
      linkedInInfo() {
        const linkedInInfo = {
          text: this.linkedInText,
          isPostPublic: this.isLinkedInPostPublic,
          idsToPostAs: this.selectedLinkedInIds,
          postType: this.linkedInPostType,
          didUserEditLinkedInText: this.didUserEditLinkedInText,
          customCarouselFileName: this.customCarouselFileName,
        };

        if (this.linkedInPostType === 'carousel' && this.linkedInThreadFinisherText) {
          linkedInInfo.threadFinisherText = this.linkedInThreadFinisherText;
        }

        return linkedInInfo;
      },
      facebookInfo() {
        return {
          text: this.facebookText,
          didUserEditFacebookText: this.didUserEditFacebookText,
        };
      },
      tiktokInfo() {
        return {
          caption: this.tiktokCaption,
          threadFinisherText: this.tiktokThreadFinisherText,
          privacyStatus: this.tiktokPrivacyStatus,
          ...this.tiktokSettings,
        };
      },
      conditionalRetweetInfo() {
        if (this.isConditionalRetweetEnabled && this.postType !== 'recurrent') {
          const conditionIsFavorite = this.conditionalRetweetType === 'Likes';
          return {
            delayForRetweet: this.delayForRetweet ? this.delayForRetweet : '1 hour',
            minRetweetsThreshold: conditionIsFavorite ? null : Number(this.minRetweetsThreshold),
            minFavoritesThreshold: conditionIsFavorite ? Number(this.minFavoritesThreshold) : null,
          };
        } else {
          return null;
        }
      },
      autoplugInfo() {
        if (this.isAutoplugEnabled && this.postType !== 'recurrent') {
          const conditionIsFavorite = this.autoplugType === 'Likes';
          const autoplugConditions = {
            processed: false,
            minRetweets: conditionIsFavorite ? null : Number(this.autoplugMinRetweets),
            minFavorites: conditionIsFavorite ? Number(this.autoplugMinFavorites) : null,
          };
          if (this.autoplugTemplate && !this.wasAutoplugTemplateEdited) {
            autoplugConditions.templateName = this.autoplugTemplate.name;
            autoplugConditions.status = this.autoplugTemplate.content;
          } else {
            autoplugConditions.templateName = null;
            autoplugConditions.status = this.autoplugStatus;
          }

          return autoplugConditions;
        } else {
          return null;
        }
      },
      isDelayBetweenTweetsInfoValid() {
        if (this.validTweets.length === 1) {
          return true;
        } else {
          return !isNaN(this.delayBetweenTweets) && this.delayBetweenTweets >= 0;
        }
      },
      shareOnInstagramTooltip() {
        if (this.hasThreadFinisherTweet ? this.tweets.length > 11 : this.tweets.length > 10) {
          return "Instagram doesn't support carousels with more than 10 images";
        } else if (!this.tweets.every((tweet) => tweet.mediaFile.length === 0)) {
          return "Can't post tweetshot of a tweet with image";
        } else {
          return 'Share on Instagram';
        }
      },
      shareOnFacebookTooltip() {
        return this.facebookConditions.canShare
          ? 'Post on Facebook'
          : 'Facebook only supports posting either 1 GIF, 1 video or multiple images';
      },
      shareOnLinkedInTooltip() {
        return this.linkedInConditions.canShare
          ? 'Post on LinkedIn'
          : 'LinkedIn only supports posting either 1 video or up to 9 images';
      },
      shareOnTiktokTooltip() {
        const errors = [];
        const mediaFiles = this.tweets[0].mediaFile;

        // Privacy status check
        if (!this.tiktokPrivacyStatus) {
          errors.push('Privacy status is required');
        }

        // Caption length checks
        if (this.tiktokCaption.length === 0) {
          errors.push('Caption is required');
        } else if (this.tiktokCaption.length > 2200) {
          errors.push('Caption must be 2200 characters or less');
        }

        // Media upload check
        if (this.uploadingMedia.length > 0) {
          errors.push('Please wait for media upload to complete');
        }

        // Media validation
        if (mediaFiles.length === 0) {
          errors.push('Media is required (one video or at least 2 images)');
        } else if (mediaFiles.length === 1 && !mediaFiles[0].type.startsWith('video')) {
          errors.push('Single media must be a video');
        } else if (
          mediaFiles.length >= 2 &&
          !mediaFiles.every((file) => file.type.startsWith('image'))
        ) {
          errors.push('Multiple media must all be images');
        } else if (mediaFiles.length === 1 && mediaFiles[0].type.startsWith('image')) {
          errors.push('Single image is not allowed, minimum 2 images required');
        }

        // Brand content checks
        if (this.tiktokSettings.discloseContent &&
          !this.tiktokSettings.yourBrand &&
          !this.tiktokSettings.brandedContent
        ) {
          errors.push('Please select brand content options');
        }

        return errors.length > 0 ? errors.join('\n') : '';
      },
      canShareOnInstagram() {
        return (
          (this.hasThreadFinisherTweet ? this.tweets.length <= 11 : this.tweets.length <= 10)
        );
      },
      canShareOnThreads() {
        const doSecondaryTweetsHaveOneMediaOrLess = this.tweets
          .slice(1)
          .every((tweet) => tweet.mediaFile.length <= 1);

        const areAllTweetsBelowOrEqualToCharacterLimit = this.tweets.every(
          (tweet) => countTweetLength(tweet.status) <= 500,
        );

        return (
          areAllTweetsBelowOrEqualToCharacterLimit &&
          (this.tweets.length === 1 || doSecondaryTweetsHaveOneMediaOrLess)
        );
      },
      canShareOnTiktok() {
        return (
          (this.hasThreadFinisherTweet ? this.tweets.length <= 36 : this.tweets.length <= 35) &&
          this.tweets.every((tweet) => tweet.mediaFile.length === 0)
        );
      },
      facebookConditions() {
        const conditions = {
          canShare: false,
          canAddMedia: false,
        };

        if (Array.isArray(this.tweets)) {
          /*
           * We are counting videos and GIFs attached to the thread to see if we can add another Media
           * since Facebook allows only one GIF, one video, or multiple images per post
           * This computed property is used when Facebook cross posting feature is enabled
           */
          const videoCounter = this.tweets.reduce(
            (acc, val) =>
              acc +
              val.mediaFile.filter((media) => media.type.toLowerCase().includes('video')).length,
            0
          );

          const gifCounter = this.tweets.reduce(
            (acc, val) =>
              acc +
              val.mediaFile.filter((media) => media.type.toLowerCase().includes('gif')).length,
            0
          );

          conditions.canAddMedia = gifCounter === 0 && videoCounter === 0;
          conditions.canShare =
            gifCounter <= 1 &&
            videoCounter <= 1 &&
            (gifCounter === 0 || videoCounter === 0) &&
            ((gifCounter === 0 && videoCounter === 0) || this.imageCountInThread === 0);
        }
        return conditions;
      },
      linkedInConditions() {
        const conditions = {
          canShare: false,
          canAddMedia: false,
        };

        if (Array.isArray(this.tweets)) {
          const videoCounter = this.tweets.reduce(
            (acc, val) =>
              acc +
              val.mediaFile.filter((media) => media.type.toLowerCase().includes('video')).length,
            0
          );

          conditions.canAddMedia = videoCounter === 0 && this.imageCountInThread < 9;

          conditions.canShare =
            (videoCounter === 1 && this.imageCountInThread === 0) ||
            (videoCounter === 0 && this.imageCountInThread <= 9);
        }

        return conditions;
      },
      isShareOnLinkedInInfoValid() {
        if (this.isShareOnLinkedInEnabled) {
          return (
            (this.linkedInPostType === 'carousel' &&
              this.linkedInInfo.customCarouselFileName.trim() !== '' &&
              (!this.isLinkedInThreadFinisherEnabled ||
                (countTweetLength(this.linkedInThreadFinisherText) > 0 &&
                  countTweetLength(this.linkedInThreadFinisherText) <=
                    this.tweetCharactersLimit))) ||
            (this.linkedInText &&
              this.linkedInText.trim().length > 0 &&
              this.linkedInText.trim().length <= 3000)
          );
        } else {
          return true;
        }
      },
      showLinkedInWarning() {
        if (
          (this.linkedInText.trim().length === 0 && this.validTweets.length === 0) ||
          this.linkedInInfo.customCarouselFileName.trim() === ''
        ) {
          return true;
        }
        return !this.isShareOnLinkedInInfoValid;
      },
      isShareOnFacebookInfoValid() {
        if (this.isShareOnFacebookEnabled) {
          return (
            this.facebookText &&
            this.facebookText.trim().length > 0 &&
            this.facebookText.trim().length <= 63206
          );
        } else {
          return true;
        }
      },
      isShareOnInstagramInfoValid() {
        if (this.isShareOnInstagramEnabled) {
          return (
            !this.instagramCaption ||
            ((this.instagramCaption.match(/#/g) || []).length <= 30 &&
              (this.instagramCaption.match(/@/g) || []).length <= 20 &&
              this.instagramCaption.length <= 2200)
          );
        } else {
          return true;
        }
      },
      isShareOnThreadsInfoValid() {
        if (this.isShareOnThreadsEnabled) {
          return this.canShareOnThreads;
        } else {
          return true;
        }
      },
      isShareOnTiktokInfoValid() {
        if (this.isShareOnTiktokEnabled) {
          return (
            this.tiktokCaption &&
            this.tiktokCaption.length <= 2200 &&
            this.tiktokPrivacyStatus &&
            (!this.tiktokSettings.discloseContent ||
              this.tiktokSettings.yourBrand ||
              this.tiktokSettings.brandedContent)
          );
        }
        return true;
      },
      isPollsInfoValid() {
        return this.tweets.every((tweet) => this.isTweetPollInfoValid(tweet));
      },
      isConditionalRetweetInfoValid() {
        if (this.isConditionalRetweetEnabled) {
          return (
            (!lodash.isNil(this.minFavoritesThreshold) &&
              Number(this.minFavoritesThreshold) >= 1) ||
            (!lodash.isNil(this.minRetweetsThreshold) && Number(this.minRetweetsThreshold) >= 1)
          );
        } else {
          return true;
        }
      },
      isAutoplugInfoValid() {
        if (this.isAutoplugEnabled) {
          const areMinFavoritesConditionsValid =
            this.autoplugType === 'Likes' &&
            !lodash.isNil(this.autoplugMinFavorites) &&
            Number(this.autoplugMinFavorites) >= 0;
          const areMinRetweetsConditionsValid =
            this.autoplugType === 'Retweets' &&
            !lodash.isNil(this.autoplugMinRetweets) &&
            Number(this.autoplugMinRetweets) >= 0;

          return (
            (areMinFavoritesConditionsValid || areMinRetweetsConditionsValid) &&
            this.autoplugStatus &&
            this.autoplugStatus.trim().length > 0
          );
        } else {
          return true;
        }
      },
      timeToShow() {
        if (!this.isWholeScheduleLoaded) return;

        const time = this.timeFromParentLocal
          ? this.timeFromParentLocal
          : this.schedule.getNextTimeSlot();

        if (!time) {
          const tenMinutesFromNow = moment().add(10, 'minutes');
          return `${tenMinutesFromNow.format('dd')} at ${tenMinutesFromNow.format('H:mm')}`;
        }

        return `${time.format('dd')} at ${time.format('H:mm')}`;
      },
      previousThreadType() {
        return this.previousThread instanceof DraftThread ? 'draft' : 'thread';
      },
      canInstagramPostBeScheduled() {
        return Boolean(
          this.instagramCaption.length < 2200 &&
            this.instagramCaption.length > 0 &&
            !this.isSubmitting &&
            (this.tweets[0].mediaFile.length > 0 ||
              this.tweetshotContent.length > 0 ||
              this.reelOptions) &&
            this.uploadingMedia.length === 0,
        );
      },
      canFacebookPostBeScheduled() {
        return (
          (this.facebookText.length > 0 ||
            this.tweets[0].mediaFile.length > 0 ||
            this.tweetshotContent.length > 0) &&
          this.facebookText.length <= 63206 &&
          this.uploadingMedia.length === 0
        );
      },
      canLinkedInPostBeScheduled() {
        return (
          (this.linkedInText.length > 0 ||
            this.tweets[0].mediaFile.length > 0 ||
            this.tweetshotContent.length > 0) &&
          this.linkedInText.length <= 3000 &&
          this.tweets[0].mediaFile.length <= 9 &&
          this.tweetshotContent.length <= 9 &&
          this.linkedInInfo.customCarouselFileName.trim() !== '' &&
          this.uploadingMedia.length === 0
        );
      },
      canThreadsPostBeScheduled() {
        const areAllTweetValid = this.tweets.every((tweet, index) => {
          const isInitialPostMediaValid = index === 0 && tweet.mediaFile.length <= 10;
          // Secondary posts count as a reply so they only allow posting one media file
          const isSecondaryPostMediaValid = index !== 0 && tweet.mediaFile.length <= 1;

          return (
            (tweet.status.length > 0 || tweet.mediaFile.length > 0) &&
            tweet.status.length <= 500 &&
            (isInitialPostMediaValid || isSecondaryPostMediaValid)
          );
        });
        return areAllTweetValid && this.uploadingMedia.length === 0;
      },
      canTiktokPostBeScheduled() {
        const mediaFiles = this.tweets[0].mediaFile;

        const hasValidMedia =
          mediaFiles.length > 0 &&
          ((mediaFiles.length === 1 && mediaFiles[0].type.includes('video')) ||
            (mediaFiles.length >= 2 && mediaFiles.every((file) => file.type.includes('image'))));

        return Boolean(
          this.tiktokPrivacyStatus &&
            this.tiktokCaption.length > 0 &&
            this.tiktokCaption.length <= 2200 &&
            this.uploadingMedia.length === 0 &&
            (hasValidMedia || this.tweetshotContent.length > 0) &&
            (!this.tiktokSettings.discloseContent ||
              this.tiktokSettings.yourBrand ||
              this.tiktokSettings.brandedContent),
        );
      },
      canThreadBeStoredAsADraft() {
        const shouldIgnoreTime = true;

        // Recurrent posts are drafts but we need to check the tweets length since they will be posted in due time
        const shouldIgnoreTweetsLength = this.postType !== 'recurrent';

        return this.canPostBeScheduled(shouldIgnoreTime, shouldIgnoreTweetsLength);
      },
      isMobileKeyboardVisible() {
        return this.isMobile && this.isComposerFocused && this.isKeyboardVisible;
      },
      categories() {
        return this.selectedCategories.map((category) => category.id);
      },

      canThreadBeScheduledWithoutLink() {
        if (!this.isAReply) {
          return true;
        } else {
          return this.originalTweetInfo ? true : false;
        }
      },
      getButtonType() {
        return (this.threadToEdit && this.threadToEdit.publishingError !== null) || this.isDraft
          ? 'primary'
          : this.threadToEdit
          ? 'secondary'
          : 'primary';
      },
      isThreadUpdatable() {
        return (
          this.threadToEdit &&
          !this.isDraft &&
          ['recurrent', 'twitter'].includes(this.postType) &&
          this.threadToEdit.publishingError === null
        );
      },
      videosLeft() {
        return 30 - this.userProfile.reels.renderCount;
      },
      getComposerTitle() {
        return !this.isAReply && this.selectedTab !== 'reel-editor'
          ? 'Create'
          : this.selectedTab === 'reel-editor'
          ? 'Edit Reel'
          : 'Schedule a reply';
      },
      getScheduleButtonText() {
        if (this.threadToEdit && !this.isDraft) {
          return 'Reschedule';
        } else if (this.timeFromParentLocal) {
          return `Schedule on ${this.timeFromParentLocal.format('ddd, HH:mm')}`;
        } else {
          return 'Schedule';
        }
      },
      getNextFreeSlot() {
        if (!this.schedule) return moment().add(10, 'minutes');
        return this.schedule.getNextTimeSlot() ?? moment().add(10, 'minutes');
      },
      getFormattedNextFreeSlot() {
        return this.getNextFreeSlot.format('ddd, HH:mm');
      },
      hasUserReachedSchedulingLimits() {
        if (!this.schedule) return false;
        return this.schedule.hasUserReachedSchedulingLimits();
      },
      showUserHasReachedSchedulingLimitWarning() {
        return this.showSchedulingLimitNotification && this.hasUserReachedSchedulingLimits;
      },
      getSlideOverWidth() {
        if (this.isFocusModeEnabled) {
          return 'w-full';
        } else {
          return this.showQuickView.enable ? 'lg:max-w-7xl' : 'lg:max-w-3.5xl';
        }
      },
      getComposerWidth() {
        if (this.isFocusModeEnabled) {
          return 'w-3/4';
        } else {
          return this.showQuickView.enable ? 'w-full lg:w-3/5 xl:w-1/2' : 'w-full';
        }
      },
      isATweetExceedingCharLimit() {
        return this.tweets.some(
          (tweet) => countTweetLength(tweet.status) > this.tweetCharactersLimit,
        );
      },
      canShowPreview() {
        return (
          this.selectedTab !== 'instagram' &&
          this.postType !== 'instagram' &&
          this.selectedTab !== 'reel-editor' &&
          !this.isFocusModeEnabled
        );
      },
    },
    data() {
      return {
        areDraftsVisible: false,
        isAIOn: false,
        cachedGeneratedAITweets: [],
        isGeneratingAIResult: false,
        showDelay: false,
        isSidebarVisible: false,
        timeFromParentLocal: null,
        isPreviewVisible: false,
        arePromptsVisible: false,
        isAIVisible: false,
        isShareOnFacebookEnabled: false,
        isShareOnInstagramEnabled: false,
        isShareOnLinkedInEnabled: false,
        isShareOnTiktokEnabled: false,
        isShareOnThreadsEnabled: false,
        resetLinkedIn: null,
        resetFacebookManualEdit: null,
        previousThread: null,
        isConditionalRetweetEnabled: false,
        delayForRetweet: '12 hours',
        minRetweetsThreshold: null,
        minFavoritesThreshold: null,
        conditionalRetweetType: 'Retweets',
        isAutoplugEnabled: false,
        autoplugStatus: '',
        autoplugMinRetweets: 100,
        autoplugMinFavorites: 0,
        autoplugTemplate: null,
        wasAutoplugTemplateEdited: false,
        autoplugType: 'Retweets',
        wasSubmitted: false,
        currentlyFocusedTweetIndex: 0,
        linkedInPostType: 'text',
        postType: 'twitter',
        isTweetshotModalVisible: false,
        wasUpdated: false,
        initialComposerPosition: 0,
        hasInspirationTweet: false,
        isComposerFocused: false,
        showQuickView: {
          content: false,
          enable: false,
        },
        selectedCategories: [],
        tiktokPrivacyStatus: null,
        tiktokSettings: {
          comments: false,
          duet: false,
          stitch: false,
          discloseContent: false,
          yourBrand: false,
          brandedContent: false,
        },
        ...this.initialState(),
        show: false,
        originalTweetInfo: null,
        isAReply: false,
        isPreviewShown: false,
        isDraftShown: false,
        isPromptsShown: false,
        hasStartedEditing: false,
        isFocusModeEnabled: false,
        selectedDraft: null,
        showSchedulingLimitNotification: true,
      };
    },
    mounted() {
      if (this.showPromptsOnLoad) {
        this.togglePrompts();
      }

      if (this.showDraftsOnLoad) {
        this.toggleDrafts();
      }

      this.hasStartedEditing = this.hasStartedEditingFromParent;
      this.isAIOn = this.$route.query.ai === 'on';

      if (!this.schedule) {
        store.dispatch('fetchSchedule');
      }

      const cachedGeneratedAITweets = localStorage.getItem('generatedAITweets');

      if (cachedGeneratedAITweets) {
        this.cachedGeneratedAITweets = JSON.parse(cachedGeneratedAITweets);
      }

      if (this.postTypeFromParent) {
        this.postType = this.postTypeFromParent;
      }

      if (this.categoriesFromParent) {
        this.selectedCategories = this.categoriesFromParent;
      }

      if (this.youtubeShortRef) {
        this.tweets = [
          {
            ...this.emptyTweet(0),
            status: 'youtube short',
            mediaFile: [
              {
                type: 'video',
                thumbnail: config.buildStorageMediaURL(this.youtubeShortRef.data().thumbnailPath),
                thumbnailPath: this.youtubeShortRef.data().thumbnailPath,
                status: this.youtubeShortRef.data().status,
                url: lodash.get(this.youtubeShortRef.data(), 'outputFileUrl', null),
                name: this.youtubeShortRef.data().name,
                _type: 'youtube-short',
              },
            ],
            fileList: [
              {
                type: 'video',
                thumbnail: config.buildStorageMediaURL(this.youtubeShortRef.data().thumbnailPath),
                thumbnailPath: this.youtubeShortRef.data().thumbnailPath,
                url: lodash.get(this.youtubeShortRef.data(), 'outputFileUrl', null),
                status: this.youtubeShortRef.data().status,
                _type: 'youtube-short',
              },
            ],
          },
        ];
        if (this.timeFromParent) {
          this.timeFromParentLocal = this.timeFromParent;
          this.updateFullTime(this.timeFromParentLocal);
        }
      } else if (this.threadFromParent) {
        if (
          ['twitter', 'recurrent', 'threads'].includes(this.postType) &&
          this.selectedTab !== 'reel-editor'
        ) {
          this.updateComposerFromThread(this.threadFromParent);
        } else if (this.postType === 'instagram') {
          this.updateComposerFromInstagramPost(this.threadFromParent);
        } else if (this.postType === 'facebook') {
          this.updateComposerFromFacebookPost(this.threadFromParent);
        } else if (this.postType === 'linkedin') {
          this.updateComposerFromLinkedInPost(this.threadFromParent);
        } else if (this.postType === 'tiktok') {
          this.updateComposerFromTiktokPost(this.threadFromParent);
        }
      } else {
        if (this.timeFromParent) {
          this.timeFromParentLocal = this.timeFromParent;
          this.updateFullTime(this.timeFromParentLocal);
        } else if (this.schedule) {
          this.setTimeToNextTimeSlot();
        }
      }

      if (this.postType === 'twitter' && !this.threadFromParent) {
        this.isShareOnFacebookEnabled = lodash.get(
          this,
          'userProfile.settings.isShareOnFacebookEnabled',
          false
        );
        this.isShareOnInstagramEnabled = lodash.get(
          this,
          'userProfile.settings.isShareOnInstagramEnabled',
          false
        );
        this.isShareOnLinkedInEnabled = lodash.get(
          this,
          'userProfile.settings.isShareOnLinkedInEnabled',
          false
        );
        this.isShareOnThreadsEnabled = lodash.get(
          this,
          'userProfile.settings.isShareOnThreadsEnabled',
          false,
        );
        this.isShareOnTiktokEnabled = lodash.get(
          this,
          'userProfile.settings.isShareOnTiktokEnabled',
          false,
        );
      }

      if (lodash.has(this.$route.query, 'content')) {
        this.tweets[0].status = this.$route.query.content;
      }

      if (this.tweetsFromParent && this.tweetsFromParent.length > 0) {
        this.tweets = this.tweetsFromParent;
      } else if (lodash.get(this.userProfile, 'settings.shouldAutoFillEmptyComposer', false)) {
        this.fillComposerWithUserBestTweet();
      }

      this.setTweetIndexToFocus(this.tweets.length - 1);
      this.show = true;

      EventBus.$on('show-loader', () => {
        this.isSubmitting = true;
      });

      EventBus.$on('hide-loader', () => {
        this.isSubmitting = false;
      });

      EventBus.$on('set-uploading-media', (i) => {
        this.uploadingMedia = [...this.uploadingMedia, i];
        setUnfinishedPost(
          this.tweets,
          this.isFavorite,
          this.isShareOnInstagramEnabled,
          this.hasThreadFinisherTweet
        );
      });

      EventBus.$on('unset-uploading-media', (i) => {
        this.uploadingMedia = [...this.uploadingMedia.filter((idx) => idx !== i)];
        setUnfinishedPost(
          this.tweets,
          this.isFavorite,
          this.isShareOnInstagramEnabled,
          this.hasThreadFinisherTweet
        );
      });

      if (this.isAReplyFromParent === true) {
        this.isAReply = true;
      } else {
        this.isAReply = false;
      }
    },
    methods: {
      formatTweet,
      addSendUsDMButton(index) {
        this.tweets[
          index
        ].status = `${this.tweets[index].status}\nhttps://twitter.com/messages/compose?recipient_id=${this.userProfile.twitterId}`;
      },
      setSubmitting(val) {
        this.isSubmitting = val;
      },
      invalidateAITweetCache() {
        this.cachedGeneratedAITweets = JSON.parse(localStorage.getItem('generatedAITweets'));
      },
      updateTweetshotContent(content) {
        this.tweetshotContent = content;
      },
      getSlotTime(slotTime, hourType) {
        const timeFormat = hourType === 24 ? 'HH:mm' : 'hh:mm A';
        return slotTime.format(timeFormat);
      },
      postNow() {
        const shouldPostNow = true;
        if (this.selectedTab !== 'reel-editor' || this.threadToEdit) {
          this.schedulePost(shouldPostNow);
        } else {
          this.scheduleReel(shouldPostNow);
        }
      },
      initialState() {
        return {
          selectedTab: this.defaultTab,
          isTimeModalVisible: false,
          tweets: getUnfinishedPost() ? getUnfinishedPost().tweets : [this.emptyTweet(0)],
          tweetIndexToSelectGifFor: null,
          hasThreadFinisherTweet: getUnfinishedPost()
            ? getUnfinishedPost().hasThreadFinisherTweet
            : false,
          isDelayModalVisible: false,
          isConditionalRetweetModalVisible: false,
          delayBetweenTweets: 0,
          instagramCaption: '',
          instagramThreadFinisherText: null,
          tiktokThreadFinisherText: null,
          isLongTweetshot: false,
          isLargeFontTweetshot: false,
          facebookText: '',
          didUserEditFacebookText: false,
          linkedInText: '',
          isLinkedInPostPublic: true,
          selectedLinkedInIds: [],
          isLinkedInThreadFinisherEnabled: false,
          linkedInThreadFinisherText: '',
          didUserEditLinkedInText: false,
          customCarouselFileName: 'Shared from Hypefury',
          date: null,
          time: null,
          isSubmitting: false,
          showCategoriesModal: false,
          isFavorite: getUnfinishedPost() ? getUnfinishedPost().isFavorite : false,
          isAutoplugModalVisible: false,
          threadToEdit: null,
          threadToWatch: null,
          isDraft: false,
          threadToReplace: null,
          tweetshotContent: [],
          originalData: [],
          reelOptions: null,
          focusModeComposerStatus: 'FILLING',
          uploadingMedia: [],
          tiktokCaption: '',
        };
      },
      setCurrentlyFocusedTweetIndex(element) {
        this.currentlyFocusedTweetIndex = Number(element.target.id);
      },
      editDraft(draft) {
        if (draft.replyToTweetId) {
          this.isAReply = true;
          this.originalTweetInfo = draft.replyToTweetInfo;
          this.threadToEdit = draft;
        } else {
          this.isAReply = false;
          this.selectedTab = 'twitter';
        }
        this.isDraft = true;
        this.hasStartedEditing = true;
        this.updateComposerFromThread(draft);
        this.setTweetIndexToFocus(this.tweets.length - 1);
        this.selectedDraft = draft.id;
        if (!this.isDesktop) this.closeSidebar();
      },
      updateComposerFromThread(thread) {
        if (this.unsubscribeFromThreadToWatch) {
          this.unsubscribeFromThreadToWatch();
        }

        if (
          (this.postType === 'recurrent' && this.isReplacingPost) ||
          (this.sourceFromParent && this.sourceFromParent.name === 'Analytics')
        ) {
          this.threadToReplace = thread;
        } else {
          this.threadToEdit = thread;

          if (this.isDraft) {
            this.setTimeToNextTimeSlot();
          } else {
            this.updateFullTime(this.threadToEdit.time);
          }

          if (this.postType !== 'recurrent') {
            // We need to watch the thread to edit in case it gets scheduled
            this.unsubscribeFromThreadToWatch = fb.threadsCollection
              .doc(this.threadToEdit.id)
              .onSnapshot((doc) => {
                this.threadToWatch = doc.data();
              });
          }
        }

        this.tweets = thread.tweets.map((tweet) => {
          const newTweet = lodash.cloneDeep(tweet);

          if (newTweet.media) {
            newTweet.mediaFile = [];
            newTweet.media.forEach((media, i) => {
              const mediaName = media.name;
              const mediaType = media.type;
              const mediaAltText = media.altText;
              if (media._type === 'youtube-short') {
                newTweet.mediaFile[i] = {
                  ...media,
                  type: this.getMediaType(mediaType),
                  thumbnail: config.buildStorageMediaURL(media.thumbnail),
                };
              } else {
                newTweet.mediaFile[i] = {
                  name: mediaName,
                  type: this.getMediaType(mediaType),
                  mimeType: mediaType,
                  url: config.buildStorageMediaURL(mediaName),
                  altText: mediaAltText,
                  thumbnail: media.thumbnail,
                };
              }
              newTweet.fileList = [];
            });
          }

          return {
            ...this.emptyTweet(),
            ...newTweet,
            ...(this.threadToReplace || this.postType === 'recurrent' ? { published: false } : {}),
          };
        });
        this.hasThreadFinisherTweet = thread.hasThreadFinisherTweet || false;
        this.delayBetweenTweets = thread.delayBetweenTweets || 0;
        this.isFavorite = thread.isFavorite;
        this.isShareOnFacebookEnabled = Boolean(thread.facebook);
        this.isShareOnLinkedInEnabled = Boolean(thread.linkedIn);
        this.isShareOnInstagramEnabled = Boolean(thread.shareOnInstagram);
        this.isShareOnThreadsEnabled = Boolean(thread.threads);
        this.isShareOnTiktokEnabled = Boolean(thread.tiktok);

        if (thread.categories) {
          this.selectedCategories = thread.categories;
        }

        this.originalData = {
          tweets: lodash.cloneDeep(this.tweets),
          isFavorite: thread.isFavorite,
          isShareOnInstagramEnabled: Boolean(thread.shareOnInstagram),
          isShareOnFacebookEnabled: Boolean(thread.facebook),
          isShareOnLinkedInEnabled: Boolean(thread.linkedIn),
          isShareOnTiktokEnabled: Boolean(thread.tiktok),
          facebookText: lodash.get(thread, 'facebook.text', ''),
          linkedInText: lodash.get(thread, 'linkedIn.text', ''),
          tiktokCaption: lodash.get(thread, 'tiktok.caption', ''),
          tweetshotContent: lodash.cloneDeep(thread.tweetshotContent) || [],
          instagramCaption: thread.instagramCaption || '',
          instagramThreadFinisherText: thread.instagramThreadFinisherText,
          tiktokThreadFinisherText: lodash.get(thread, 'tiktok.threadFinisherText', ''),
          isLinkedInPostPublic: lodash.get(thread, 'linkedIn.isPostPublic', true),
          isLongTweetshot: thread.isLongTweetshot,
          isLargeFontTweetshot: thread.isLargeFontTweetshot,
          tiktokPrivacyStatus: lodash.get(thread, 'tiktok.privacyStatus', null),
          tiktokSettings: lodash.get(thread, 'tiktok.settings', {
            comments: false,
            duet: false,
            stitch: false,
            discloseContent: false,
            yourBrand: false,
            brandedContent: false,
          }),
        };
      },
      updateComposerFromInstagramPost(post) {
        if (this.unsubscribeFromThreadToWatch) {
          this.unsubscribeFromThreadToWatch();
        }

        this.threadToEdit = post;
        this.updateFullTime(this.threadToEdit.time);
        this.timeFromParentLocal = this.threadToEdit.time;
        if (this.threadToEdit.tweetshotContent) {
          this.tweetshotContent = this.threadToEdit.tweetshotContent;
        }
        // We need to watch the thread to edit in case it gets scheduled
        this.unsubscribeFromThreadToWatch = fb.threadsCollection
          .doc(this.threadToEdit.id)
          .onSnapshot((doc) => {
            this.threadToWatch = doc.data();
          });

        const newTweet = lodash.cloneDeep(post.tweets[0]);

        this.instagramCaption = newTweet.status;

        if (newTweet.media) {
          newTweet.mediaFile = [];
          newTweet.media.forEach((media, i) => {
            const mediaName = media.name;
            const mediaType = media.type;
            const mediaAltText = media.altText;
            if (media._type === 'youtube-short') {
              newTweet.mediaFile[i] = {
                ...media,
                type: this.getMediaType(mediaType),
                thumbnail: config.buildStorageMediaURL(media.thumbnail),
              };
            } else {
              newTweet.mediaFile[i] = {
                name: mediaName,
                type: this.getMediaType(mediaType),
                mimeType: mediaType,
                url: config.buildStorageMediaURL(mediaName),
                altText: mediaAltText,
                thumbnail: media.thumbnail,
              };
            }
            newTweet.fileList = [];
          });
        }

        this.tweets = [
          {
            ...this.emptyTweet(),
            ...newTweet,
          },
        ];

        this.originalData = {
          tweets: lodash.cloneDeep(this.tweets),
          instagramCaption: newTweet.status,
          tweetshotContent: lodash.cloneDeep(this.tweetshotContent) || [],
          isLongTweetshot: post.isLongTweetshot,
          isLargeFontTweetshot: post.isLargeFontTweetshot,
        };
      },
      updateComposerFromFacebookPost(post) {
        if (this.unsubscribeFromThreadToWatch) {
          this.unsubscribeFromThreadToWatch();
        }

        this.threadToEdit = post;
        this.updateFullTime(this.threadToEdit.time);
        this.timeFromParentLocal = this.threadToEdit.time;
        if (this.threadToEdit.tweetshotContent) {
          this.tweetshotContent = this.threadToEdit.tweetshotContent;
        }
        // We need to watch the thread to edit in case it gets scheduled
        this.unsubscribeFromThreadToWatch = fb.threadsCollection
          .doc(this.threadToEdit.id)
          .onSnapshot((doc) => {
            this.threadToWatch = doc.data();
          });

        const newTweet = lodash.cloneDeep(post.tweets[0]);

        this.facebookText = newTweet.status;

        if (newTweet.media) {
          newTweet.mediaFile = [];
          newTweet.media.forEach((media, i) => {
            const mediaName = media.name;
            const mediaType = media.type;
            const mediaAltText = media.altText;
            newTweet.mediaFile[i] = {
              name: mediaName,
              type: this.getMediaType(mediaType),
              mimeType: mediaType,
              url: config.buildStorageMediaURL(mediaName),
              altText: mediaAltText,
              thumbnail: media.thumbnail,
            };
            newTweet.fileList = [];
          });
        }

        this.tweets = [
          {
            ...this.emptyTweet(),
            ...newTweet,
          },
        ];

        this.originalData = {
          tweets: lodash.cloneDeep(this.tweets),
          facebookText: newTweet.status,
          tweetshotContent: lodash.cloneDeep(this.tweetshotContent) || [],
        };
      },
      updateComposerFromLinkedInPost(post) {
        if (this.unsubscribeFromThreadToWatch) {
          this.unsubscribeFromThreadToWatch();
        }

        this.threadToEdit = post;
        this.updateFullTime(this.threadToEdit.time);
        this.timeFromParentLocal = this.threadToEdit.time;

        if (this.threadToEdit.tweetshotContent) {
          this.tweetshotContent = this.threadToEdit.tweetshotContent;
        }

        // We need to watch the thread to edit in case it gets scheduled
        this.unsubscribeFromThreadToWatch = fb.threadsCollection
          .doc(this.threadToEdit.id)
          .onSnapshot((doc) => {
            this.threadToWatch = doc.data();
          });

        const newTweet = lodash.cloneDeep(post.tweets[0]);

        this.linkedInText = newTweet.status;

        if (newTweet.media) {
          newTweet.mediaFile = [];
          newTweet.media.forEach((media, i) => {
            const mediaName = media.name;
            const mediaType = media.type;
            const mediaAltText = media.altText;
            newTweet.mediaFile[i] = {
              name: mediaName,
              type: this.getMediaType(mediaType),
              mimeType: mediaType,
              url: config.buildStorageMediaURL(mediaName),
              altText: mediaAltText,
              thumbnail: media.thumbnail,
            };
            newTweet.fileList = [];
          });
        }

        this.tweets = [
          {
            ...this.emptyTweet(),
            ...newTweet,
          },
        ];

        this.originalData = {
          tweets: lodash.cloneDeep(this.tweets),
          linkedInText: newTweet.status,
          tweetshotContent: lodash.cloneDeep(this.tweetshotContent),
          isLinkedInPostPublic: lodash.get(post, 'linkedIn.isPostPublic', true),
        };
      },
      updateComposerFromTiktokPost(post) {
        if (this.unsubscribeFromThreadToWatch) {
          this.unsubscribeFromThreadToWatch();
        }

        this.threadToEdit = post;
        this.updateFullTime(this.threadToEdit.time);
        this.timeFromParentLocal = this.threadToEdit.time;
        if (this.threadToEdit.tweetshotContent) {
          this.tweetshotContent = this.threadToEdit.tweetshotContent;
        }
        // We need to watch the thread to edit in case it gets scheduled
        this.unsubscribeFromThreadToWatch = fb.threadsCollection
          .doc(this.threadToEdit.id)
          .onSnapshot((doc) => {
            this.threadToWatch = doc.data();
          });

        const newTweet = lodash.cloneDeep(post.tweets[0]);
        const tiktokCaption = lodash.get(post, 'tiktok.caption', '');
        this.tiktokCaption = tiktokCaption;
        const tiktokThreadFinisherText = lodash.get(post, 'tiktok.threadFinisherText', '');
        this.tiktokThreadFinisherText = tiktokThreadFinisherText;
        const tiktokPrivacyStatus = lodash.get(post, 'tiktok.privacyStatus', null);
        this.tiktokPrivacyStatus = tiktokPrivacyStatus;
        const tiktokSettings = lodash.get(post, 'tiktok.settings', {
          comments: false,
          duet: false,
          stitch: false,
          discloseContent: false,
          yourBrand: false,
          brandedContent: false,
        });

        if (newTweet.media) {
          newTweet.mediaFile = [];
          newTweet.media.forEach((media, i) => {
            const mediaName = media.name;
            const mediaType = media.type;
            const mediaAltText = media.altText;
            newTweet.mediaFile[i] = {
              name: mediaName,
              type: this.getMediaType(mediaType),
              mimeType: mediaType,
              url: config.buildStorageMediaURL(mediaName),
              altText: mediaAltText,
              thumbnail: media.thumbnail,
            };
            newTweet.fileList = [];
          });
        }

        this.tweets = [
          {
            ...this.emptyTweet(),
            ...newTweet,
          },
        ];

        this.originalData = {
          tweets: lodash.cloneDeep(this.tweets),
          tiktokCaption,
          tweetshotContent: lodash.cloneDeep(this.tweetshotContent) || [],
          tiktokThreadFinisherText,
          tiktokPrivacyStatus,
          tiktokSettings,
        };
      },
      onInputChange() {
        if (!this.threadToEdit && !this.threadToReplace) {
          setUnfinishedPost(
            this.tweets,
            this.isFavorite,
            this.isShareOnInstagramEnabled,
            this.hasThreadFinisherTweet
          );
        }
        this.hasStartedEditing = true;
      },
      resetComposer(time) {
        Object.assign(this.$data, this.initialState());
        this.setTweetIndexToFocus(0);
        this.setTimeToNextTimeSlot(time);
        this.resetFacebookManualEdit();
        this.resetLinkedIn();
        this.mediaToDelete = [];

        if (this.unsubscribeFromThreadToWatch) {
          this.unsubscribeFromThreadToWatch();
        }
      },
      setTimeToNextTimeSlot(time) {
        const nextTimeSlot = time
          ? this.schedule.getNextTimeSlot(time)
          : this.schedule.getNextTimeSlot();

        if (nextTimeSlot) {
          this.date = nextTimeSlot.format('YYYY-MM-DD');
          this.time = nextTimeSlot.format('HH:mm');
        } else {
          const tenMinutesFromNow = moment().add(10, 'minutes');
          this.date = tenMinutesFromNow.format('YYYY-MM-DD');
          this.time = tenMinutesFromNow.format('HH:mm');
        }
      },
      updateFullTime(time) {
        this.date = time ? time.format('YYYY-MM-DD') : null;
        this.time = time ? time.format('HH:mm') : null;
      },
      canAddGif(tweet) {
        return lodash.get(tweet, 'mediaFile', []).length === 0;
      },
      deleteTweet(tweetCount, isThreadFinisherTweet) {
        if (isThreadFinisherTweet) {
          this.hasThreadFinisherTweet = false;
        }

        this.tweets = this.tweets
          .filter((tweet) => tweet.count !== tweetCount)
          .map((tweet, index) => ({ ...tweet, count: index }));

        const tweetsCounts = this.tweets.map((tweet) => tweet.count);
        if (tweetsCounts.includes(tweetCount)) {
          this.setTweetIndexToFocus(tweetCount);
        } else {
          this.setTweetIndexToFocus(tweetCount - 1);
        }
      },
      async addGifToTweet(gifURL, tweetIndex) {
        if (!this.canAddGif(this.tweets[tweetIndex])) {
          return;
        }

        const image = await controller.proxyFile(this.currentUser, gifURL);
        this.tweets = this.tweets.map((tweet) => {
          if (tweet.count === tweetIndex) {
            return {
              ...tweet,
              mediaFile: [{ url: gifURL, type: 'gif' }],
            };
          }
          return tweet;
        });

        const f = new File([image], 'tenor.gif', { type: 'image/gif' });
        EventBus.$emit('set-uploading-media', 0);
        const { file, thumbnail } = await storage.uploadNewMedia(f);
        this.tweets = this.tweets.map((tweet) => {
          if (tweet.count === tweetIndex) {
            return {
              ...tweet,
              media: [{
                name: file.metadata.name,
                type: file.metadata.contentType,
                size: file.metadata.size,
                ...(thumbnail ? { thumbnail } : {}),
              }],
              mediaFile: [{ url: gifURL, type: 'gif' }],
            };
          }
          return tweet;
        });
        EventBus.$emit('unset-uploading-media', 0);
      },
      addTweet(tweet = {}, index = null) {
        if (!index) {
          const tweetToAdd = { ...this.emptyTweet(this.tweets.length), ...tweet };

          if (this.hasThreadFinisherTweet) {
            this.tweets = [...this.tweets.slice(0, -1), tweetToAdd, this.tweets.slice(-1)[0]].map(
              (tweet, index) => ({ ...tweet, count: index })
            );
            this.setTweetIndexToFocus(this.tweets.length - 2);
          } else {
            this.tweets = [...this.tweets, tweetToAdd];
            this.setTweetIndexToFocus(this.tweets.length - 1);
          }
        } else {
          this.tweets = [
            ...this.tweets.slice(0, index),
            { ...this.emptyTweet(index), ...tweet },
            ...this.tweets.slice(index),
          ].map((tweet, index) => ({ ...tweet, count: index }));
          this.setTweetIndexToFocus(index);
        }
        this.hasStartedEditing = true;
      },
      setThreadFromGptResult(statuses) {
        this.tweets = statuses.map((status, index) => {
          return {
            ...this.emptyTweet(index),
            status,
          };
        });
        this.setTweetIndexToFocus(0);
      },
      addThreadFinisherTweet() {
        this.$eventStore.composer('Action bar: Add thread finisher tweet');
        const previouslyFocusedTweetIndex = this.currentlyFocusedTweetIndex;
        const savedThreadFinisherText = lodash.get(this.userProfile, 'settings.threadFinisherText');
        this.addTweet({
          status: savedThreadFinisherText || defaultThreadFinisherText(this.userProfile.username),
        });
        this.hasThreadFinisherTweet = true;

        this.setTweetIndexToFocus(previouslyFocusedTweetIndex);
      },
      updateStatus(value, idx) {
        // TODO: refactor to reassign the array instead of mutating the array with fixing the bug of media description when status changes
        this.tweets[idx].status = value;
      },
      updateStatusFromFocusMode(value) {
        this.tweets = value;
      },
      clearMediaSelectionAtIndex(tweet, i) {
        const mediaName = tweet.media[i].name;
        const mediaThumbnail = tweet.media[i].thumbnail;
        this.setMediaToDelete(mediaName, mediaThumbnail);
        this.tweets = this.tweets.map((t) => {
          if (t.count === tweet.count) {
            const newMediaFile = t.mediaFile.filter((_, index) => index !== i);
            const newFileList = t.fileList.filter((_, index) => index !== i);
            const newMedia = t.media.filter((_, index) => index !== i);
            return {
              ...t,
              mediaFile: newMediaFile,
              fileList: newFileList,
              media: newMedia,
            };
          }
          return t;
        });
        setUnfinishedPost(
          this.tweets,
          this.isFavorite,
          this.isShareOnInstagramEnabled,
          this.hasThreadFinisherTweet
        );
      },
      insertAiTweet(status) {
        this.tweets = [{ ...this.emptyTweet(0), status }];
        this.setTweetIndexToFocus(0);
      },
      insertInspirationTweet(inspirationTweet) {
        this.hasStartedEditing = true;
        this.tweets = [
          {
            ...this.emptyTweet(0),
            status: inspirationTweet,
          },
          ...this.tweets.filter((tweet) => tweet.count !== 0),
        ];

        this.setTweetIndexToFocus(0);
      },
      // Limit max characters to 280 instead this.tweetCharactersLimit to accommodate Twitter Premium users who prefer the 280-character tweet splitting behavior.
      splitLongTextIntoTweets(event, tweetToStartFrom, maximumCharacters = 280) {
        if (!lodash.get(this.userProfile, 'settings.shouldSplitLongText')) return;

        const textToSplit = event.clipboardData.getData('text');
        const targetTweetIndex = tweetToStartFrom;
        const targetTweet = this.tweets[targetTweetIndex].status;
        const selectedText = getSelectionText();
        let text;
        if (!selectedText) {
          text = targetTweet + textToSplit;
        } else {
          const splitTargetTweet = targetTweet.split(selectedText);
          const isSelectedTextInTheMiddle = splitTargetTweet.length === 2;
          if (isSelectedTextInTheMiddle) {
            const [firstTweetPart, lastTweetPart] = splitTargetTweet;
            text = firstTweetPart + textToSplit + lastTweetPart;
          } else {
            this.$notify({
              type: 'warning',
              message: `Sorry, I am not smart enough to split this long text. :(\nPlease paste it again in a different tweet box.,`,
            });
            return;
          }
        }

        if (countTweetLength(text) < maximumCharacters) {
          return;
        }

        this.$notify({
          type: 'info',
          title: 'Text pasted!',
          message: `You can disable Auto-text-splitting in <a href="/settings">Settings</a>.`,
        });

        event.preventDefault();

        let tweetsBeforeCurrent = [];
        if (targetTweetIndex > 0) {
          tweetsBeforeCurrent = this.tweets.filter((tweet) => tweet.count < targetTweetIndex);
        }

        const statuses = splitTextIntoTweets(text, this.tweetCharactersLimit);

        const currentTweet = {
          ...this.tweets[targetTweetIndex],
          status: statuses[0],
        };

        const newTweets = [];
        for (let i = 1; i < statuses.length; i++) {
          const newTweet = this.emptyTweet(tweetsBeforeCurrent.length + i);
          newTweet.status = statuses[i];
          newTweets.push(newTweet);
        }

        let tweetsAfterCurrent = [];
        if (targetTweetIndex < this.tweets.length - 1) {
          tweetsAfterCurrent = this.tweets
            .filter((tweet) => tweet.count > targetTweetIndex)
            .map((t) => ({ ...t, count: t.count + newTweets.length }));
        }

        this.tweets = [...tweetsBeforeCurrent, currentTweet, ...newTweets, ...tweetsAfterCurrent];

        this.setTweetIndexToFocus(Math.max(...newTweets.map((t) => t.count)));
      },
      updateParentValues(data) {
        Object.entries(data).forEach(([key, value]) => {
          this[key] = value;
        });
      },
      addToQueue(shouldPostOnNextFreeSlot = false) {
        shouldPostOnNextFreeSlot === true
          ? this.updateFullTime(this.getNextFreeSlot)
          : this.timeFromParentLocal
          ? this.updateFullTime(this.timeFromParentLocal)
          : this.setTimeToNextTimeSlot();
        if (this.selectedTab !== 'reel-editor') {
          this.schedulePost();
        } else {
          this.scheduleReel();
        }
      },
      async saveDraft() {
        this.$eventStore.composer('Save to drafts');

        // isDraft is used so that we don't check for drafts limit when updating a draft
        if (!this.isDraft && didUserReachDraftsLimit(this.userProfile)) {
          this.openDraftsLimitNextPlanPopUp();
          return;
        }

        const draft = new DraftThread({
          tweets: this.validTweets,
          user: this.userProfile.uid,
          isFavorite: this.isFavorite,
          conditionalRetweetsConditions: this.conditionalRetweetInfo,
          autoplug: this.autoplugInfo,
          shareOnInstagram: this.isShareOnInstagramEnabled,
          linkedIn: this.isShareOnLinkedInEnabled ? this.linkedInInfo : null,
          facebook: this.isShareOnFacebookEnabled ? this.facebookInfo : null,
          tiktok: this.isShareOnTiktokEnabled ? this.tiktokInfo : null,
          delayBetweenTweets:
            this.validTweets.length > 1 && this.isDelayBetweenTweetsInfoValid
              ? this.delayBetweenTweets
              : null,
          categories: this.categories,
          replyToTweetId: this.isAReply ? this.originalTweetInfo.id : null,
          replyToTweetInfo: this.isAReply ? this.originalTweetInfo : null,
          instagramCaption: this.instagramCaption,
          isRecurrentPost: this.postType === 'recurrent',
          hasThreadFinisherTweet: this.hasThreadFinisherTweet,
          instagramThreadFinisherText: this.instagramThreadFinisherText,
          postIdToReplace: lodash.get(this.threadToReplace, 'id', null),
          isLongTweetshot: this.isLongTweetshot,
          isLargeFontTweetshot: this.isLargeFontTweetshot,
          threads: this.isShareOnThreadsEnabled ? { posts: this.validTweets } : null,
        });

        if (this.threadToEdit) {
          draft.id = this.threadToEdit.id;
        }

        this.selectedDraft = null;
        return this.saveOrUpdateThread(draft);
      },
      updateThreadWithoutChangingTheTime() {
        this.updateFullTime(this.threadToEdit.time);
        this.schedulePost();
      },
      getPostData(shouldPostNow = false) {
        if (this.postType === 'instagram') {
          return Thread.newThread({
            time: this.timeFromParentLocal,
            tweets: [
              {
                ...this.tweets[0],
                hashtags: '',
                status: this.instagramCaption,
              },
            ],
            user: this.userProfile.uid,
            postNow: shouldPostNow,
            source: 'instagram-post',
            tweetshotContent: this.tweetshotContent.length > 0 ? this.tweetshotContent : null,
            isLongTweetshot: this.isLongTweetshot,
            isLargeFontTweetshot: this.isLargeFontTweetshot,
            ...(this.threadToEdit && this.threadToEdit.tweetReel
              ? { tweetReel: this.threadToEdit.tweetReel }
              : null),
            ...(this.youtubeShortRef ? { youtubeShortRef: this.youtubeShortRef.id } : {}),
          });
        } else if (this.postType === 'facebook') {
          return Thread.newThread({
            time: this.timeFromParentLocal,
            tweets: [
              {
                ...this.tweets[0],
                status: this.facebookText,
              },
            ],
            user: this.userProfile.uid,
            postNow: shouldPostNow,
            facebook: {
              text: this.facebookText,
            },
            source: 'facebook-post',
            tweetshotContent:
              Array.isArray(this.tweetshotContent) && this.tweetshotContent.length > 0
                ? this.tweetshotContent
                : null,
          });
        } else if (this.postType === 'linkedin') {
          return Thread.newThread({
            time: this.timeFromParentLocal,
            tweets: [
              {
                ...this.tweets[0],
                status: this.linkedInText,
              },
            ],
            user: this.userProfile.uid,
            postNow: shouldPostNow,
            linkedIn: {
              isPostPublic: this.isLinkedInPostPublic,
              idsToPostAs: this.selectedLinkedInIds,
            },
            source: 'linkedin-post',
            tweetshotContent:
              Array.isArray(this.tweetshotContent) && this.tweetshotContent.length > 0
                ? this.tweetshotContent
                : null,
          });
        } else if (this.postType === 'threads') {
          return Thread.newThread({
            time: shouldPostNow ? new Date() : moment.tz(this.fullTime, this.timezone).toDate(),
            tweets: this.validTweets,
            user: this.userProfile.uid,
            postNow: shouldPostNow,
            threads: { posts: this.validTweets },
            source: 'threads-post',
          });
        } else if (this.postType === 'tiktok') {
          return Thread.newThread({
            time: this.timeFromParentLocal,
            tweets: [
              {
                ...this.tweets[0],
                status: this.tiktokCaption,
              },
            ],
            user: this.userProfile.uid,
            postNow: shouldPostNow,
            source: 'tiktok-post',
            tiktok: this.tiktokInfo,
            tweetshotContent: this.tweetshotContent.length > 0 ? this.tweetshotContent : null,
          });
        } else {
          if (this.isAMAPost) {
            return Thread.newThread({
              time: shouldPostNow ? new Date() : moment.tz(this.fullTime, this.timezone).toDate(),
              tweets: this.validTweets,
              user: this.userProfile.uid,
              postNow: shouldPostNow,
              ama: { isEnabled: true, numberOfRepliesAnswered: 0 },
            });
          } else {
            return Thread.newThread({
              time: shouldPostNow ? new Date() : moment.tz(this.fullTime, this.timezone).toDate(),
              tweets: this.validTweets,
              user: this.userProfile.uid,
              isFavorite: this.isFavorite,
              postNow: shouldPostNow,
              conditionalRetweetsConditions: this.conditionalRetweetInfo,
              autoplug: this.autoplugInfo,
              shareOnInstagram: this.isShareOnInstagramEnabled,
              linkedIn: this.isShareOnLinkedInEnabled ? this.linkedInInfo : null,
              facebook: this.isShareOnFacebookEnabled ? this.facebookInfo : null,
              tiktok: this.isShareOnTiktokEnabled ? this.tiktokInfo : null,
              delayBetweenTweets:
                this.validTweets.length > 1 && this.isDelayBetweenTweetsInfoValid
                  ? this.delayBetweenTweets
                  : null,
              categories: this.categories,
              replyToTweetId: this.isAReply ? this.originalTweetInfo.id : null,
              replyToTweetInfo: this.isAReply ? this.originalTweetInfo : null,
              instagramCaption: this.instagramCaption,
              hasThreadFinisherTweet: this.hasThreadFinisherTweet,
              instagramThreadFinisherText: this.instagramThreadFinisherText,
              isLongTweetshot: this.isLongTweetshot,
              isLargeFontTweetshot: this.isLargeFontTweetshot,
              ...(this.youtubeShortRef ? { youtubeShortRef: this.youtubeShortRef.id } : {}),
              ...(this.isShareOnThreadsEnabled ? { threads: { posts: this.validTweets } } : {}),
            });
          }
        }
      },
      async scheduleReel(shouldPostNow = false) {
        this.isSubmitting = true;
        this.isTimeModalVisible = false;

        const { tweet, options, docId } = this.reelOptions;

        const thread = Thread.newThread({
          time: shouldPostNow ? new Date() : moment.tz(this.fullTime, this.timezone).toDate(),
          tweets: [
            {
              status: this.instagramCaption,
            },
          ],
          user: this.userProfile.uid,
          source: 'instagram-post',
        });
        try {
          if (this.userProfile.reels.renderCount >= 30) {
            this.swalModal({
              title: 'Sorry',
              text: 'You have reached the limit of 30 reels rendered.',
              type: 'warning',
            });
            return;
          }
          const result = await thread.saveToFirestore(
            this.currentUser,
            this.userProfile.uid,
            this.timezone,
            this.$eventStore
          );

          const renderTasks = [
            {
              tweet,
              options,
              instagramPost: {
                id: result.postId,
                postNow: shouldPostNow,
              },
              originalPostId: docId,
            },
          ];

          const { data } = await controller.renderThreads(this.currentUser, renderTasks);
          if (data.success) {
            this.$notify({
              type: 'success',
              message: `Your post has been successfully published!`,
            });
          } else {
            this.swalModal({
              title: 'Sorry',
              text: 'Your reels changes will not be applied because you ran out of credits',
              type: 'warning',
            });
            return;
          }
        } catch (error) {
          this.swalModal({
            title: 'Sorry',
            text: `An error has occurred while scheduling the post.`,
            type: 'warning',
          });
        } finally {
          this.isSubmitting = false;
          setTimeout(() => {
            this.wasSubmitted = false;
          }, 10000);
          const scheduleDropdown = this.$refs.postActions;

          if (scheduleDropdown) {
            scheduleDropdown.close();
          }
        }
      },
      async schedulePost(shouldPostNow = false) {
        const shouldIgnoreTime = shouldPostNow;
        if (shouldIgnoreTime ? !this.canPostBeScheduledIgnoreTime() : !this.canPostBeScheduled()) {
          return;
        }

        const post = this.getPostData(shouldPostNow);

        if (this.threadToEdit) {
          post.id = this.threadToEdit.id;
        }

        if (this.selectedTab === 'reel-editor') {
          if (shouldPostNow) {
            this.$eventStore.tweetToVideo('Edit: Schedule: Post now');
          } else {
            this.$eventStore.tweetToVideo('Edit: Schedule: On next free slot');
          }
        } else {
          if (shouldPostNow) {
            this.$eventStore.composer('Schedule: Post now');
          } else {
            this.$eventStore.composer('Schedule: On next free slot');
          }
        }

        return this.saveOrUpdateThread(post);
      },
      async saveOrUpdateThread(thread) {
        this.isSubmitting = true;
        this.isTimeModalVisible = false;

        if (this.selectedTab === 'reel-editor') {
          const { options, tweet } = this.reelOptions;

          if (!lodash.isEqual(thread.tweetReel.options, options)) {
            const renderTasks = [
              {
                tweet,
                options,
                instagramPost: {
                  id: thread.id,
                  postNow: false,
                },
                originalPostId: thread.tweetReel.originalPostId,
              },
            ];

            const { data } = await controller.renderThreads(this.currentUser, renderTasks);
            if (!data.success) {
              this.swalModal({
                title: 'Sorry',
                text: 'You have reached the limit of 30 reels rendered.',
                type: 'warning',
              });
              this.isSubmitting = false;
              return;
            }
          }
        }

        thread.tweets = thread.tweets.map((tweet) => {
          const updatedTweet = {...tweet};
          delete updatedTweet.fileList;
          delete updatedTweet.mediaFile;
          return updatedTweet;
        });

        try {
          await storage.deleteOldMedia([this.mediaToDelete]);

          if (this.threadToEdit && thread.id) {
            await thread.update(this.currentUser, this.userProfile);
          } else {
            const result = await thread.saveToFirestore(
              this.currentUser,
              this.userProfile.uid,
              this.timezone,
              this.$eventStore
            );
            thread.id = result.postId;
          }

          if (this.timeFromParent) {
            this.timeFromParentLocal = this.schedule.getNextTimeSlot(this.timeFromParentLocal);
          }

          removeUnfinishedPost();

          this.previousThread = thread;

          this.wasSubmitted = true;

          if (this.threadToEdit) {
            this.wasUpdated = true;
          }

          const scheduleDropdown = this.$refs.postActions;

          if (scheduleDropdown) {
            scheduleDropdown.close();
          }

          if (this.sourceFromParent && !this.isDraft) {
            if (this.postType === 'twitter') {
              if (this.threadToEdit) {
                const threadOrTweet = thread.tweets.length > 1 ? 'Thread' : 'Tweet';
                const message = thread.postNow
                  ? `${threadOrTweet} on its way!`
                  : `${threadOrTweet} updated!`;
                this.$notify({ type: 'success', message });
              }
            } else if (this.postType === 'recurrent') {
              const threadOrTweet = thread.tweets.length > 1 ? 'Thread' : 'Tweet';
              const message = this.threadToReplace
                ? `${threadOrTweet} has been replaced successfully!`
                : `${threadOrTweet} has been saved successfully!`;
              this.$notify({ type: 'success', message });
            } else if (this.postType === 'threads') {
              if (this.threadToEdit) {
                const threadOrTweet = thread.tweets.length > 1 ? 'Thread' : 'Post';
                const message = thread.postNow
                  ? `${threadOrTweet} on its way!`
                  : `${threadOrTweet} updated!`;
                this.$notify({ type: 'success', message });
              }
            } else {
              const postTypeCapitalized =
                this.postType === 'linkedin' ? 'LinkedIn' : lodash.capitalize(this.postType);
              let action;
              if (thread.postNow) {
                action = 'is on its way!';
              } else if (this.threadToEdit) {
                action = 'has been updated successfully!';
              } else {
                action = 'has been scheduled successfully!';
              }

              this.$notify({ type: 'success', message: `${postTypeCapitalized} post ${action}` });
            }
            this.$refs.composerContainer.dismiss();
          } else {
            const time = thread.time && !this.threadToEdit ? thread.time : undefined;
            if (this.previousThread.postNow) {
              this.$notify({
                type: 'success',
                message: `Your post has been successfully published!`,
              });
            } else if (this.wasUpdated) {
              this.$notify({
                type: 'success',
                message: `Your post has been successfully updated!`,
              });
            } else if (this.previousThreadType === 'draft') {
              this.$notify({ type: 'success', message: `Your post has been added to Drafts!` });
            } else {
              this.$notify({
                type: 'success',
                message: `Your post has been scheduled successfully!`,
              });
            }

            this.isAReply && (this.isAReply = false);

            this.resetComposer(time);
            if (this.bestTweetIndex !== this.userBestTweetsCount) {
              store.commit('setBestTweetIndex', this.bestTweetIndex + 1);
            }
            this.hasStartedEditing = false;
          }

          if (lodash.get(this.userProfile, 'settings.shouldAutoFillEmptyComposer', false)) {
            this.$nextTick(() => {
              this.fillComposerWithUserBestTweet();
            });
          }
        } catch (error) {
          console.error(error);
          this.swalModal({
            title: 'Sorry',
            text: `An error has occurred while saving the thread.`,
            type: 'warning',
          });
        } finally {
          this.isSubmitting = false;
          setTimeout(() => {
            this.wasSubmitted = false;
          }, 10000);
          if (this.isAMAPost) {
            this.$router.push('queue');
          }
        }
      },
      canPostBeScheduled(shouldIgnoreTime, shouldIgnoreTweetsLength) {
        if (this.cannotSchedulePosts) {
          return false;
        }
        if (this.postType === 'instagram') {
          return this.canInstagramPostBeScheduled;
        } else if (this.postType === 'facebook') {
          return this.canFacebookPostBeScheduled;
        } else if (this.postType === 'linkedin') {
          return this.canLinkedInPostBeScheduled;
        } else if (this.postType === 'threads') {
          return this.canThreadsPostBeScheduled;
        } else if (this.postType === 'tiktok') {
          return this.canTiktokPostBeScheduled;
        } else {
          return this.canTwitterThreadBeScheduled(shouldIgnoreTime, shouldIgnoreTweetsLength);
        }
      },
      canTwitterThreadBeScheduled(shouldIgnoreTime = false, shouldIgnoreTweetsLength = false) {
        const tweetsOverCharactersLimit = this.tweets.filter(
          (t) => countTweetLength(t.status) > this.tweetCharactersLimit,
        );

        return Boolean(
          (shouldIgnoreTweetsLength || tweetsOverCharactersLimit.length === 0) &&
            (shouldIgnoreTime || this.isTimeValid(this.fullTime, this.threadToEdit)) &&
            this.isWholeScheduleLoaded &&
            !this.isSubmitting &&
            this.isPollsInfoValid &&
            this.validTweets.length > 0 &&
            this.isConditionalRetweetInfoValid &&
            this.isAutoplugInfoValid &&
            this.isShareOnLinkedInInfoValid &&
            this.isShareOnFacebookInfoValid &&
            this.isShareOnInstagramInfoValid &&
            this.isShareOnTiktokInfoValid &&
            this.isDelayBetweenTweetsInfoValid &&
            this.canThreadBeScheduledWithoutLink &&
            this.isShareOnThreadsInfoValid &&
            this.uploadingMedia.length === 0,
        );
      },
      canPostBeScheduledIgnoreTime() {
        const shouldIgnoreTime = true;
        return this.canPostBeScheduled(shouldIgnoreTime);
      },
      updatePoll(poll, tweetId) {
        this.tweets = this.tweets.map((tweet) =>
          tweet.guid === tweetId ? { ...tweet, poll } : tweet
        );
      },
      switchSelectedTab(tab, isEnabled) {
        if (isEnabled) {
          this.selectedTab = tab;
        }
      },
      handleCtrlEnter() {
        this.postType === 'twitter' ? this.addToQueue() : this.schedulePost();
      },
      schedulePostOrShowModal() {
        !['twitter', 'recurrent'].includes(this.postType)
          ? this.schedulePost()
          : (this.isTimeModalVisible = true);
      },
      showPostConfirmationModal() {
        this.confirmPostNow(
          this.canPostBeScheduledIgnoreTime(),
          this.isShareOnLinkedInEnabled && ['linkedin', 'twitter'].includes(this.postType),
          this.isShareOnFacebookEnabled && ['facebook', 'twitter'].includes(this.postType),
          this.postNow,
          this.postType
        );
      },
      fillComposerWithUserBestTweet() {
        const bestTweetsCategoryTweets = lodash.get(this.userBestTweetsCategory, 'tweets', []);
        if (
          bestTweetsCategoryTweets &&
          bestTweetsCategoryTweets.length > 0 &&
          this.tweets.length === 1 &&
          !this.hasStartedEditing &&
          !this.threadToReplace &&
          this.postType !== 'recurrent'
        ) {
          this.hasInspirationTweet = true;
          const tweet = bestTweetsCategoryTweets[this.bestTweetIndex];
          this.tweets[0].status = tweet.text;
        }
      },
      changeBestTweet(action) {
        if (action === 'next' && this.bestTweetIndex !== this.userBestTweetsCount) {
          store.commit('setBestTweetIndex', this.bestTweetIndex + 1);
        } else if (action === 'previous' && this.bestTweetIndex > 0) {
          store.commit('setBestTweetIndex', this.bestTweetIndex - 1);
        }
        this.fillComposerWithUserBestTweet();
      },
      clearComposer(idx) {
        const event = '';
        this.updateStatus(event, idx);
      },
      handleComposerFocus: lodash.debounce(
        function (isFocus) {
          if (isFocus === this.isComposerFocused) return;
          this.isComposerFocused = isFocus;
        },
        300,
        { maxWait: 500 }
      ),
      addCategory(category) {
        if (!this.selectedCategories.find((c) => c.id === category.id)) {
          this.selectedCategories.push(category);
        }
      },
      removeCategory(category) {
        this.selectedCategories = this.selectedCategories.filter((c) => c.id !== category.id);
      },
      togglePreview(enable) {
        this.$eventStore.composer('Show preview');
        if (enable) {
          this.isPreviewShown = true;
          this.showQuickView.content = true;
          this.showQuickView.enable = true;
        } else {
          this.showQuickView.content = false;
        }
      },
      isComposerDirty() {
        let data = {};
        if (['twitter', 'recurrent'].includes(this.postType)) {
          data = {
            tweets: lodash.cloneDeep(this.tweets),
            isFavorite: this.isFavorite,
            isShareOnInstagramEnabled: this.isShareOnInstagramEnabled,
            isShareOnFacebookEnabled: this.isShareOnFacebookEnabled,
            isShareOnLinkedInEnabled: this.isShareOnLinkedInEnabled,
            facebookText: this.facebookText,
            linkedInText: this.linkedInText,
            tweetshotContent: lodash.cloneDeep(this.tweetshotContent),
            instagramCaption: this.instagramCaption,
            instagramThreadFinisherText: this.instagramThreadFinisherText,
            isLinkedInPostPublic: this.isLinkedInPostPublic,
            isLongTweetshot: this.isLongTweetshot,
            isLargeFontTweetshot: this.isLargeFontTweetshot,
            tiktokCaption: this.tiktokCaption,
            tiktokThreadFinisherText: this.tiktokThreadFinisherText,
            tiktokPrivacyStatus: this.tiktokPrivacyStatus,
            tiktokSettings: this.tiktokSettings,
          };
        } else if (this.postType === 'instagram') {
          data = {
            tweets: this.tweets,
            instagramCaption: this.instagramCaption,
            tweetshotContent: this.tweetshotContent,
            isLongTweetshot: this.isLongTweetshot,
            isLargeFontTweetshot: this.isLargeFontTweetshot,
          };
        } else if (this.postType === 'facebook') {
          data = {
            tweets: this.tweets,
            facebookText: this.facebookText,
            tweetshotContent: this.tweetshotContent,
          };
        } else if (this.postType === 'linkedin') {
          data = {
            tweets: this.tweets,
            linkedInText: this.linkedInText,
            tweetshotContent: this.tweetshotContent,
            isLinkedInPostPublic: this.isLinkedInPostPublic,
          };
        } else if (this.postType === 'tiktok') {
          data = {
            tweets: this.tweets,
            tiktokCaption: this.tiktokCaption,
            tweetshotContent: this.tweetshotContent,
            tiktokThreadFinisherText: this.tiktokThreadFinisherText,
            tiktokPrivacyStatus: this.tiktokPrivacyStatus,
            tiktokSettings: this.tiktokSettings,
          };
        }

        if (this.threadToEdit) {
          return !lodash.isEqual(data, this.originalData);
        }
        return false;
      },
      attemptDismiss() {
        if (this.showQuickView.enable) {
          this.showQuickView.content = false;
          return;
        }

        if (this.isComposerDirty()) {
          this.swalModal({
            title: 'Are you sure?',
            text: `You have unsaved changes. Are you sure you want to dismiss this composer?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, dismiss it!',
            cancelButtonText: 'No, keep it',
          }).then((result) => {
            if (result.value) {
              this.$refs.composerContainer.dismiss();
            }
          });
        } else {
          this.$refs.composerContainer.dismiss();
        }
      },
      chooseTimeForDraft(draft) {
        this.editDraft(draft);
        this.isTimeModalVisible = true;
      },
      scheduleDraftOnNextFreeSlot(draft) {
        this.editDraft(draft);
        this.schedulePost();
      },
      showPreview() {
        this.showQuickView.content = true;
        this.showQuickView.enable = true;
        this.isPreviewShown = true;
      },
      togglePrompts() {
        this.$eventStore.composer('Inspiration');
        if (this.isPromptsShown) {
          this.showQuickView.content = false;
        } else {
          this.isPromptsShown = true;
          this.showQuickView.content = true;
          this.showQuickView.enable = true;
        }
      },
      toggleDrafts() {
        this.$eventStore.composer('Drafts');
        if (this.isDraftShown) {
          this.showQuickView.content = false;
        } else {
          this.isDraftShown = true;
          this.showQuickView.content = true;
          this.showQuickView.enable = true;
        }
      },
      handleQuickViewScroll(e) {
        const draftRef = this.$refs.drafts;
        if (draftRef && this.isDraftShown) {
          draftRef.onScroll(e);
        }
      },
      toggleFocusMode() {
        if (!this.isFocusModeEnabled) {
          this.focusModeComposerStatus = 'FILLING';
        }
        this.isFocusModeEnabled = !this.isFocusModeEnabled;
        const showPreview = this.isFocusModeEnabled;
        this.togglePreview(showPreview);
      },
      updateFocusModeStatus(status) {
        this.focusModeComposerStatus = status;
      },
      async setAsRecurrentPost() {
        EventBus.$emit('show-loader');
        try {
          await new DraftThread({
            id: this.threadToEdit.id,
            tweets: this.validTweets,
            user: this.userProfile.uid,
            isFavorite: this.isFavorite,
            conditionalRetweetsConditions: this.conditionalRetweetInfo,
            autoplug: this.autoplugInfo,
            shareOnInstagram: this.isShareOnInstagramEnabled,
            linkedIn: this.isShareOnLinkedInEnabled ? this.linkedInInfo : null,
            facebook: this.isShareOnFacebookEnabled ? this.facebookInfo : null,
            delayBetweenTweets:
              this.validTweets.length > 1 && this.isDelayBetweenTweetsInfoValid
                ? this.delayBetweenTweets
                : null,
            categories: this.categories,
            replyToTweetId: this.isAReply ? this.originalTweetInfo.id : null,
            replyToTweetInfo: this.isAReply ? this.originalTweetInfo : null,
            instagramCaption: this.instagramCaption,
            hasThreadFinisherTweet: this.hasThreadFinisherTweet,
            instagramThreadFinisherText: this.instagramThreadFinisherText,
            postIdToReplace: lodash.get(this.threadToReplace, 'id', null),
            isLongTweetshot: this.isLongTweetshot,
            isLargeFontTweetshot: this.isLargeFontTweetshot,
            isRecurrentPost: true,
            threads: this.isShareOnThreadsEnabled ? { posts: this.validTweets } : null,
          }).update();

          this.$notify({ type: 'success', message: 'Thread set as recurrent post successfully!' });
          this.resetComposer();
        } catch (error) {
          this.swalModal({
            title: 'Sorry',
            text: 'An error has occurred while setting the thread as recurrent post.',
            type: 'warning',
          });
        }
        EventBus.$emit('hide-loader');
      },
      canAddCategory() {
        return this.selectedCategories.length > 0 && !this.isATweetExceedingCharLimit;
      },
      switchToScheduleAndCloseComposer() {
        this.$refs.composerContainer.dismiss();
        if (this.$route.hash !== '#schedule') {
          this.$router.replace({ path: '/queue', hash: '#schedule' });
        }
      },
    },
    watch: {
      isMobileKeyboardVisible(val) {
        if (val) {
          document.documentElement.style.height = 'var(--available-space)';
        } else {
          document.documentElement.style.height = '100%';
        }
      },
      isShareOnLinkedInEnabled(val) {
        if (!val && this.selectedTab === 'linkedin' && this.selectedTab !== 'reel-editor') {
          this.selectedTab = 'twitter';
        }
        if (val) {
          this.$eventStore.composer('Select platforms: LinkedIn enabled');
        } else {
          this.$eventStore.composer('Select platforms: LinkedIn disabled');
        }
      },
      isShareOnInstagramEnabled(val) {
        if (!val && this.selectedTab === 'instagram' && this.selectedTab !== 'reel-editor') {
          this.selectedTab = 'twitter';
        }
        if (val) {
          this.$eventStore.composer('Select platforms: Instagram enabled');
        } else {
          this.$eventStore.composer('Select platforms: Instagram disabled');
        }
      },
      isShareOnFacebookEnabled(val) {
        if (!val && this.selectedTab === 'facebook' && this.selectedTab !== 'reel-editor') {
          this.selectedTab = 'twitter';
        }
        if (val) {
          this.$eventStore.composer('Select platforms: Facebook enabled');
        } else {
          this.$eventStore.composer('Select platforms: Facebook disabled');
        }
      },
      isShareOnTiktokEnabled(val) {
        if (!val && this.selectedTab === 'tiktok' && this.selectedTab !== 'reel-editor') {
          this.selectedTab = 'twitter';
        }
      },
      postType(type) {
        if (this.selectedTab !== 'reel-editor') {
          if (type === 'recurrent') {
            this.selectedTab = 'twitter';
          } else {
            this.selectedTab = type;
          }
        }
        if (type === 'tiktok') {
          this.showPreview();
        }
      },
      threadToWatch(thread) {
        const wasThreadScheduled = thread && thread.scheduled && thread.publishingError === null;
        if (wasThreadScheduled && this.selectedTab !== 'reel-editor') {
          this.swalModal({
            text: `This post has been scheduled for publishing.\nYou can't edit it anymore.`,
            type: 'warning',
          });
          this.resetComposer();
        }
      },
      tweets(tweets) {
        if (this.hasThreadFinisherTweet && tweets.length === 1) {
          this.hasThreadFinisherTweet = false;
        }
      },
      isWholeScheduleLoaded(isLoaded) {
        if (isLoaded && !this.timeFromParentLocal && !this.threadToEdit) {
          this.setTimeToNextTimeSlot();
        }
      },
      canShareOnInstagram(canShare) {
        if (!canShare) {
          this.isShareOnInstagramEnabled = false;
        }
      },
      canShareOnTiktok(canShare) {
        if (!canShare) {
          this.isShareOnTiktokEnabled = false;
        }
      },
      canShareOnThreads(canShare) {
        if (!canShare) {
          this.isShareOnThreadsEnabled = false;
        }
      },
      hasThreadFinisherTweet(hasFinisher) {
        if (!this.threadToEdit) {
          setUnfinishedPost(
            this.tweets,
            this.isFavorite,
            this.isShareOnInstagramEnabled,
            hasFinisher
          );
        }
      },
      tweetsCombinedStatuses(statuses, oldStatuses) {
        if (!oldStatuses && statuses && this.wasSubmitted) {
          this.wasUpdated = false;
        }
      },
      selectedTab(tab) {
        if (tab === 'twitter' || tab === 'threads') {
          this.setTweetIndexToFocus(this.tweets.length - 1);
        }
      },
      isAReply(newValue) {
        if (newValue) {
          this.selectedTab = 'reply';
        }
      },
      showQuickView: {
        handler(val) {
          if (!val.enable) {
            this.isPreviewShown = false;
            this.isDraftShown = false;
            this.isPromptsShown = false;
          }
        },
        deep: true,
      },
      isPreviewShown(val) {
        if (val) {
          this.isDraftShown = false;
          this.isPromptsShown = false;
        }
      },
      isDraftShown(val) {
        if (val) {
          this.isPreviewShown = false;
          this.isPromptsShown = false;
        }
      },
      isPromptsShown(val) {
        if (val) {
          this.isPreviewShown = false;
          this.isDraftShown = false;
        }
      },
      isTimeModalVisible(val) {
        if (val) {
          if (this.selectedTab === 'reel-editor') {
            this.$eventStore.tweetToVideo('Edit: Schedule: Choose a time');
          } else {
            this.$eventStore.composer('Schedule: Choose a time');
          }
        }
      },
      facebookConditions: {
        deep: true,
        handler(val) {
          if (!val.canShare) this.isShareOnFacebookEnabled = false;
        },
      },
    },
    beforeDestroy() {
      if (this.unsubscribeFromThreadToWatch) {
        this.unsubscribeFromThreadToWatch();
      }
    },
    props: {
      youtubeShortRef: {
        type: Object,
        default: null,
      },
      showPromptsOnLoad: {
        type: Boolean,
        default: false,
      },
      showDraftsOnLoad: {
        type: Boolean,
        default: false,
      },
      initialReelOptions: {
        type: Object,
        default: () => null,
      },
      defaultTab: {
        type: String,
        default: 'twitter',
      },
      timeFromParent: {
        type: Object,
        default: null,
      },
      threadFromParent: {
        type: Object,
        default: null,
      },
      categoriesFromParent: {
        type: Array,
        default: null,
      },
      isReplacingPost: {
        type: Boolean,
        default: false,
      },
      sourceFromParent: {
        type: Object,
        default: null,
      },
      postTypeFromParent: {
        type: String,
        default: null,
      },
      isAReplyFromParent: {
        type: Boolean,
        default: false,
      },
      isAMA: {
        type: Boolean,
        default: false,
      },
      tweetsFromParent: {
        type: Array,
        default: () => [],
      },
      hasStartedEditingFromParent: {
        type: Boolean,
        default: false,
      },
    },
    mixins: [
      QuoteTweetMixin,
      SwalModalMixin,
      UploadContainerMixin,
      LinkedInMixin,
      FacebookMixin,
      NewThreadMixin,
      BreakpointsMixin,
      MobileMixin,
      UpgradeToNextPlanMixin,
      CustomerStatusMixin,
      TweetCharactersLimitMixin,
    ],
  };
</script>

<style lang="scss" scoped>
  .preview-height {
    height: calc(100% - 50px);
    @include lg {
      height: 100%;
    }
  }
  .info_trigger {
    display: block;
  }

  .info_trigger:hover + .info_content_container {
    opacity: 100;
    transition-delay: 0.7s;
  }

  .info_content_container {
    opacity: 0;
  }

  .alert_container {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 1px 15px 0px rgba(0, 0, 0, 0.10);
  }

  .mode--dark .alert_container {
    background: linear-gradient(0deg, rgba(255, 225, 180, 0.2) 0%, rgba(255, 225, 180, 0.2) 100%),
      rgba(23, 25, 29, 0.8);
  }
</style>
