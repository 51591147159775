import { render, staticRenderFns } from "./NewComposerPreview.vue?vue&type=template&id=0b1eafab&scoped=true&"
import script from "./NewComposerPreview.vue?vue&type=script&lang=js&"
export * from "./NewComposerPreview.vue?vue&type=script&lang=js&"
import style0 from "vue-slick-carousel/dist/vue-slick-carousel.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./NewComposerPreview.vue?vue&type=style&index=1&id=0b1eafab&prod&lang=scss&"
import style2 from "./NewComposerPreview.vue?vue&type=style&index=2&id=0b1eafab&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b1eafab",
  null
  
)

export default component.exports