<script>
  import { mapGetters } from 'vuex';
  import lodash from 'lodash';

  export default {
    name: 'tiktok-mixin',
    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
        userProfile: 'getUserProfile',
        tiktokUserDetails: 'getTiktokUserDetails',
      }),
      isUserConnectedToTiktok() {
        return Boolean(
          lodash.get(this.userProfile, 'integrations.tiktok.tokenInfo.accessToken', false),
        );
      },
      tiktokUserInfo() {
        if (!this.tiktokUserDetails) {
          return {
            userName: this.userProfile.name,
            profilePicture: this.userProfile.photoURL,
            comments: false,
            duet: false,
            stitch: false,
          };
        }

        return {
          userName: this.tiktokUserDetails.creator_username,
          profilePicture: this.tiktokUserDetails.creator_avatar_url,
          comments: !this.tiktokUserDetails.comment_disabled,
          duet: !this.tiktokUserDetails.duet_disabled,
          stitch: !this.tiktokUserDetails.stitch_disabled,
        };
      },
    },
  };
</script>
