<template>
  <div class="overscroll-behavior-contain mb-13 h-full lg:mb-0">
    <div data-cy="composer-tiktok">
      <div ref="container" class="mb-5 flex flex-col space-y-2">
        <new-alert v-if="!isUserConnectedToTiktok">
          You can schedule a TikTok post but it won't be posted unless you
          <router-link class="text-main-color-100 underline" to="/settings#tiktok"
            >connect your TikTok account to Hypefury.</router-link
          >
        </new-alert>
        <div class="space-y-4">
          <div class="flex items-center gap-4">
            <img
              :src="tiktokUserInfo.profilePicture"
              :alt="tiktokUserInfo.userName"
              class="avatar-img"
            />
            <span class="avatar-name">{{ tiktokUserInfo.userName }}</span>
          </div>
          <VueSlickCarousel
            v-if="!isPostOfTypeTiktok"
            :key="isThreadFinisherEnabled ? '0' : '1'"
            ref="carousel"
            :infinite="false"
            :arrows="false"
            :dots="true"
          >
            <div ref="firstSlide">
              <div
                v-if="tweet.mediaFile.length === 0"
                class="border relative mx-auto mt-2 flex w-full items-center justify-center rounded-lg border-gray-90 px-8 py-13 lg:max-w-md"
                :style="`min-height: ${isLongTweetshot ? '30rem' : '24rem'}`"
              >
                <div
                  v-if="isLoadingTweetshot || !shouldDisplayTweetshotPreview"
                  class="dark-mode-bg absolute flex h-full w-full items-center justify-center rounded-lg bg-gray-60"
                >
                  <div
                    v-if="!shouldDisplayTweetshotPreview"
                    class="flex h-full w-full items-center justify-center"
                  >
                    <span v-if="isPostOfTypeTiktok">Select</span>
                    <span v-else>Input</span>
                    &nbsp;tweets to see a preview here
                  </div>
                  <div v-else class="loader" style="width: 24rem; height: 24rem">
                    <span class="loading loading-lg">
                      <inline-svg src="/img/icons/loading.svg" />
                    </span>
                  </div>
                </div>
                <div
                  v-else
                  class="dark-mode-bg absolute top-0 flex h-full w-full items-center justify-center rounded-lg bg-gray-60 px-8 text-center text-lg opacity-0 duration-200 ease-in hover:opacity-75"
                >
                  This is just a preview.<br />
                  If you have a thread, all of its tweets will be posted as part of the carousel.
                </div>
                <img
                  :src="tweetshotUrl"
                  class="tweet-shot-preview rounded-lg"
                  alt="tweetShot preview"
                />
              </div>
            </div>
            <div v-if="isThreadFinisherEnabled">
              <div
                class="border relative mx-auto mt-2 flex w-full flex-col items-start justify-center gap-y-4 rounded-lg border-gray-90 px-8 py-13 lg:max-w-md"
                :style="`height: ${slideHeight}px`"
              >
                <div class="flex items-center gap-4">
                  <img :src="userProfile.photoURL" :alt="userProfile.name" class="avatar-img" />
                  <span class="avatar-name">{{ userProfile.name }}</span>
                </div>
                <textarea
                  dir="auto"
                  class="form-control simple"
                  :style="`min-height: 10rem`"
                  rows="3"
                  ref="threadFinisherText"
                  v-model="threadFinisherText"
                  placeholder="Add text for your custom last carousel slide here..."
                  maxlength="280"
                  @focus="onFocus"
                  @blur="$emit('blurred')"
                ></textarea>
              </div>
            </div>
          </VueSlickCarousel>
          <div class="ml-0 sm:ml-13">
            <base-input alternative>
              <textarea
                dir="auto"
                class="form-control simple"
                style="min-height: 200px; max-height: calc(100vh - 770px); overflow-y: auto"
                ref="tiktokCaption"
                rows="3"
                v-model="tiktokCaption"
                placeholder="Caption goes here..."
                maxlength="2200"
                @focus="onFocus"
                @blur="$emit('blurred')"
                @keyup="resize"
                @keydown.ctrl.enter="$emit('pressed-ctrl-enter')"
                @keydown.meta.enter="$emit('pressed-ctrl-enter')"
                data-cy="composer-tiktok-caption-input"
              ></textarea>
              <portal-target
                name="tiktok_post_media"
                multiple
                class="thread-preview focus--hide mb-20 space-y-2 p-2"
              />
            </base-input>
            <portal-target :name="`tiktok-actions-${tweet.guid}`" v-if="isDesktop" />
          </div>
        </div>
      </div>
    </div>
    <portal :to="`tiktok-actions-${tweet.guid}`">
      <div class="flex items-center justify-end gap-6 px-2.5">
        <tooltip
          v-if="isPostOfTypeTiktok"
          data-cy="compose-upload-media"
          :content="uploadContainerTooltipContent"
        >
          <upload-container
            :tweet="tweet"
            portalTargetName="tiktok_post_media"
            :canAddMedia="canAddMedia"
            :canAddMoreVideos="canAddMoreVideos"
            postType="tiktok"
            :clearMediaSelectionAtIndexParent="clearMediaSelectionAtIndex"
            :uploadingMedia="uploadingMedia"
          />
        </tooltip>
        <tooltip
          data-cy="compose-tweetshots"
          v-if="!isPostOfTypeTiktok"
          :content="
            tweet.mediaFile.length > 0 ? 'Cannot post tweetshots with images' : 'Tweetshots'
          "
        >
          <div>
            <new-button
              type="custom"
              @click="$emit('show-tweetshot-modal')"
              :disabled="tweet.mediaFile.length > 0"
            >
              <inline-svg class="h-5 w-5 text-main-color-100" src="/img/icons/tweetshot.svg" />
            </new-button>
          </div>
        </tooltip>
        <emoji-picker @selected-emoji="addEmoji($event)" />
        <!--
          Tweet shots options for TikTok are temporarily disabled.
          This section will be re-enabled once we finalize the implementation
          strategy for converting tweets into TikTok-formatted content.
        -->
        <!-- <new-drop-down
          icon
          data-cy="tiktok-long-format-dropdown"
          buttonType="bordered"
          buttonSize="sm"
        >
          <template v-slot:button="slotProps">
            <inline-svg
              src="/img/icons/arrow-down-icon.svg"
              class="h-2 w-2 stroke-current"
              :class="['transform', slotProps.isOpen ? 'rotate-180' : 'rotate-0']"
            />
          </template>
          <template>
            <div class="flex flex-col gap-y-3">
              <div
                v-if="!isPostOfTypeTiktok"
                class="flex items-center justify-between gap-x-3 px-3 text-sm"
                :class="{
                  'text-gray-80': !isThreadFinisherEnabled,
                  'dropdown-main-text': isThreadFinisherEnabled,
                }"
              >
                <div class="flex items-center gap-x-3">
                  <inline-svg class="h-5 w-5" src="/img/icons/new/custom-slide.svg" />
                  Add custom last carousel slide
                </div>
                <base-toggle data-cy="tiktok-large-font-toggle" v-model="isThreadFinisherEnabled" />
              </div>
              <div
                class="flex items-center justify-between gap-x-3 px-3 text-sm"
                :class="{
                  'text-gray-80': !isLongTweetshot,
                  'dropdown-main-text': isLongTweetshot,
                }"
              >
                <div class="flex items-center gap-x-3">
                  <inline-svg class="h-5 w-5" src="/img/icons/long-tweetshots.svg" />
                  Use long format
                </div>
                <base-toggle data-cy="tiktok-long-format-toggle" v-model="isLongTweetshot" />
              </div>
              <div
                class="flex items-center justify-between gap-x-3 px-3 text-sm"
                :class="{
                  'text-gray-80': !isLargeFontTweetshot,
                  'dropdown-main-text': isLargeFontTweetshot,
                }"
              >
                <div class="flex items-center gap-x-3">
                  <inline-svg class="h-5 w-5" src="/img/icons/font_size.svg" />
                  Use large font
                </div>
                <base-toggle data-cy="tiktok-large-font-toggle" v-model="isLargeFontTweetshot" />
              </div>
            </div>
          </template>
        </new-drop-down> -->
      </div>
      <div class="flex h-56 w-full flex-col gap-y-6 pb-10 pt-6">
        <template v-if="tweet.mediaFile.length > 0">
          <div class="flex gap-x-6">
            <div>
              <label class="mb-1 text-base text-ds-text-secondary"> Who can view this video</label>
              <BaseSelect
                :options="privacyStatusOptions"
                v-model="tiktokPrivacyStatus"
                size="sm"
                placeholder="Select audience"
                data-cy="audience-select"
              />
            </div>
            <div>
              <label class="mb-1 text-base text-ds-text-secondary">Allow users to</label>
              <div class="mt-2 flex items-center gap-x-5">
                <new-base-checkbox
                  type="checkbox"
                  v-model="tiktokSettings.comments"
                  id="comment"
                  label="Comment"
                  data-cy="comment-checkbox"
                />
                <template v-if="canEnableDuetAndStitch">
                  <new-base-checkbox
                    type="checkbox"
                    v-model="tiktokSettings.duet"
                    :disabled="!canEnableDuetAndStitch"
                    id="duet"
                    label="Duet"
                    data-cy="duet-checkbox"
                  />
                  <new-base-checkbox
                    type="checkbox"
                    v-model="tiktokSettings.stitch"
                    :disabled="!canEnableDuetAndStitch"
                    id="stitch"
                    label="Stitch"
                    data-cy="stitch-checkbox"
                  />
                </template>
              </div>
            </div>
          </div>
          <div>
            <div class="flex items-center gap-x-3">
              <label class="text-base font-medium text-ds-text-secondary" for="discloseContent">
                Disclose Content
              </label>
              <tooltip
                :content="
                  tiktokPrivacyStatusValue === 'SELF_ONLY'
                    ? 'Setting privacy to Private disables the branded content option'
                    : ''
                "
                :disabled="tiktokPrivacyStatusValue !== 'SELF_ONLY'"
              >
                <base-toggle
                  v-model="tiktokSettings.discloseContent"
                  id="discloseContent"
                  :disabled="tiktokPrivacyStatusValue === 'SELF_ONLY'"
                  data-cy="disclose-content-switch"
                />
              </tooltip>
            </div>
            <p class="mt-1 text-sm text-ds-text-secondary">
              Turn on to disclose that this video promotes goods or services in exchange for
              something of value. Your video could promote yourself, a third party, or both
            </p>
            <template v-if="tiktokSettings.discloseContent">
              <div class="mt-2 flex items-center">
                <div>
                  <new-base-checkbox
                    :disabled="tiktokPrivacyStatusValue === 'SELF_ONLY'"
                    type="checkbox"
                    v-model="tiktokSettings.yourBrand"
                    id="yourBrand"
                    label="Your Brand"
                    data-cy="your-brand-checkbox"
                  />
                  <p class="pl-8 text-sm text-ds-text-secondary-tertiary">
                    You are promoting yourself or your own business. This photo/video will be
                    labeled as 'Promotional content'
                  </p>
                </div>
                <div>
                  <new-base-checkbox
                    :disabled="tiktokPrivacyStatusValue === 'SELF_ONLY'"
                    type="checkbox"
                    v-model="tiktokSettings.brandedContent"
                    id="brandedContent"
                    label="Branded Content"
                    data-cy="branded-content-checkbox"
                  />
                  <p class="pl-8 text-sm text-ds-text-secondary-tertiary">
                    You are promoting another brand or a third party. Your photo/video will be
                    labeled as 'Paid partnership'
                  </p>
                </div>
              </div>
            </template>
          </div>
        </template>
        <p
          class="text-sm text-ds-text-secondary-tertiary"
          :class="{ 'mt-6': tweet.mediaFile.length > 0 }"
        >
          By posting, you agree to
          <a
            v-if="tiktokSettings.brandedContent"
            href="https://www.tiktok.com/legal/page/global/bc-policy/en"
            target="_blank"
            class="text-ds-button-icon underline"
          >
            Branded Content Policy</a
          >
          <template v-if="tiktokSettings.brandedContent"> and </template>
          <a
            href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
            target="_blank"
            class="text-ds-button-icon underline"
            >Tiktok’s Music Usage Confirmation.</a
          >
        </p>
      </div>
    </portal>
  </div>
</template>

<script>
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import { mapGetters } from 'vuex';
  import UploadContainerMixin from '@/views/Mixins/UploadContainerMixin.vue';
  import EmojiPicker from '@/components/Threads/EmojiPicker.vue';
  import UploadContainer from '@/components/UploadContainer.vue';
  import BreakpointsMixin from '@/views/Mixins/BreakpointsMixin.vue';
  import controller from '@/controller';
  import VueSlickCarousel from 'vue-slick-carousel';
  import TiktokMixin from '@/views/Mixins/TiktokMixin.vue';
  import lodash from 'lodash';

  export default {
    name: 'NewComposerTiktok',
    components: {
      UploadContainer,
      EmojiPicker,
      VueSlickCarousel,
    },
    async created() {
      if (!this.originalThread) {
        this.tiktokSettings = {
          comments: this.tiktokUserInfo.comments,
          duet: this.tiktokUserInfo.duet,
          stitch: this.tiktokUserInfo.stitch,
          discloseContent: false,
          yourBrand: false,
          brandedContent: false,
        };
      }
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      uploadContainerTooltipContent() {
        if (this.tweet.mediaFile.length > 35) return 'You can only add up to 35 images or videos';

        if (this.tweet.mediaFile.length > 0 && this.tweet.mediaFile[0].type.includes('video'))
          return 'Cannot add more than 1 video';

        return 'Upload media';
      },
      canAddMoreVideos() {
        return (
          this.tweet.mediaFile.length === 0 ||
          this.tweet.mediaFile.filter((media) => media.type.includes('video')).length === 0
        );
      },
      canAddMedia() {
        return (
          this.tweet.mediaFile.length === 0 ||
          (this.tweet.mediaFile.length < 35 && !this.tweet.mediaFile[0].type.includes('video'))
        );
      },
      canEnableDuetAndStitch() {
        return this.tweet.mediaFile.every((media) => media.type.includes('video'));
      },
      shouldDisplayTweetshotPreview() {
        return (
          (!window.Cypress && !this.isPostOfTypeTiktok && this.tweet.status.trim().length > 0) ||
          this.tweetshotContent.length > 0
        );
      },
      tiktokPrivacyStatusValue() {
        return this.tiktokPrivacyStatus ? this.tiktokPrivacyStatus.value : null;
      },
    },
    mounted() {
      this.resize();
    },
    props: {
      tweet: {
        type: Object,
        default: null,
      },
      isPostOfTypeTiktok: {
        type: Boolean,
        default: false,
      },
      isVisible: {
        type: Boolean,
        default: false,
      },
      originalThread: {
        type: Object,
        default: null,
      },
      canShareOnTiktok: {
        type: Boolean,
        default: false,
      },
      tweetshotContent: {
        type: Array,
        default: () => [],
      },
      uploadingMedia: Array,
    },
    data() {
      return {
        tiktokCaption: '',
        isThreadFinisherEnabled: false,
        slideHeight: 0,
        tweetshotUrl: '/img/tweetshot_placeholder_blank.png',
        threadFinisherText: '',
        isLoadingTweetshot: false,
        currentTweetshotText: '',
        isLongTweetshot: false,
        isLargeFontTweetshot: false,
        privacyStatusOptions: [
          { label: 'Public', value: 'PUBLIC_TO_EVERYONE' },
          { label: 'Friends', value: 'MUTUAL_FOLLOW_FRIENDS' },
          { label: 'Private', value: 'SELF_ONLY' },
        ],
        tiktokPrivacyStatus: null,
        tiktokSettings: {
          comments: false,
          duet: false,
          stitch: false,
          discloseContent: false,
          yourBrand: false,
          brandedContent: false,
        },
      };
    },
    updated() {
      this.updateParentValues();
      this.resize();
    },
    methods: {
      addEmoji(emoji) {
        const captionRef = this.$refs.tiktokCaption;
        const position = captionRef.selectionStart;
        this.tiktokCaption = [
          this.tiktokCaption.slice(0, position),
          emoji.native,
          this.tiktokCaption.slice(position),
        ].join('');
        const positionAfterTweet = position + 2;

        this.$nextTick(() => {
          captionRef.focus();
          captionRef.setSelectionRange(positionAfterTweet, positionAfterTweet);
        });
      },
      clearMediaSelectionAtIndex(tweet, index) {
        this.$emit('cleared-media', tweet, index);
      },
      updateParentValues() {
        this.$emit('values-set', {
          tiktokCaption: this.tiktokCaption,
          tiktokThreadFinisherText: this.isThreadFinisherEnabled ? this.threadFinisherText : null,
          tiktokPrivacyStatus: this.tiktokPrivacyStatus,
          tiktokSettings: this.tiktokSettings,
        });
      },
      updateValuesFromThread(thread) {
        this.tiktokCaption = lodash.get(thread, 'tiktok.caption', '');
        this.isThreadFinisherEnabled = Boolean(lodash.get(thread, 'tiktok.threadFinisherText'));
        this.threadFinisherText = lodash.get(thread, 'tiktok.threadFinisherText', '');
        this.tiktokPrivacyStatus = lodash.get(thread, 'tiktok.privacyStatus', '');
        this.tiktokSettings = {
          comments: lodash.get(thread, 'tiktok.comments', false),
          duet: lodash.get(thread, 'tiktok.duet', false),
          stitch: lodash.get(thread, 'tiktok.stitch', false),
          discloseContent: lodash.get(thread, 'tiktok.discloseContent', false),
          yourBrand: lodash.get(thread, 'tiktok.yourBrand', false),
          brandedContent: lodash.get(thread, 'tiktok.brandedContent', false),
        };
      },
      resize() {
        const { tiktokCaption } = this.$refs;
        if (tiktokCaption && tiktokCaption.scrollHeight > 100) {
          tiktokCaption.style.height = tiktokCaption.scrollHeight - 4 + 'px';
        }
      },
      onFocus() {
        this.$emit('focused');
        this.resize();
      },
      resizeCarouselSlides() {
        const firstSlideRef = this.$refs.firstSlide;
        if (firstSlideRef) {
          this.slideHeight = firstSlideRef.offsetHeight;
        }
      },
      async generateTweetshotPreview(tweetStatus) {
        this.isLoadingTweetshot = true;
        try {
          this.tweetshotUrl = await controller.generateTweetshotFromText(
            this.currentUser,
            this.userProfile.uid,
            tweetStatus,
            this.isLongTweetshot,
            this.isLargeFontTweetshot,
          );
        } catch (error) {
          this.swalModal({
            title: 'Error',
            text: `An error occurred while creating the preview of your tweet.`,
            type: 'error',
          });
        }
        this.currentTweetshotText = tweetStatus;
        this.isLoadingTweetshot = false;
        setTimeout(() => {
          this.resizeCarouselSlides();
        }, 200);
      },
    },
    watch: {
      originalThread(thread) {
        if (thread) {
          this.updateValuesFromThread(thread);
        }
      },
      isThreadFinisherEnabled() {
        this.resizeCarouselSlides;
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.carousel.next();
          }, 100);
        });
      },
      isVisible(visible) {
        if (visible) {
          if (
            this.shouldDisplayTweetshotPreview &&
            this.currentTweetshotText !== this.tweet.status
          ) {
            this.generateTweetshotPreview(this.tweet.status);
          }
          this.$nextTick(() => {
            if (this.$refs.tiktokCaption && !this.isTabletOrMobile) {
              this.$refs.tiktokCaption.focus();
            }
            this.resize();
          });
        }
      },
      tweetshotContent() {
        if (
          this.tweetshotContent.length &&
          this.currentTweetshotText !== this.tweetshotContent[0].tweetText
        ) {
          this.generateTweetshotPreview(this.tweetshotContent[0].tweetText);
        }
      },
      canEnableDuetAndStitch(value) {
        if (!value) {
          this.tiktokSettings.duet = false;
          this.tiktokSettings.stitch = false;
        }
      },
      tiktokPrivacyStatus(value) {
        if (value.value === 'SELF_ONLY') {
          this.tiktokSettings.brandedContent = false;
          this.tiktokSettings.yourBrand = false;
          this.tiktokSettings.discloseContent = false;
        }
      },
    },
    mixins: [UploadContainerMixin, SwalModalMixin, BreakpointsMixin, TiktokMixin],
  };
</script>

<style src="vue-slick-carousel/dist/vue-slick-carousel.css"></style>

<style lang="scss">
  .slick-dots {
    background-color: transparent;
    list-style: none;
    display: flex !important;
    align-items: center;
    justify-content: center;

    button {
      color: transparent;
      outline: none;
    }

    button:before {
      color: var(--outline-secondary);
      background-color: transparent;
      content: '•';
      font-size: 16px;
    }

    .slick-active button:before {
      color: var(--text-secondary-tertiary);
    }
  }
</style>

<style lang="scss" scoped>
  .tweet-shot-preview {
    width: 100%;
    @include sm {
      width: 24rem;
    }
  }
</style>
