const moment = require('moment');

export default class InstagramSlot {
  slotType = 'tiktok';

  constructor(time) {
    this.time = time;
    this.midnight = moment(time).startOf('day').toISOString();
  }

  isEmpty() {
    return false;
  }
}
