<template>
  <label
    class="relative"
    :class="{
      tag: type === 'tag',
      checkbox: type === 'checkbox',
      disabled,
    }"
  >
    <input
      type="checkbox"
      :class="{
        tag: type === 'tag',
        checkbox: type === 'checkbox',
      }"
      :value="inputValue"
      :disabled="disabled"
      v-model="model"
    />
    <span>{{ label }}</span>
  </label>
</template>

<script>
  export default {
    name: 'NewBaseCheckbox',
    props: {
      label: {
        type: String,
        required: true,
      },
      value: {
        type: [Array, Boolean],
        required: true,
      },
      inputValue: {
        type: [String, Number, Boolean],
        default: true,
      },
      type: {
        type: String,
        default: 'checkbox',
        validator: (value) => ['tag', 'checkbox'].includes(value),
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      model: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .new-ui {
    input.checkbox {
      @apply m-0 grid h-5 w-5 translate-y-1 transform appearance-none place-items-center rounded-md;
    }

    label {
      &.checkbox {
        @apply flex items-center gap-x-2 text-base;
      }
      &.tag.disabled {
        @apply cursor-not-allowed;
      }
    }

    input.checkbox::before {
      @apply h-3 w-3;
      content: '';
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      transform: scale(0);
      transform-origin: center center;
      transition: 100ms transform ease-in-out;
    }

    input.checkbox:checked::before {
      transform: scale(1);
    }

    input:disabled {
      &.checkbox {
        @apply cursor-not-allowed text-gray-80 opacity-50;
      }
      &.tag {
        @apply cursor-not-allowed;
      }
    }

    input.tag {
      @apply absolute inset-0 h-px w-px opacity-0;
      + span {
        @apply inline-block cursor-pointer rounded-full px-3  py-1.5  text-xs font-semibold text-white;
      }
    }
    &.mode--dark {
      input.tag + span {
        @apply bg-dark-mode-70;
      }
      input:checked.tag + span {
        @apply bg-main-color-100;
      }
      input.checkbox {
        @apply bg-dark-mode-50;
      }
      input.checkbox:checked {
        @apply bg-white;
      }
      input.checkbox::before {
        box-shadow: inset 1rem 1rem black;
      }
      label.checkbox {
        @apply text-white;
      }
    }
    &.mode--light {
      input.tag + span {
        @apply bg-gray-100;
      }
      input:checked.tag + span {
        @apply bg-main-color-100;
      }
      input.checkbox {
        @apply border border-gray-100;
      }
      input.checkbox:checked {
        @apply bg-black;
      }
      input.checkbox::before {
        box-shadow: inset 1rem 1rem white;
      }
      label.checkbox {
        @apply text-black;
      }
    }
  }
</style>
