<template>
  <div id="hypefury-header" class="h-24 w-full px-6 py-4 lg:w-241 lg:py-6">
    <div class="relative flex items-center justify-between">
      <button class="z-20 lg:hidden" @click="minimizeSidebar">
        <inline-svg src="/img/icons/menu.svg" />
      </button>
      <div class="absolute inset-0 flex items-center justify-center lg:relative">
        <router-link to="/" class="app-logo flex items-center justify-center">
          <app-logo />
          <dark-logo class="hidden" />
        </router-link>
      </div>
      <div class="flex items-center space-x-3 h-full">
        <div class="flex flex-col items-end">
          <new-drop-down
            :padded="false"
            ref="dropdown"
            buttonType="custom"
            buttonClasses="px-0"
            fixed
          >
            <template v-slot:button="slotProps">
              <div class="flex avatar items-center">
                <span class="sr-only">avatar</span>
                <img class="w-6 h-6 rounded-full overflow-hidden" alt="Profile picture" :src="user.photoURL">
                <arrow-down-icon
                  class="ml-2 transform"
                  :class="slotProps.isOpen ? 'rotate-180' : 'rotate-0'"
                />
              </div>
            </template>
            <div
              class="flex w-full flex-col items-start justify-center py-4 gap-y-1 md:max-w-xxs"
              style="min-width: 200px"
            >
              <new-button type="dropdown" :highlighted="true">
                <div class="flex min-w-0 items-center justify-between space-x-2">
                  <div class="flex min-w-0 items-center space-x-2">
                    <img :src="userProfile.photoURL" class="avatar-img-xs" alt="profile picture" />
                    <span class="truncate overflow-ellipsis flex-grow text-secondary">{{
                      userProfile.name
                    }}</span>
                  </div>
                  <inline-svg v-if="otherConnectedUserProfiles.length > 0" src="/img/icons/tick.svg" />
                </div>
              </new-button>
              <new-button type="dropdown"
                class="dropdown-item"
                v-bind:key="profile.uid"
                @click="switchToProfile(profile.uid)"
                v-for="profile in otherConnectedUserProfiles"
              >
                <img width="24" height="24" :src="profile.photoURL" class="w-4 h-4 rounded-full overflow-hidden flex-shrink-0" />
                <span class="truncate overflow-ellipsis text-secondary">{{profile.name}}</span>
              </new-button>
              <new-button type="dropdown"
                v-if="shouldShowAddAccountButton"
                @click="connectAdditionalAccountIfAllowed"
              >
                <div class="flex space-x-2">
                  <inline-svg
                    v-if="canUserConnectAdditionalAccounts"
                    src="/img/icons/new/plus.svg"
                    class="stroke-ds-button-icon"
                  />
                  <inline-svg v-else src="/img/icons/premium.svg" />
                  <span>Add account</span>
                </div>
              </new-button>
              <hr class="w-full border-divider" />
              <new-button type="dropdown" @click="goToPage('/settings')">
                <div class="flex space-x-2">
                  <settings-icon class="h-5 w-5 stroke-ds-button-icon" />
                  <span class="text-base">Settings</span>
                </div>
              </new-button>
              <!-- <new-button type="dropdown" v-if="!isUserManaging" @click="goToPage('/billing')">
                <div class="flex space-x-2">
                  <inline-svg src="/img/icons/new/bill.svg" class="stroke-ds-button-icon" />
                  <span class="text-base">Billing</span>
                </div>
              </new-button> -->
              <new-button type="dropdown" v-if="!isUserManaging" @click="goToPage('/billing')">
                <div class="flex space-x-2">
                  <inline-svg src="/img/icons/new/bill.svg" class="stroke-ds-button-icon" />
                  <span class="text-base">Subscription</span>
                </div>
              </new-button>
              <new-button type="dropdown" @click="() => handleDropdownButonClick('Help')">
                <a href="https://hypefury.crisp.help/en/" target="_blank" class="w-full">
                  <div class="flex space-x-2">
                    <inline-svg
                      src="/img/icons/new/help.svg"
                      class="h-5 w-5 stroke-ds-button-icon"
                    />
                    <span class="text-base">Help</span>
                  </div>
                </a>
              </new-button>
              <new-button
                type="dropdown"
                class="beamer-button flex"
                :disabled="!isBeamerReady"
                @click="() => handleDropdownButonClick('What\'s new')"
              >
                <div class="flex space-x-2">
                  <inline-svg src="/img/icons/new/bell.svg" class="stroke-ds-button-icon" />
                  <span class="text-base">What's new</span>
                </div>
              </new-button>
              <new-button type="dropdown" @click="() => handleDropdownButonClick('Logout')">
                <inline-svg src="/img/icons/new/log-out.svg" class="stroke-ds-button-icon" />
                <span class="text-base">Logout</span>
              </new-button>
            </div>
          </new-drop-down>
          <div
            class="hidden items-center justify-between lg:flex mt-auto"
            v-show="isUserPlanBasicOrAbove(userProfile.customerStatus)"
          >
            <arrow-icon
              v-if="this.userProfile.followersDiffSinceLastWeek > 0"
              height="12"
              width="12"
              color="#29af87"
            />
            <arrow-icon
              color="red"
              height="12"
              width="12"
              v-else-if="this.userProfile.followersDiffSinceLastWeek < 0"
              class="transform rotate-180"
            />
            <span class="text-xs text-secondary">{{ userFollowers }} followers</span>
          </div>
        </div>
      </div>
    </div>

    <upgrade-to-premium-pop-up
      :show="showUpgradeToPremiumPopUp"
      :content="`Upgrade to the ${getPlanLabel(
        'premium'
      )} plan to add more accounts and unlock ton of other powerful features!`"
      :contentForNonSubscribers="`Subscribe to the ${getPlanLabel(
        'premium'
      )} plan to add more additional accounts
        and unlock a ton of other powerful features!`"
      @continue-operation="continuePremiumFeature"
      @close="showUpgradeToPremiumPopUp = false"
    />

    <upgrade-to-next-plan-pop-up
      :show="showNextPlanPopUp"
      :content="nextPlanPopUpContent"
      @close="showNextPlanPopUp = false"
      :nextPlan="nextPlan"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapState } from 'vuex';
import 'firebase/compat/auth';
import Vue from 'vue';
import VueAuthenticate from 'vue-authenticate';
import VueAxios from 'vue-axios';
import lodash from 'lodash';
import LogoutMixin from '../Mixins/LogoutMixin.vue';
import DarkLogo from '../../svgs/darkLogo';
import AppLogo from '../../svgs/logo';
import SettingsIcon from '../../components/Icons/SettingsIcon.vue';
import BillingIcon from '../../components/Icons/BillingIcon.vue';
import LogoutIcon from '../../components/Icons/LogoutIcon.vue';
import NotificationIcon from '../../components/Icons/NotificationIcon.vue';
import ArrowIcon from '../../components/Icons/ArrowIcon.vue';
import ArrowDownIcon from '../../components/Icons/ArrowDownIcon.vue';
import { switchToProfile } from '@/util/switchToProfile';
import { twitterLoginRedirectURI, httpRoutesURL } from '@/config';
import SwalModalMixin from '../Mixins/SwalModalMixin.vue';
import controller from '@/controller';
import dao from '@/dao';
import CustomerStatusMixin from '../Mixins/CustomerStatusMixin.vue';
import UpgradeToNextPlanMixin from '../Mixins/UpgradeToNextPlanMixin.vue';
import UpgradeToNextPlanPopUp from '@/components/UpgradeToNextPlanPopUp.vue';

export default {
  name: 'hypefury-header',
  data() {
    return {
      showUpgradeToPremiumPopUp: false,
    };
  },
  props: {
    logo: {
      type: String,
      default: '/logo.svg',
      description: 'HypeFury Logo'
    },
    userFollowers: {
      type: String
    },
  },
  computed: {
    showToggle() {
      return ! this.$sidebar.isMinimized
    },
    ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile', parentUserProfile: 'getParentUserProfile' }),
    ...mapState(['firestoreListeners', 'isUserManaging', 'isBeamerReady', 'userProfiles']),
    user() {
      const {
        photoURL: normalPhotoUrl,
        name,
        subscriptionTierName
      } = this.userProfile;
      const photoURL = normalPhotoUrl.replace('_normal', '');

      return {
        photoURL,
        name,
        currentPlan: subscriptionTierName
      }
    },
    canUserConnectAdditionalAccounts() {
      if (!this.parentUserProfile) return false;
      const customerStatus = this.parentUserProfile.customerStatus;
      const limitsToUse = this.didUserSubscribeBeforePlansChanges(this.parentUserProfile)
        ? 'old'
        : 'new';
      return (
        this.secondaryAccountsCount <
        this.PLANS[customerStatus].limits.additionalAccounts[limitsToUse]
      );
    },
    otherConnectedUserProfiles() {
      return Object.values(this.userProfiles).filter((profile) => {
        return profile.uid !== this.userProfile.uid;
      });
    },
    secondaryAccountsCount() {
      const managedAccountsCount = lodash.get(this, 'parentUserProfile.additionalAccounts', [])
        .filter((account) => account.type === 'managed').length;
      return Object.keys(this.userProfiles).length - (managedAccountsCount + 1);
    },
    shouldShowAddAccountButton() {
      return this.secondaryAccountsCount < 14;
    },
  },
  methods: {
    goToPage(pageRoute){
      this.$eventStore.general.sidebar(`Go to ${pageRoute}`);
      this.$refs.dropdown.close();
      this.$router.push(pageRoute);
    },
    switchToProfile(userId) {
      this.$eventStore.general.sidebar(`Switch to profile`);
      switchToProfile(userId);
    },
    handleDropdownButonClick(buttonName) {
      this.$eventStore.general.sidebar(buttonName);
      if (buttonName === 'Logout') {
        this.logout();
      } else {
        this.$refs.dropdown.close();
      }
    },
    toggleUserSettings() {
      // pass
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    connectAdditionalAccountIfAllowed() {
      this.$eventStore.general.sidebar('Add account');
      const customerStatus = this.parentUserProfile.customerStatus;
      const limitsToUse = this.didUserSubscribeBeforePlansChanges(this.parentUserProfile)
        ? 'old'
        : 'new';

      const additionalAccountsLimit =
        this.PLANS[customerStatus].limits.additionalAccounts[limitsToUse];

      if (this.secondaryAccountsCount >= additionalAccountsLimit) {
        this.openAdditionalAccountsLimitNextPlanPopUp(additionalAccountsLimit, limitsToUse);
      } else {
          this.swalModal({
            title: `Connect additional account`,
            text: "Make sure you're logged in to the account you want to connect to Hypefury on 𝕏",
            confirmButtonText: 'Connect',
            preConfirm: () => {
              this.connectAdditionalAccount();
            },
          });
      }
    },
    async connectAdditionalAccount() {
      if (!this.hasTwitterAuthBeenInitialized) {
        Vue.use(VueAuthenticate, {
          bindRequestInterceptor: function () {
            this.$http.interceptors.request.use((config) => {
              if (this.isAuthenticated()) {
                config.headers['Authorization'] = [
                  this.options.tokenType, this.getToken()
                ].join(' ');
              } else {
                // Do nothing (default behavior of vue-authentication: remove the Authorization header)
              }
              return config;
            });
          },
          providers: {
            twitter: {
              name: 'twitter',
              url: `${httpRoutesURL}/integrations/auth/twitter`,
              authorizationEndpoint: 'https://api.twitter.com/oauth/authenticate',
              redirectUri: `${twitterLoginRedirectURI}/`,
              oauthType: '1.0',
            },
          },
        });
        Vue.use(VueAxios, axios);

        this.hasTwitterAuthBeenInitialized = true;
      }

      try {
        const result = await this.$auth.authenticate('twitter');
        const data = result.data;
        const newUserUsername = data.profile.screen_name;
        const newUserTwitterId = data.profile.id_str;

        if (
          lodash.size(lodash.get(this, 'parentUserProfile.parentsCustomerStatus', {})) > 0 &&
          this.isUserNotSubscribed(this.parentUserProfile.customerStatus)
        ) {
           this.swalModal({
            title: 'Error',
            text: `@${this.parentUserProfile.username} is a secondary account, you cannot connect more accounts to it.`,
            type: 'error',
          });
          return;
        }

        if (this.userProfiles[this.currentUser.uid].twitterId === newUserTwitterId ||
          this.userProfile.twitterId === newUserTwitterId) {
          this.swalModal({
            title: 'Please change accounts',
            text: `You are trying to add the currently connected account (@${newUserUsername}).
            Please switch accounts from twitter.com and try again.`,
            type: 'error',
          });
          return;
        }

        if (this.otherConnectedUserProfiles.map(p => p.twitterId).includes(newUserTwitterId)) {
          this.swalModal({
            title: 'Woops',
            text: `@${newUserUsername} is already connected!`,
            type: 'warning',
          });
          return;
        }

        // vueauth_token token is set by vue-authenticate to remember the currently logged in user
        // We don't need this behavior + it breaks axios by replacing all the Authentication header
        // value with the value from this vueauth_token.
        localStorage.removeItem('vue-authenticate.vueauth_token');

        try {
          const hypefuryAccount = await controller.doesAccountExistInHypefury(this.currentUser,
            data.access_token,
            data.access_token_secret);
          const isTrialAllowed = false;

          if (
            hypefuryAccount.isUserParentAccount === true &&
            ['trial', 'none'].includes(hypefuryAccount.customerStatus)
          ) {
            this.swalModal({
              title: 'Error',
              text: `@${newUserUsername} is a parent account, you cannot connect it to another parent account.`,
              type: 'error',
            });
            return;
          }

          if (
            lodash.get(this.userProfile, 'parentsCustomerStatus', {})[hypefuryAccount.id]
          ) {
            this.swalModal({
              title: 'Error',
              text: `@${newUserUsername} is this account's parent, you cannot add it as a child account.`,
              type: 'error',
            });
            return;
          }

          await dao.userProfile.addAdditionalTwitterAccount(
            this.currentUser.uid,
            data.access_token,
            data.access_token_secret,
            lodash.omit(data.profile, ['entities', 'status.entities', 'status.place']),
          );

          this.swalModal({
            title: 'Account successfully connected',
            text: `You will be switched to @${newUserUsername} now.`,
            type: 'success',
          }).then(() => {
            switchToProfile(hypefuryAccount.id);
          });
        } catch (error) {
          console.log(error)
          const isAccountNotConnectedToHypefury = error.response.status === 404;
          if (isAccountNotConnectedToHypefury) {
            this.swalModal({
              title: 'Sorry',
              text: `@${newUserUsername} is not a Hypefury user yet. Please log into Hypefury with the @${newUserUsername} account and try again.`,
              type: 'warning',
            });
          } else {
            throw error;
          }
        }
      } catch (error) {
        console.log(error)
        this.swalModal({
          title: 'Sorry',
          text: `An error has occurred while connecting your additional Twitter account.`,
          type: 'warning',
        });
      }
    },
    continuePremiumFeature() {
      this.connectAdditionalAccount();
    },
  },
  mixins: [LogoutMixin, SwalModalMixin, UpgradeToNextPlanMixin, CustomerStatusMixin],
  components: {
    DarkLogo,
    AppLogo,
    SettingsIcon,
    BillingIcon,
    LogoutIcon,
    NotificationIcon,
    ArrowIcon,
    ArrowDownIcon,
    UpgradeToNextPlanPopUp,
  }
}
</script>

<style>
  #hypefury-header .beamer_icon.active {
    font-size: 0;
    width: 6px;
    height: 6px;
    box-shadow: 0 0 0 2px var(--sidebar);
  }
</style>
