<template>
  <div>
    <div class="flex w-full items-center justify-between gap-x-3 lg:hidden">
      <h1>Preview</h1>
      <new-button icon @click="$emit('close')" type="transparent">
        <inline-svg
          src="/img/icons/new/modal-collapse.svg"
          class="hidden h-6 text-main-color-100 lg:inline"
        />
        <inline-svg src="/img/icons/new/x.svg" class="inline text-gray-80 lg:hidden" />
      </new-button>
    </div>
    <div class="mt-4 flex items-center gap-x-4 lg:mt-0" data-cy="tweet-preview">
      <new-tabs
        class="w-full"
        :key="defaultTab"
        :defaultTabHash="defaultTab"
        :useLocationHash="false"
        :hideTabsLabel="hideTabsLabel"
      >
        <new-tab v-if="isAReplyFromParent" label="Reply" id="reply-preview">
          <reply-preview
            :hasThreadFinisherTweet="hasThreadFinisherTweet"
            :tweets="tweets"
            :userProfile="userProfile"
            @updateParentOriginalTweetInfo="updateParentOriginalTweetInfo"
          />
        </new-tab>
        <new-tab
          v-else
          label="Twitter"
          id="twitter-preview"
          :isHidden="['recurrent', 'twitter'].includes(postType)"
        >
          <tweet-thread-preview
            :hasThreadFinisherTweet="hasThreadFinisherTweet"
            :tweets="tweets"
            :userProfile="userProfile"
          />
        </new-tab>
        <new-tab label="Threads" id="threads-preview" :isHidden="postType === 'threads'">
          <tweet-thread-preview
            :hasThreadFinisherTweet="hasThreadFinisherTweet"
            :tweets="tweets"
            :userProfile="userProfile"
          />
        </new-tab>
        <new-tab label="LinkedIn" id="linkedin-preview" :isHidden="isLinkedinTabHidden">
          <linked-in-preview
            :text="linkedInText"
            :postType="linkedInPostType"
            :media="allTweetsMedia"
            :profilePictureURL="userLinkedInData.profilePictureURL"
            :userDisplayName="userLinkedInData.name"
            :userHeadline="userLinkedInData.headline"
          />
        </new-tab>
        <new-tab label="Facebook" id="facebook-preview" :isHidden="isFacebookTabHidden">
          <facebook-preview
            :text="facebookText"
            :media="allTweetsMedia"
            :profilePictureURL="userProfile.photoURL"
            :userDisplayName="userProfile.name"
          />
        </new-tab>
        <new-tab label="TikTok" id="tiktok-preview" :isHidden="isTiktokTabHidden">
          <div v-if="!allTweetsMedia.length" class="text-center text-gray-80">
            No media available for preview
          </div>
          <VueSlickCarousel
            v-else-if="hasImageAttachments"
            class="z-40 w-full"
            :infinite="false"
            :arrows="false"
            :dots="true"
            :slidesToShow="1.2"
            :centerMode="true"
            centerPadding="20px"
          >
            <div v-for="media in allTweetsMedia" :key="media.guid" class="flex items-center gap-4">
              <img :src="media.url" class="slide-image" alt="user images" />
            </div>
          </VueSlickCarousel>
          <VideoPlayer v-else :options="videoOptions" />
        </new-tab>
        <!-- Disable Instagram Tab form preview -->
        <!-- <new-tab label="Instagram" id="instagram-preview" v-if="isShareOnInstagramEnabled">
          <div class="mb-5 flex items-center">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.99935 13.3327C11.8403 13.3327 13.3327 11.8403 13.3327 9.99935C13.3327 8.1584 11.8403 6.66602 9.99935 6.66602C8.1584 6.66602 6.66602 8.1584 6.66602 9.99935C6.66602 11.8403 8.1584 13.3327 9.99935 13.3327Z"
                fill="white"
              />
              <path
                d="M14.1658 0H5.8325C2.61667 0 0 2.6175 0 5.83417V14.1675C0 17.3833 2.6175 20 5.83417 20H14.1675C17.3833 20 20 17.3825 20 14.1658V5.8325C20 2.61667 17.3825 0 14.1658 0ZM10 15C7.2425 15 5 12.7575 5 10C5 7.2425 7.2425 5 10 5C12.7575 5 15 7.2425 15 10C15 12.7575 12.7575 15 10 15ZM15.8333 5C15.3733 5 15 4.62667 15 4.16667C15 3.70667 15.3733 3.33333 15.8333 3.33333C16.2933 3.33333 16.6667 3.70667 16.6667 4.16667C16.6667 4.62667 16.2933 5 15.8333 5Z"
                fill="white"
              />
            </svg>
          </div>
          <div class="rounded-2xl bg-white">
            <div class="flex items-center gap-4 p-6">
              <img :src="userProfile.photoURL" :alt="userProfile.name" class="avatar-img" />
              <span class="avatar-name">{{ userProfile.name }}</span>
            </div>
            <div
              class="word-break whitespace-pre-wrap break-words px-6 text-lg text-black"
              v-html="instagramCaption"
              dir="auto"
            ></div>
            <div
              v-for="tweet in tweets"
              :key="tweet.guid"
              class="grid gap-6 rounded-bl-2xl rounded-br-2xl bg-gray-10 p-6"
            >
              <div class="grid gap-5 rounded-2xl bg-white p-6 shadow-sm">
                <div class="flex items-start gap-4">
                  <img
                    :src="userProfile.photoURL"
                    :alt="userProfile.name"
                    class="h-9 w-9 rounded-full"
                  />
                  <div class="flex-1">
                    <span class="text-base font-bold text-black">{{ userProfile.name }}</span>
                    <span class="flex items-center gap-2 text-base text-gray-80"
                      >@{{ userProfile.username }}</span
                    >
                  </div>
                </div>
                <div
                  class="whitespace-pre-wrap break-words text-lg text-black"
                  v-html="formatTweet(tweet.status)"
                  dir="auto"
                />
                <p class="flex items-center gap-2 text-lg leading-none text-gray-80">
                  29 Aug 2022 <small class="block h-1 w-1 rounded-full bg-gray-80" /> 7:24 AM
                </p>
              </div>
            </div>
          </div>
        </new-tab> -->
      </new-tabs>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { formatTweet } from '@/util/formatTweet';
  import LinkedInMixin from '@/views/Mixins/LinkedInMixin.vue';
  import NewTab from '@/components/Tabs/NewTab.vue';
  import NewTabs from '@/components/Tabs/NewTabs.vue';
  import FacebookPreview from './Previews/FacebookPreview.vue';
  import LinkedInPreview from './Previews/LinkedInPreview.vue';
  import TweetThreadPreview from './Previews/TweetThreadPreview.vue';
  import ReplyPreview from './Previews/ReplyPreview.vue';
  import VideoPlayer from '@/components/VideoPlayer.vue';
  import VueSlickCarousel from 'vue-slick-carousel';

  export default {
    name: 'NewComposerPreview',
    components: {
      NewTab,
      NewTabs,
      FacebookPreview,
      LinkedInPreview,
      TweetThreadPreview,
      ReplyPreview,
      VideoPlayer,
      VueSlickCarousel,
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      allTweetsMedia() {
        return this.tweets.reduce((acc, tweet) => {
          if (tweet.mediaFile) {
            acc.push(...tweet.mediaFile);
          }
          return acc;
        }, []);
      },
      isLinkedinTabHidden() {
        return (
          (['recurrent', 'twitter', 'threads'].includes(this.postType) &&
            this.isShareOnLinkedInEnabled) ||
          this.postType === 'linkedin'
        );
      },
      isFacebookTabHidden() {
        return (
          (['recurrent', 'twitter', 'threads'].includes(this.postType) &&
            this.isShareOnFacebookEnabled) ||
          this.postType === 'facebook'
        );
      },
      isTiktokTabHidden() {
        return (
          (['recurrent', 'twitter', 'threads'].includes(this.postType) &&
            this.isShareOnTiktokEnabled) ||
          this.postType === 'tiktok'
        );
      },
      defaultTab() {
        let tabHash = 'twitter-preview';

        if (this.postType === 'facebook') {
          tabHash = 'facebook-preview';
        }
        if (this.postType === 'linkedin') {
          tabHash = 'linkedin-preview';
        }

        if (this.postType === 'threads') {
          tabHash = 'threads-preview';
        }

        if (this.postType === 'tiktok') {
          tabHash = 'tiktok-preview';
        }

        return tabHash;
      },
      videoOptions() {
        if (!this.tweets[0].mediaFile) {
          return {};
        }

        return {
          autoplay: false,
          controls: true,
          sources: [
            {
              src: this.tweets[0].mediaFile[0].url,
              type: 'video/mp4',
            },
          ],
        };
      },
      hasImageAttachments() {
        if (this.tweets[0].mediaFile) {
          return this.tweets[0].mediaFile.some((mediaFile) => mediaFile.type.includes('image'));
        }
        return false;
      },
    },
    methods: {
      formatTweet,
      updateParentOriginalTweetInfo(value) {
        this.$emit('updateParentOriginalTweetInfo', { originalTweetInfo: value });
      },
    },
    props: {
      tweets: {
        type: Array,
        required: true,
      },
      hasThreadFinisherTweet: {
        type: Boolean,
        required: true,
      },
      linkedInText: {
        type: String,
        required: true,
      },
      linkedInPostType: {
        type: String,
        required: true,
      },
      facebookText: {
        type: String,
        required: true,
      },
      instagramCaption: {
        type: String,
        required: true,
      },
      isShareOnInstagramEnabled: {
        type: Boolean,
        required: true,
      },
      isShareOnLinkedInEnabled: {
        type: Boolean,
        required: true,
      },
      isShareOnFacebookEnabled: {
        type: Boolean,
        required: true,
      },
      isAReplyFromParent: {
        type: Boolean,
        required: false,
      },
      postType: {
        type: String,
        required: true,
      },
      hideTabsLabel: {
        type: Boolean,
        default: false,
      },
    },
    mixins: [LinkedInMixin],
  };
</script>

<style src="vue-slick-carousel/dist/vue-slick-carousel.css"></style>

<style lang="scss">
  .slick-dots {
    background-color: transparent;
    list-style: none;
    display: flex !important;
    align-items: center;
    justify-content: center;

    button {
      color: transparent;
      outline: none;
    }

    button:before {
      color: var(--outline-secondary);
      background-color: transparent;
      content: '•';
      font-size: 16px;
    }

    .slick-active button:before {
      color: var(--text-secondary-tertiary);
    }
  }
</style>

<style lang="scss" scoped>
  .slide-image {
    margin: 0 auto;
    width: 360px;
    height: 640px;
    object-fit: cover;
    border-radius: 0.5rem;
  }
</style>
