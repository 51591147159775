import moment from 'moment';
import 'moment-timezone';
import lodash from 'lodash';
import { formatHourOrMinute } from '@/util/formatHourOrMinute';
import { formatTimeWithUserLocale } from '@/util/formatTimeWithUserLocale';

class ScheduleForSettings {
  #rawTimeSlotsForEachDay;
  #timeSlotsForEachDay;

  constructor(timeSlotsForEachDay) {
    this.#rawTimeSlotsForEachDay = timeSlotsForEachDay;
    this.#timeSlotsForEachDay = timeSlotsForEachDay.map((s) => {
      if (s === '') return [];
      return s.split(',').map((s) => {
        const split = s.split(':');
        const [hour, minute, type] = split;
        return {
          hour: parseInt(hour),
          minute: parseInt(minute),
          type,
        };
      });
    });
  }

  getScheduleForView() {
    const result = {};
    for (let i = 1; i <= 7; i++) {
      const dayNumber = i % 7;
      const timeSlots = this.#timeSlotsForEachDay[dayNumber];
      const day = moment().day(dayNumber).format('dddd');
      result[day] = [];
      timeSlots.forEach((timeSlot) => {
        timeSlot.day = dayNumber;
        timeSlot.formatted = formatTimeSlot(timeSlot);
        timeSlot.id = `${day}-${formatHourOrMinute(timeSlot.hour)}-${formatHourOrMinute(
          timeSlot.minute
        )}`;
        result[day].push(timeSlot);
      });
      result[day] = lodash.sortBy(result[day], ['hour', 'minute']);
    }
    return result;
  }

  getScheduleForDB() {
    return this.#timeSlotsForEachDay.map((timeSlots) => {
      return timeSlots
        .map((timeSlot) => {
          let formattedSlot =
            formatHourOrMinute(timeSlot.hour) + ':' + formatHourOrMinute(timeSlot.minute);

          if (timeSlot.type) {
            formattedSlot += `:${timeSlot.type}`;
          }
          return formattedSlot;
        })
        .join(',');
    });
  }

  removeSlotAndReturnScheduleForDB(timeSlot) {
    const schedule = this.#timeSlotsForEachDay.map((timeSlots) => {
      return timeSlots
        .filter((slot) => {
          return (
            slot.hour !== timeSlot.hour ||
            slot.minute !== timeSlot.minute ||
            slot.day !== timeSlot.day
          );
        })
        .map((timeSlot) => {
          let formattedSlot =
            formatHourOrMinute(timeSlot.hour) + ':' + formatHourOrMinute(timeSlot.minute);

          if (timeSlot.type) {
            formattedSlot += `:${timeSlot.type}`;
          }

          return formattedSlot;
        })
        .join(',');
    });
    return { schedule, hasEvergreenSlots: this.#hasEvergreenSlots(schedule) };
  }

  getScheduleForDBWithNewSlot(timeSlot) {
    const prevSchedule = JSON.parse(JSON.stringify(this.#timeSlotsForEachDay));
    this.add(timeSlot);
    const newScheduleForDB = this.getScheduleForDB();
    this.#timeSlotsForEachDay = prevSchedule;
    return {
      schedule: newScheduleForDB,
      hasEvergreenSlots: this.#hasEvergreenSlots(newScheduleForDB),
    };
  }

  #hasEvergreenSlots = (schedule) => {
    return schedule.some((day) => day.includes(':evergreen'));
  };

  add(timeSlot) {
    if (timeSlot.day === 'Every day') {
      for (let i = 0; i < 7; i++) {
        this.#addTimeInSlot(timeSlot.hour, timeSlot.minute, i, timeSlot.type);
      }
    } else if (timeSlot.day === 'Weekdays') {
      for (let i = 1; i < 6; i++) {
        this.#addTimeInSlot(timeSlot.hour, timeSlot.minute, i, timeSlot.type);
      }
    } else if (timeSlot.day === 'Weekends') {
      this.#addTimeInSlot(timeSlot.hour, timeSlot.minute, 0, timeSlot.type);
      this.#addTimeInSlot(timeSlot.hour, timeSlot.minute, 6, timeSlot.type);
    } else {
      this.#addTimeInSlot(timeSlot.hour, timeSlot.minute, timeSlot.day, timeSlot.type);
    }
  }

  remove(timeSlot) {
    if (this.#timeSlotsForEachDay[timeSlot.day].length === 1) {
      throw new Error('Cannot remove the last slot of the schedule.');
    }
    this.#timeSlotsForEachDay[timeSlot.day] = this.#timeSlotsForEachDay[timeSlot.day].filter(
      (slot) => {
        return slot.hour !== timeSlot.hour || slot.minute !== timeSlot.minute;
      }
    );
  }

  getNumberOfPostsPerWeek() {
    return lodash.flatten(this.#timeSlotsForEachDay).length;
  }

  naturalize() {
    function f(existingTimes, newTimes) {
      if (existingTimes.length === 0) {
        return newTimes;
      }

      const firstTime = existingTimes.shift();
      const time = firstTime.time.clone();
      const minuteDiff = Math.floor(Math.random() * 5) + 1;
      let beforeOrAfter = Math.floor(Math.random() * 2) === 1 ? 1 : -1;
      time.add({ minutes: minuteDiff * beforeOrAfter });

      const areDaysDifferent = firstTime.time.day() !== time.day();
      const areTimesTheSame =
        firstTime.time.hour() === time.hour() && firstTime.time.minute() === time.minute();
      const hourIsRound = time.minute() === 0;
      if (areDaysDifferent || areTimesTheSame || hourIsRound) {
        existingTimes.unshift(firstTime);
        return f(existingTimes, newTimes);
      }

      const maybeSameTime = lodash.find(
        existingTimes,
        (t) => t.time.hour() === time.hour() && t.time.minute() === time.minute()
      );
      if (maybeSameTime) {
        existingTimes.push(firstTime);
        return f(existingTimes, newTimes);
      }

      newTimes.push({ time: time, type: firstTime.type });
      return f(existingTimes, newTimes);
    }

    function createNewTimes(times) {
      const newTimes = f(lodash.clone(times), []);
      const timeSlots = newTimes.map((t) => {
        let time = t.time.format('HH:mm');

        if (t.type) {
          time += `:${t.type}`;
        }

        return time;
      });
      const uniqueTimes = new Set(
        timeSlots.map((timeslot) => {
          const split = timeslot.split(':');
          const hour = split[0];
          const minute = split[1];

          return `${hour}:${minute}`;
        })
      );

      if (timeSlots.length !== uniqueTimes.size) {
        return createNewTimes(lodash.clone(times));
      }

      return timeSlots.join(',');
    }

    const oldTimeSlots = this.#rawTimeSlotsForEachDay;
    const newTimeSlots = oldTimeSlots.map((timesOfDay) => {
      const getSlotType = (t) => {
        if (t.includes(':evergreen')) {
          return 'evergreen';
        } else if (t.includes(':instagram')) {
          return 'instagram';
        } else if (t.includes(':linkedin')) {
          return 'linkedin';
        } else if (t.includes(':facebook')) {
          return 'facebook';
        } else if (t.includes(':tiktok')) {
          return 'tiktok';
        } else if (t.includes(':threads')) {
          return 'threads';
        } else {
          return null;
        }
      };

      const times = timesOfDay
        .split(',')
        .filter((t) => t.length > 0)
        .map((t) => ({ time: moment.utc(t, 'HH:mm'), type: getSlotType(t) }));
      return createNewTimes(times);
    });
    return new ScheduleForSettings(newTimeSlots);
  }

  #addTimeInSlot = (hour, minute, slot, type) => {
    const maybeSameSlot = this.#timeSlotsForEachDay[slot].filter((timeSlot) => {
      return timeSlot.hour === hour && timeSlot.minute === minute;
    });
    if (maybeSameSlot.length > 0) return;
    const newSlot = { hour: parseInt(hour), minute: parseInt(minute) };

    if (type) {
      newSlot['type'] = type;
    }

    this.#timeSlotsForEachDay[slot].push(newSlot);
  };
}

function formatTimeSlot(timeSlot) {
  const now = moment({ hour: timeSlot.hour, minute: timeSlot.minute });
  return formatTimeWithUserLocale(now);
}

export { ScheduleForSettings };
