<script>
  import { mapGetters } from 'vuex';
  import {
    isUserPlanBasicOrAbove,
    isUserPlanPremiumOrAbove,
    isUserSubscribed,
    isUserNotSubscribed,
    isUserPlanStandardOrBelow,
    PLANS,
    getPlansAboveCurrentPlan,
    didUserSubscribeBeforePlansChanges,
    getPlanLabel,
  } from '@/../functions/src/util/customerStatus.cjs';

  export default {
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      userPlan() {
        return PLANS[this.userProfile.customerStatus].label;
      },
    },
    methods: {
      isUserPlanBasicOrAbove,
      isUserPlanPremiumOrAbove,
      isUserSubscribed,
      isUserNotSubscribed,
      isUserPlanStandardOrBelow,
      getPlansAboveCurrentPlan,
      didUserSubscribeBeforePlansChanges,
      getPlanLabel,
    },
    data() {
      return {
        PLANS,
      };
    },
  };
</script>
