import controller from '@/controller';
import {Schedule} from '@/models/Schedule';
import {UserProfile} from "./models/UserProfile";
import {buildPostFromFirestore} from "@/util/buildPostFromFirestore";
import lodash from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import Vuex from 'vuex';
const fb =  require('./firebase');
import { buildCategoryFromFirestore } from './util/buildCategoryFromFirestore';
import { RecurrentPost } from "../functions/src/models/RecurrentPost";
import { hypefuryAccountTwitterId } from '@/config';
import { DraftThread } from '@/models/DraftThread';
import { Thread } from '@/models/Thread';
import dao from '@/dao';
import { v1 as uuidv1 } from 'uuid';
const inspirationCategories = require('../public/inspiration-categories.json');
import { getInspirationTweets } from '@/util/inspirationTweets';
import { DMCampaign } from './models/DMCampaign';
import { groupPostsByCategoryId } from './util/groupPostsByCategoryId';
Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    currentUserId: null,
    currentUser: null,
    evergreenPosts: null,
    firestoreListeners: [],
    schedule: null,
    isWholeScheduleLoaded: false,
    churnkeyHMAC: null,
    servicebotHMAC: null,
    twitterUserInfo: null,
    userProfiles: {},
    isAcuteReady: false,
    isBeamerReady: false,
    isUserManaging: false,
    cannotSchedulePosts: false,
    followedUsers: [],
    userCategories: [],
    userCategoriesLoaded: false,
    userRecurrentPosts: [],
    userAffiliateDetails: null,
    userAffiliateDetailsLoaded: false,
    isUserFollowingHypefuryOnTwitter: true,
    managedAccountsAdditionalData: {},
    writerGhostwritingThreads: null,
    clientGhostwritingThreads: null,
    postedTweetsLikes: null,
    postedTweetsRetweets: null,
    inspirationTweets: null,
    userBestTweetsCategory: null,
    usersBestTweetsCategory: null,
    autoDMThreads: null,
    dmCampaigns: null,
    isPaddleReady: false,
    categoriesPosts: {},
    oldUserCategories: null,
    draftPostsFromCursor: [],
    userBestTweetsCount: null,
    bestTweetIndex: 0,
    replyToTweetUrl: '',
    tiktokUserDetails: null,
    wasUserRedirectedToBilling: false,
  },
  mutations: {
    setTiktokUserDetails(state, val) {
      state.tiktokUserDetails = val;
    },
    addDraftPostsFromCursor(state, val) {
      Vue.set(state.draftPostsFromCursor, val.cursorCount, val.posts);
    },
    setCurrentUser(state, val) {
      const currentUserId = localStorage.getItem('currentUserId');

      if (currentUserId) {
        state.currentUser = val;
        state.currentUserId = currentUserId;
      } else if (!state.currentUser) {
        state.currentUser = val;
        state.currentUserId = val.uid;
      }
    },
    addFirestoreListener(state, newListener) {
      state.firestoreListeners = [...state.firestoreListeners, newListener];
    },
    disconnectAdditionalAccount(state, accountId) {
      const newUserProfiles = lodash.clone(state.userProfiles);
      delete newUserProfiles[accountId];
      state.userProfiles = newUserProfiles;
    },
    setUserAffiliateDetails(state, val) {
      state.userAffiliateDetails = val;
    },
    setUserAffiliateDetailsLoaded(state, val) {
      state.userAffiliateDetailsLoaded = val;
    },
    setEvergreenPosts(state, val) {
      state.evergreenPosts = val;
    },
    setFollowedUsers(state, val) {
      state.followedUsers = val;
    },
    setSchedule(state, val) {
      state.schedule = val;
    },
    setIsWholeScheduleLoadedToTrue(state) {
      state.isWholeScheduleLoaded = true;
    },
    setServicebotHMAC (state, val) {
      state.servicebotHMAC = val;
    },
    setChurnkeyHMAC (state, val) {
      state.churnkeyHMAC = val;
    },
    setTwitterUserInfo (state, val) {
      state.twitterUserInfo = val;
    },
    setClientGhostwritingThreads (state, val) {
      state.clientGhostwritingThreads = val;
    },
    setWriterGhostwritingThreads (state, val) {
      state.writerGhostwritingThreads = val;
    },
    setAutoDMThreads (state, val) {
      state.autoDMThreads = val;
    },
    setDMCampaigns (state, val) {
      state.dmCampaigns = val;
    },
    setUserProfile (state, val) {
      state.currentUserId = val.uid;

      const oldUserProfile = lodash.clone(state.userProfiles[val.uid]);

      Vue.set(state.userProfiles, state.currentUserId, val);
      if (shouldUpdateScheduleFromUserProfile(val, oldUserProfile)) {
        store.dispatch('fetchSchedule');
      }
      // here the fetching of the additional accounts is done asynchronously;
      // getUserProfile is thus called before this code completes, which makes the data it gets not accurate
      // val.additionalAccounts.map((additionaAccount) => {
      //   additionaAccount.hypefuryAccountRef.get().then((doc) => {
      //     state.userProfiles[doc.data().uid] = new UserProfile(doc.data());
      //   });
      // });
      fb.usersCollection
        .doc(state.currentUser.uid)
        .get()
        .then((doc) => {
          state.userProfiles[state.currentUser.uid] = new UserProfile(doc.data());
          (doc.data().additionalAccounts || []).map((additionaAccount) => {
            if (!additionaAccount.hypefuryAccountRef) return;
            additionaAccount.hypefuryAccountRef.get().then((doc) => {
              store.commit('addUserProfile', {
                id: doc.data().uid,
                profile: new UserProfile(doc.data()),
              });
            });
          });
          const dateToExpireCookie = moment().add(1, 'year').toDate().toString();
          document.cookie = `twitterUserId=${doc.data().twitterId}; expires=${dateToExpireCookie}`;
        });
    },
    setIsAcuteReady(state, val) {
      state.isAcuteReady = val;
    },
    setIsBeamerReady(state, val) {
      state.isBeamerReady = val;
    },
    setIsPaddleReady(state, val) {
      state.isPaddleReady = val;
    },
    setUserCategories (state, val) {
      state.userCategories = val;
      state.userCategoriesLoaded = true;
      store.dispatch('fetchCategoriesPosts');
    },
    setUserRecurrentPosts (state, val) {
      state.userRecurrentPosts = val;
      if (val.length) {
        store.dispatch('fetchCategoriesPosts');
      }
      store.dispatch('fetchSchedule');
    },
    setCategoriesPosts (state, val) {
      state.categoriesPosts = val;
      store.dispatch('fetchSchedule');
    },
    setOldUserCategories(state, val) {
      state.oldUserCategories = val;
    },
    addUserProfile(state, val) {
      const newUserProfiles = lodash.clone(state.userProfiles);
      newUserProfiles[val.id] = val.profile;
      state.userProfiles = newUserProfiles;

      const managedAccount = state.userProfiles[state.currentUser.uid].managedAccounts.find(
        (managedAccount) => managedAccount.uid === state.currentUserId
      );
      if (managedAccount) {
        state.isUserManaging = true;
        state.cannotSchedulePosts = Boolean(managedAccount.cannotSchedulePosts);
      }
    },
    setIsUserFollowingHypefuryOnTwitter(state, val) {
      state.isUserFollowingHypefuryOnTwitter = val;
    },
    setManagedAccountsAdditionalData(state, val) {
      state.managedAccountsAdditionalData = val;
    },
    setPostedTweetsLikes(state, val) {
      state.postedTweetsLikes = val;
    },
    setPostedTweetsRetweets(state, val) {
      state.postedTweetsRetweets = val;
    },
    setInspirationTweets(state, val) {
      state.inspirationTweets = val;
    },
    setUserBestTweetsCategory(state, val) {
      state.userBestTweetsCategory = val;
      state.userBestTweetsCount = val.tweets.length - 1;
    },
    setBestTweetIndex(state, val) {
      state.bestTweetIndex = val;
    },
    setReplyToTweetUrl(state, val) {
      state.replyToTweetUrl = val;
    },
  },
  getters: {
    getCurrentUser(state) {
      return state.currentUser;
    },
    getIsUserManaging(state) {
      return state.isUserManaging;
    },
    getTwitterUserInfo (state) {
      return state.twitterUserInfo;
    },
    getParentUserProfile(state) {
      const parentUserProfile = Object.values(state.userProfiles).find(
        (userProfile) => userProfile.uid === state.currentUser.uid,
      );

      return parentUserProfile;
    },
    getUserProfile(state) {
      return state.userProfiles[state.currentUserId];
    },
    getUserCategories (state) {
      return state.userCategories;
    },
    getUserRecurrentPosts (state) {
      return state.userRecurrentPosts;
    },
    getPostedTweetsLikes(state) {
      return state.postedTweetsLikes;
    },
    getPostedTweetsRetweets(state) {
      return state.postedTweetsRetweets;
    },
    getReplyToTweetURL(state) {
      return state.replyToTweetUrl;
    },
    getTiktokUserDetails(state) {
      return state.tiktokUserDetails;
    },
  },
  actions: {
    addFirestoreListener({ commit }, listener) {
      commit('addFirestoreListener', listener);
    },
    disconnectAdditionalAccount({ commit }, accountId) {
      commit('disconnectAdditionalAccount', accountId);
    },
    unsubscribeFromFirestoreListeners({ state }) {
      state.firestoreListeners.forEach((unsubscribeFromListener) => {
        unsubscribeFromListener();
      });
    },
    async updateIsUserFollowingHypefury({ commit, state }) {
      const isUserFollowingHypefury = await controller.isUserFollowingHypefuryOnTwitter(state.currentUser,
        state.currentUserId,
        state.userProfiles[state.currentUserId].twitterId,
        hypefuryAccountTwitterId);

      commit('setIsUserFollowingHypefuryOnTwitter', isUserFollowingHypefury);
    },
    fetchManagedAccountsAdditionalData({ state }) {
      const currentUserProfile = state.userProfiles[state.currentUserId];

      if (
        currentUserProfile.isGhostwriter !== true ||
        currentUserProfile.managedAccounts.length === 0
      ) {
        return null;
      }

      const managedAccountsRefs = currentUserProfile.managedAccounts.map(
        (account) => account.hypefuryAccountRef
      );

      managedAccountsRefs.forEach((managedAccountRef) => {
        const unsubscribe = managedAccountRef.onSnapshot((doc) => {
          store.commit('setManagedAccountsAdditionalData', {
            ...state.managedAccountsAdditionalData,
            [doc.data().uid]: {
              followersCount: doc.data().followersCount,
              numberOfScheduledPosts: doc.data().currentlyScheduledPostsCount,
              cannotSchedulePosts: Boolean(doc.data().cannotSchedulePosts),
            },
          });

          store.dispatch('addFirestoreListener', unsubscribe);
        });
      });
    },
    fetchEvergreenPosts({ state }) {
      const unsubscribe = fb.threadsCollection
        .where('deleted', '==', false)
        .where('user', '==', fb.usersCollection.doc(state.currentUserId))
        .where('isFavorite', '==', true)
        .onSnapshot(function (doc) {
          const evergreenPosts = doc.docs.map((d) => buildPostFromFirestore(d));
          store.commit('setEvergreenPosts', evergreenPosts);
        });
      store.dispatch('addFirestoreListener', unsubscribe);
    },
    async fetchFollowedUsers({ commit, state }) {
      const response = await controller.getFollowedUsers(
        state.currentUser,
        state.currentUserId,
      );
      const followedUsers = response.data.users;

      commit('setFollowedUsers', followedUsers);
    },
    fetchUserProfile({ state }) {
      return new Promise(async (resolve, reject) => {
        const urlParams = new URLSearchParams(window.location.search);

        const userId = urlParams.has('switchToAccount') ? urlParams.get('switchToAccount') : state.currentUserId;

        const unsubscribe = fb.usersCollection.doc(userId).onSnapshot(doc => {
          const userExists = doc.data();
          if (!userExists) return;
          const userProfile = new UserProfile(doc.data());
          store.commit('setUserProfile', userProfile)

          if (!state.servicebotHMAC && userProfile.isStripeCustomer) {
            store.dispatch('fetchServicebotHMAC');
          }

          if (!state.churnkeyHMAC) {
            store.dispatch('fetchChurnkeyHMAC');
          }

          resolve(userProfile);
        }, (err) => {
          reject(err);
        });

        store.dispatch('addFirestoreListener', unsubscribe);
      });
    },
    async fetchTwitterUserInfo({ commit, state }) {
      const twitterUserInfo = await controller.getTwitterUserInfo(
        state.currentUser,
        state.userProfiles[state.currentUserId],
        state.userProfiles[state.currentUserId].twitterId
      );
      commit('setTwitterUserInfo', twitterUserInfo);
      return twitterUserInfo;
    },
    async fetchServicebotHMAC({commit, state}) {
      const user = state.userProfiles[state.currentUserId];
      if (!user.email) return;
      const servicebotHMAC = await controller.getServicebotHMAC(state.currentUser);
      commit('setServicebotHMAC', servicebotHMAC);
    },
    async fetchChurnkeyHMAC({ commit, state }) {
      const user = state.userProfiles[state.currentUserId];
      const shouldFetchChunkeyHMAC = user.isStripeCustomer
        ? Boolean(user.email)
        : Boolean(user.subscriptionId);

      if (shouldFetchChunkeyHMAC) {
        const churnkeyHMAC = await controller.getChurnkeyHMAC(state.currentUser, user.isStripeCustomer);
        commit('setChurnkeyHMAC', churnkeyHMAC);
      }
    },
    async fetchSchedule({commit, state}) {
      function buildSchedule(daysCount, docs, userProfile) {
        const posts = docs.map((d) => buildPostFromFirestore(d, userProfile.timezone));
        const postsMap = {};
        const flattenedPosts = lodash.flatten(posts);
        flattenedPosts.forEach(p => postsMap[p.id] = p);

        return new Schedule(
          daysCount,
          userProfile.timezone,
          Object.values(postsMap),
          userProfile.schedule,
          state.userRecurrentPosts,
          state.categoriesPosts,
        );
      }

      if (store.state.route.meta.id === 'login') return;

      const userRef = fb.usersCollection.doc(state.currentUserId);
      const oneWeekLater = moment.tz(state.userProfiles[state.currentUserId].timezone).add(1, 'week');
      const postsQuery = fb.threadsCollection
        .where('deleted', '==', false)
        .where('user', '==', userRef)
        .where('time', '>=', new Date())
        .where('postNow', '==', false)
        .where('scheduled', '==', false);

      const postsForTheNextWeek = await postsQuery.where('time', '<=', oneWeekLater.toDate()).get();
      const schedule = buildSchedule(7, postsForTheNextWeek.docs, state.userProfiles[state.currentUserId]);

      commit('setSchedule', schedule);

      setTimeout(() => {
        const unsubscribePostListener = postsQuery.onSnapshot(lodash.debounce((result) => {
          store.commit('setSchedule', buildSchedule(366, result.docs, state.userProfiles[state.currentUserId]));
          store.commit('setIsWholeScheduleLoadedToTrue');
        }, 2000, {leading: true, trailing: true}));

        store.dispatch('addFirestoreListener', unsubscribePostListener);
      }, 2000);
    },
    async fetchUserCategories({ state }) {
      const userRef = fb.usersCollection.doc(state.currentUserId);
      const unsubscribe = fb.categoriesCollection
        .where('userRef', '==', userRef)
        .onSnapshot((doc) => {
          const categories = doc.docs
            .map((d) => buildCategoryFromFirestore(d))
            .sort((a, b) => {
              if (!a.createdAt) return 1;
              if (!b.createdAt) return -1;
              return a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0;
            });
          store.commit('setUserCategories', categories);

          if (categories.length && state.userRecurrentPosts.length) {
            store.commit(
              'setUserRecurrentPosts',
              state.userRecurrentPosts.map((post) => ({
                ...post,
                category: categories.find((category) => {
                  return category.id === post.category.id;
                }),
              }))
            );
          }
        });

      store.dispatch('addFirestoreListener', unsubscribe);
    },
    async fetchUserRecurrentPosts({ state }) {
      const userRef = fb.usersCollection.doc(state.currentUserId);
      const unsubscribe = fb.recurrentPostsCollection
        .where('userRef', '==', userRef)
        .onSnapshot((doc) => {
          const posts = doc.docs
            .map((postDoc) => {
              const post = RecurrentPost.buildFromFirestore(postDoc);

              const postToReturn = {
                ...post,
                category: state.userCategories.find((category) => {
                  return category.id === post.category.id;
                }),
              };

              return postToReturn;
            })
            .filter((post) => post.category);

          store.commit('setUserRecurrentPosts', posts);
        });

      store.dispatch('addFirestoreListener', unsubscribe);
    },
    async fetchUserAffiliateDetails({ commit, state }) {
      try {
        const userAffiliateDetails = await controller.getUserAffiliateDetails(state.currentUser, state.userProfiles[state.currentUserId]);
        const totalCustomers = userAffiliateDetails.promotions.reduce((acc, currentVal) => {
          return acc + currentVal.customers_count;
        }, 0);
        const earningsBalance = lodash.get(userAffiliateDetails, 'earnings_balance.cash');

        commit('setUserAffiliateDetails', {
          earningsBalance: earningsBalance ? earningsBalance / 100 : 0,
          totalCustomers,
          referralLink: userAffiliateDetails.promotions[0].referral_link
        });
      } catch (error) {
        console.error('An error occurred while fetching affiliate details', error);
      }
      commit('setUserAffiliateDetailsLoaded', true);
    },
    fetchWriterGhostwritingThreads({ commit, state }) {
      const currentUserProfile = state.userProfiles[state.currentUserId];

      if (currentUserProfile.managedAccounts.length === 0) {
        return null;
      }

      const unsubscribe = fb.threadsCollection
        .where('source', '==', 'ghostwriting')
        .where('writer', '==', fb.usersCollection.doc(currentUserProfile.uid))
        .orderBy('created_at', 'desc')
        .onSnapshot((tweets) => {
          commit(
            'setWriterGhostwritingThreads',
            tweets.docs.map((d) =>
              d.data().time === null
                ? DraftThread.buildFromFirestore(d)
                : Thread.buildFromFirestore(d, currentUserProfile.timezone)
            )
          );
        });

      store.dispatch('addFirestoreListener', unsubscribe);
    },
    fetchClientGhostwritingThreads({ commit, state }) {
      const unsubscribe = fb.threadsCollection
        .where('source', '==', 'ghostwriting')
        .where('ghostwritingStatus', '==', 'pending')
        .where('scheduled', '==', false)
        .where('user', '==', fb.usersCollection.doc(state.userProfiles[state.currentUserId].uid))
        .orderBy('created_at', 'desc')
        .onSnapshot((tweets) => {
          commit(
            'setClientGhostwritingThreads',
            tweets.docs.map((d) => DraftThread.buildFromFirestore(d))
          );
        });

      store.dispatch('addFirestoreListener', unsubscribe);
    },
    fetchDMCampaigns({ commit, state }) {
      const unsubscribe = fb.dmCampaignsCollection
        .where('user', '==', fb.usersCollection.doc(state.userProfiles[state.currentUserId].uid))
        .orderBy('time', 'desc')
        .onSnapshot((response) => {
          const campaigns = response.docs.map((campaign) => DMCampaign.buildFromFirestore(campaign, state.userProfiles[state.currentUserId].timezone));
          commit('setDMCampaigns', campaigns);
        });

      store.dispatch('addFirestoreListener', unsubscribe);
    },
    fetchAutoDMThreads({ commit, state }) {
      const unsubscribe = fb.threadsCollection
        .where('deleted', '==', false)
        .where('user', '==', fb.usersCollection.doc(state.userProfiles[state.currentUserId].uid))
        .where('scheduled', '==', true)
        .where('autoDM.enabled', '==', true)
        .orderBy('time', 'desc')
        .onSnapshot((response) => {
          const posts = response.docs
            // Old autoDM threads are missing the messagedUsers additional data
            // so we're filtering them using the tags field (only new autoDM posts have it)
            .filter((p) => Array.isArray(p.data().autoDM.tags) && p.data().tweetIds.length > 0)
            .map((post) =>
              Thread.buildFromFirestore(post, state.userProfiles[state.currentUserId].timezone)
            );
          commit('setAutoDMThreads', posts);
        });

      store.dispatch('addFirestoreListener', unsubscribe);
    },
    async fetchPostedTweetsLikesAndRetweets({ commit, state }, isOnboarding) {
      const posts = (
        isOnboarding
          ? await fb.threadsCollection
              .where('deleted', '==', false)
              .where('user', '==', fb.usersCollection.doc(state.userProfiles[state.currentUserId].uid))
              .where('scheduled', '==', true)
              .where('publishingError', '==', null)
              .orderBy('time', 'desc')
              .limit(2000)
              .get()
          : await fb.publicPostsCollection
              .where('user', '==', fb.usersCollection.doc(state.userProfiles[state.currentUserId].uid))
              .orderBy('time', 'desc')
              .limit(2000)
              .get()
      ).docs;

      const postsWithTweets = posts.filter((doc) => {
        return Array.isArray(doc.data().tweets) && doc.data().tweets.length > 0
      })

      const tweetsRetweets = postsWithTweets
        .map((doc) => doc.data().tweets[0].retweetCount)
        .sort((a, b) => b - a);
      const tweetsLikes = postsWithTweets
        .map((doc) => doc.data().tweets[0].favoriteCount)
        .sort((a, b) => b - a);

      commit('setPostedTweetsRetweets', tweetsRetweets);
      commit('setPostedTweetsLikes', tweetsLikes);
    },
    async fetchInspirationTweets({ commit, state }) {
      // TODO: Update InspirationPanel.vue to remove duplicate code
      commit('setUserBestTweetsCategory', await getBestTweetsCategory(state.currentUser, state.userProfiles[state.currentUserId]));

      const currentInspirationCategoriesTweets = [state.userBestTweetsCategory, ...inspirationCategories];
      const inspirationCategoriesFromLocalStorage = localStorage.getItem(
        'selected_inspiration_category_ids'
      );

      const selectedInspirationCategories = [];

      if (
        inspirationCategoriesFromLocalStorage &&
        JSON.parse(inspirationCategoriesFromLocalStorage).length > 0
      ) {
        JSON.parse(inspirationCategoriesFromLocalStorage).forEach((categoryId) => {
          selectedInspirationCategories.push(
            currentInspirationCategoriesTweets.find((category) => category.id === categoryId)
          );
        });
      } else {
        selectedInspirationCategories.push(state.userBestTweetsCategory);
      }

      const tweets = [];
      await Promise.all(
        selectedInspirationCategories.map(async (category) => {
          if (!state.userCategories.includes(category)) {
            category.tweets.forEach((tweet) => {
              if (!tweet.id) {
                tweet.id = uuidv1();
              }
            });
            tweets.push(...category.tweets);
          } else {
            const postsDocs = (
              await dao.posts
                .getPostsQueriesForHistoryCategory(category.userRef.id, category.id)['all'].get()
            ).docs;
            const recurrentPostsTweets = postsDocs.map((postDoc) => {
              return {
                text: postDoc.data().tweets[0].status,
                likeCount: postDoc.data().tweets[0].favoriteCount,
                retweetCount: postDoc.data().tweets[0].retweetCount,
                time: postDoc.data().time.toDate(),
                id: postDoc.data().tweets[0].tweetId,
                user: {
                  name: state.userProfiles[state.currentUserId].name,
                  profilePhoto: state.userProfiles[state.currentUserId].photoURL,
                  username: state.userProfiles[state.currentUserId].username,
                },
              };
            });
            tweets.push(...recurrentPostsTweets);
          }
          return Promise.resolve();
        })
      );
      commit('setInspirationTweets', lodash.uniqBy(tweets, 'id'));
    },
    fetchCategoriesPosts: lodash.debounce(async ({ commit, state }) => {
      const recurrentPostsCategoriesIds = lodash.uniq(
        state.userRecurrentPosts.map((recurrentPost) => recurrentPost.category.id)
      );

      const validCategories = state.userCategories.filter(
        (category) =>
          // Only keep categories that have at least 1 post and at least 1 recurrent post
          category.postsOrder.length > 0 &&
          recurrentPostsCategoriesIds.includes(category.id) &&
          // In order to optimize fetching only fetch categories that have been updated since the last fetch (excluding the first fetch)
          (!state.oldUserCategories ||
            !lodash.isEqual(
              state.oldUserCategories.find((cat) => cat.id === category.id),
              state.userCategories.find((cat) => cat.id === category.id)
            ))
      );

      if (!validCategories.length) {
        return;
      }

      const postsPromises = validCategories.map(async (category) => {
        // Take 100 posts from each category and chunk them into groups of 10 in order to optimize fetching (10 requests instead of 100)
        const postsToFetch = lodash.take(category.postsOrder, 100);
        const postsChunks = lodash.chunk(postsToFetch, 10);

        const categoryPostsPromises = postsChunks.map((postsChunk) =>
          fb.threadsCollection
            .where(fb.firebase.firestore.FieldPath.documentId(), 'in', postsChunk)
            .get()
        );

        const postsDocs = (await Promise.all(categoryPostsPromises))
          .map((postsResult) => postsResult.docs)
          .flat();
        return postsDocs.map((post) =>
          post.data().time === null
            ? DraftThread.buildFromFirestore(post)
            : Thread.buildFromFirestore(post)
        );
      });

      const posts = (await Promise.all(postsPromises)).flat();
      // Group posts by category id { categoryId: [listOfPosts] }
      const categoriesPosts = groupPostsByCategoryId(posts, state.userProfiles[state.currentUserId], validCategories);

      commit('setCategoriesPosts', { ...state.categoriesPosts, ...categoriesPosts });
      commit('setOldUserCategories', validCategories);
    }, 2000),
    removeCategoryPost({ commit, state }, post) {
      const updatedCategoriesPosts = lodash.cloneDeep(state.categoriesPosts);
      const postCategories = post.categories.map((category) => category.id);

      Object.keys(updatedCategoriesPosts).forEach((categoryId) => {
        if (postCategories.includes(categoryId)) {
          updatedCategoriesPosts[categoryId] = updatedCategoriesPosts[categoryId].filter(
            (p) => p.id !== post.id
          );
        }
      });
      commit('setCategoriesPosts', updatedCategoriesPosts);
    },
    addCategoryPost({ commit, state }, post) {
      const updatedCategoriesPosts = lodash.cloneDeep(state.categoriesPosts);
      const postCategories = post.categories.map((category) => category.id);

      Object.keys(updatedCategoriesPosts).forEach((categoryId) => {
        if (postCategories.includes(categoryId)) {
          const categoryPostsOrder = state.userCategories
            .find((category) => category.id === categoryId)
            .postsOrder.map((post) => post.id);
          updatedCategoriesPosts[categoryId] = [...updatedCategoriesPosts[categoryId], post].sort(
            (a, b) => {
              const aIndex = categoryPostsOrder.indexOf(a.id);
              const bIndex = categoryPostsOrder.indexOf(b.id);
              return aIndex - bIndex;
            }
          );
        }
      });
      commit('setCategoriesPosts', updatedCategoriesPosts);
    },
    async fetchTiktokUserDetails({ commit, getters }) {
      const isUserConnectedToTiktok = Boolean(
        lodash.get(getters.getUserProfile, 'integrations.tiktok.tokenInfo.accessToken'),
        false,
      );
      if (!isUserConnectedToTiktok && !getters.getTiktokUserDetails) return;

      try {
        const response = await controller.tiktok.getUserInfo(
          getters.getCurrentUser,
          getters.getUserProfile,
        );
        commit('setTiktokUserDetails', response.data);
      } catch (error) {
        console.error('An error occurred while fetching Tiktok user details', error);
      }
    },
  },
});

function shouldUpdateScheduleFromUserProfile(newUserProfile, oldUserProfile) {
  if (lodash.isEmpty(oldUserProfile)) {
    return true;
  }
  if (oldUserProfile.timezone && newUserProfile.timezone !== oldUserProfile.timezone) {
    return true;
  }
  if (!lodash.isEqual(oldUserProfile.schedule.getScheduleForDB(), newUserProfile.schedule.getScheduleForDB())) {
    return true;
  }

  return false;
}

async function getBestTweetsCategory(currentUser, userProfile) {
  const usersInspirationTweets = await getInspirationTweets(
    currentUser,
    userProfile.uid
  );
  const usersBestTweets = usersInspirationTweets.map((tweet) => {
    return {
      text: tweet.status,
      likeCount: tweet.favoriteCount,
      retweetCount: tweet.retweetCount,
      time: tweet.time,
      id: tweet.tweetId,
      user: {
        name: userProfile.name,
        profilePhoto: userProfile.photoURL,
        username: userProfile.username,
      },
    };
  });

  return {
    name: 'My best tweets',
    id: 100,
    tweets: lodash.shuffle(usersBestTweets),
  };
}
