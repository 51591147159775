<template>
  <div class="video-player-container">
    <video ref="videoPlayer" class="video-js vjs-big-play-centered vjs-vertical-video"></video>
  </div>
</template>

<script>
  import videojs from 'video.js';
  export default {
    name: 'VerticalVideoPlayer',
    props: {
      options: {
        type: Object,
        required: true,
      },
      width: {
        type: Number,
        default: 360,
      },
      height: {
        type: Number,
        default: 640,
      },
    },
    data() {
      return {
        player: null,
      };
    },
    mounted() {
      const playerOptions = {
        ...this.options,
        width: this.width,
        height: this.height,
      };

      this.player = videojs(this.$refs.videoPlayer, playerOptions, () => {
        this.player.log('Video player is ready');
      });
    },
    beforeDestroy() {
      if (this.player) {
        this.player.dispose();
      }
    },
  };
</script>

<style scoped>
  .video-player-container {
    width: 360px;
    height: 640px;
    margin: 0 auto;
    border-radius: 0.5rem;
  }

  :deep(.video-js .vjs-tech) {
    border-radius: 0.5rem;
  }

  :deep(.vjs-vertical-video) {
    .vjs-tech {
      object-fit: cover;
    }
  }

  :deep(.video-js) {
    width: 100%;
    height: 100% !important;
    border-radius: 0.5rem;
  }

  :deep(.vjs-poster) {
    background-size: cover;
  }

  :deep(.vjs-control-bar) {
    flex-direction: column;
    height: auto;
    /* bottom: -33px; */
    right: 0;
    width: 100%;
    background: transparent;
  }

  :deep(.vjs-progress-control.vjs-control) {
    width: 100%;
  }

  :deep(
      .vjs-vertical-video .vjs-volume-panel,
      .vjs-vertical-video .vjs-progress-control,
      .vjs-remaining-time.vjs-time-control.vjs-control
    ) {
    display: none;
  }
</style>
